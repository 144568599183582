import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { shallowEqual } from "react-redux";
import { useAppSelector } from "../../../app/hooks";
import { REQUEST_STATE } from "../../dataTypes";
import { MylocFile } from "../../file/dataTypes";
import { selectFilesById } from "../../file/fileSelectors";
import useFetchFile from "../../file/hooks/useFetchFile";
import { FormRow, FormSection } from "../dataTypes";
import useLazyGetForm from "./useLazyGetForm";

const getFileIds = (data: (FormRow | FormSection)[]): string[] => {
  return data.reduce<string[]>((ids, section) => {
    if ("type" in section) {
      if (section.fileId) return [...ids, section.fileId];
    } else if (section.sequences) return [...ids, ...getFileIds(section.sequences)];

    return ids;
  }, []);
};

const useLazyGetFiles = ({ id, loadIfNotInitialized }: { id?: string; loadIfNotInitialized?: boolean }) => {
  const [isLoading, setIsLoading] = useState(true);
  const loadFile = useFetchFile();
  const { form, isLoading: isFormLoading } = useLazyGetForm({ id, loadIfNotInitialized: true });
  const fileIds = useMemo(() => getFileIds(form?.sequences ?? []), [form?.sequences]);
  const fileList = useAppSelector(selectFilesById(fileIds), shallowEqual);
  const fetchedFiles = useRef<{ [fileId: string]: boolean }>({});

  const files = useMemo(
    () =>
      fileList.reduce((files, file) => ({ ...files, [file.id]: file }), {} as { [id: string]: MylocFile | undefined }),
    [fileList],
  );

  useEffect(() => {
    if (loadIfNotInitialized) {
      for (const id of fileIds) {
        if (!fetchedFiles.current[id]) {
          if (files[id] === undefined) {
            fetchedFiles.current[id] = true;
            loadFile(id);
          }
        }
      }
    }
  }, [fileIds, files, loadFile, loadIfNotInitialized]);

  const formDefined = !!form;

  const checkFileAvailability = useCallback(() => {
    let filesMissing = false;

    if (formDefined && !isFormLoading) {
      for (const fileId of fileIds) {
        const file = files[fileId];

        if (!file || file.requestState === REQUEST_STATE.PENDING) filesMissing = true;
      }

      if (!filesMissing) setIsLoading(false);
    }
  }, [fileIds, files, formDefined, isFormLoading]);

  useEffect(() => {
    if (isLoading) checkFileAvailability();
  }, [checkFileAvailability, isLoading]);

  return { files, isLoading };
};

export default useLazyGetFiles;
