import PropTypes from "prop-types";
import Login from "../Login/Login";
import Welcome from "./Welcome";

const LoginWelcome = ({
  title,
  text,
  desiredPage,
  customCssClass,
}: {
  title: string;
  text: string;
  desiredPage?: string;
  customCssClass?: string;
}) => {
  return (
    <Welcome title={title} text={text} customCssClass={customCssClass}>
      <Login desiredPage={desiredPage} />
    </Welcome>
  );
};

LoginWelcome.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  desiredPage: PropTypes.string,
  customCssClass: PropTypes.string,
};

export default LoginWelcome;
