import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import classNames from "classnames";
import { useTranslate } from "../../../language/i18n";
import pages from "../../../utils/pages";
import { Home, Products, Menu, User, AccessCard, Warning } from "../../../assets/icons";
import styles from "./MobileMenu.module.scss";
import MenuDrawer from "./MenuDrawer/MenuDrawer";
import { useAppSelector } from "../../../app/hooks";
import { selectSessionId } from "../../../features/session/sessionSelectors";

function MobileMenu() {
  const translate = useTranslate();
  const userLoggedIn = !!useAppSelector(selectSessionId);
  const [menuVisible, setMenuVisible] = useState(false);

  const onItemClick: Parameters<NavLink>[0]["onClick"] = () => {
    menuVisible && toggleDrawer();
  };

  const toggleDrawer = () => {
    setMenuVisible(menuVisible => !menuVisible);
  };

  useEffect(() => {
    if (!userLoggedIn) setMenuVisible(false);
  }, [userLoggedIn]);

  return (
    <>
      {userLoggedIn && (
        <>
          <section className={styles.menu}>
            <NavLink
              exact
              to={pages.HOME}
              className={styles.link}
              activeClassName={styles.active}
              onClick={onItemClick}
            >
              <Home />
              <span className={styles.text}>{translate("HOME")}</span>
            </NavLink>

            <NavLink
              to={pages.SERVICES.PATH}
              className={styles.link}
              activeClassName={styles.active}
              onClick={onItemClick}
            >
              <Products />
              <span className={styles.text}>{translate(pages.SERVICES.NAME)}</span>
            </NavLink>

            <NavLink
              to={pages.REPORT_ERROR.PATH}
              className={styles.link}
              activeClassName={styles.active}
              onClick={onItemClick}
            >
              <Warning />
              <span className={styles.text}>{translate(pages.REPORT_ERROR.NAME)}</span>
            </NavLink>

            <NavLink
              to={pages.ACCESS_CARD.PATH}
              className={styles.link}
              activeClassName={styles.active}
              onClick={onItemClick}
            >
              <AccessCard />
              <span className={styles.text}>{translate(pages.ACCESS_CARD.NAME)}</span>
            </NavLink>

            <NavLink
              to={pages.MY_CASES.PATH}
              className={styles.link}
              activeClassName={styles.active}
              onClick={onItemClick}
            >
              <User />
              <span className={styles.text}>{translate(pages.MY_CASES.NAME)}</span>
            </NavLink>

            <button
              className={classNames(styles.link, menuVisible && styles.active)}
              onClick={e => {
                e.preventDefault();

                toggleDrawer();
              }}
            >
              <Menu />
              <span className={styles.text}>{translate("MENU")}</span>
            </button>
          </section>

          <MenuDrawer visible={menuVisible} onMenuLinkClick={toggleDrawer} />
        </>
      )}
    </>
  );
}

export default MobileMenu;
