import { useCallback, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { FETCH_MASTER_DATA_TIME_OUT, REQUEST_STATE, UseFetchRefs } from "../../dataTypes";
import { setError } from "../../dialog/dialogSlice";
import mapUseFetchRefs from "../../utils/mapUseFetchRefs";
import mergeFetchedTimestampRefAndStateEntities from "../../utils/mergeFetchedTimestampRefAndStateEntities";
import { useValidateUserIsLoggedIn } from "../../utils/useValidateUserIsLoggedIn";
import { selectFormDefinitionEntities } from "../formDefinitionSelectors";
import { fetchFormDefinition } from "../reducers/fetchFormDefinition";

let refs: UseFetchRefs;

const useFetchFormDefinition = () => {
  const dispatch = useAppDispatch();
  const result = useValidateUserIsLoggedIn();

  const formDefinitions = useAppSelector(selectFormDefinitionEntities);

  if ((refs as UseFetchRefs | undefined) === undefined) {
    refs = mapUseFetchRefs(formDefinitions);
  }

  useEffect(() => {
    refs = mergeFetchedTimestampRefAndStateEntities(refs, mapUseFetchRefs(formDefinitions));
  }, [formDefinitions]);

  const loadFormDefinition = useCallback(
    async (id?: string) => {
      if (result.isError) {
        dispatch(setError({ value: result.errorMessage }));
      } else {
        if (id) {
          const ref = refs[id];

          if (
            !ref ||
            (ref.requestState !== REQUEST_STATE.PENDING &&
              ref.fetchedTimestamp + FETCH_MASTER_DATA_TIME_OUT < Date.now())
          ) {
            refs[id] = { fetchedTimestamp: Date.now(), requestState: REQUEST_STATE.PENDING };
            await dispatch(fetchFormDefinition(id));
          }
        }
      }
    },
    [dispatch, result],
  );

  return loadFormDefinition;
};

export default useFetchFormDefinition;
