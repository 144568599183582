import { Method, Request, Response as ResponseUtil, sendRequest } from "@myloc/myloc-utils";
import { useCallback, useState } from "react";
import { useAppDispatch } from "../../../app/hooks";
import { AppDispatch } from "../../../app/store";
import { api } from "../../../config/settings";
import { getErrorMessage } from "../../../services/error/errorService";
import { RequestError } from "../../dataTypes";
import defaultRestOptions from "../../utils/defaultRestOptions";
import { SingleSignOnResponse } from "../dataTypes";
import { saveSingleSignOnResponse } from "../utils/saveSingleSignOnResponse";

async function getUserData(dispatch: AppDispatch) {
  const customErrorHandler: Parameters<typeof defaultRestOptions>["0"]["customErrorHandler"] = exception => {
    return new ResponseUtil(exception?.response?.statusCode, getErrorMessage(exception) ?? "", exception);
  };

  const url = api.singleSignOn.getUserInfo();
  const request = new Request(url, Method.GET);

  return await sendRequest(request, {}, await defaultRestOptions({ customErrorHandler, dispatch }));
}

type Response =
  | { isError: false }
  | {
      isError: true;
      message: string;
      httpStatusCode: RequestError["httpStatusCode"];
    };

const useFetchSingleSignOnUserData = () => {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();

  const loadUserData = useCallback(async (): Promise<Response> => {
    setIsLoading(true);
    setIsSuccess(false);
    setIsError(false);
    setErrorMessage(undefined);

    const response = (await getUserData(dispatch)) as any;

    let isOk = false;

    if (response?.data?.id_token) {
      saveSingleSignOnResponse(response.data.id_token as SingleSignOnResponse);
      isOk = true;
    }

    setIsSuccess(isOk);
    setIsError(!isOk);

    if (!isOk) setErrorMessage(response.message);

    setIsLoading(false);

    if (isOk) {
      return { isError: false };
    } else {
      return {
        isError: true,
        message: response.message,
        httpStatusCode: response.statusCode as RequestError["httpStatusCode"],
      };
    }
  }, [dispatch]);

  return { loadUserData, isLoading, isSuccess, isError, errorMessage };
};

export default useFetchSingleSignOnUserData;
