import { EntityActionReducerBuilderInterface, REQUEST_STATE } from "../../dataTypes";
import { fetchFiles } from "../../pageContent/reducers/fetchFiles";
import { MylocFile } from "../dataTypes";
import { fileAdapter } from "../fileAdapter";

export const addFetchFilesForPageContentReducers = (builder: EntityActionReducerBuilderInterface<MylocFile>) => {
  builder.addCase(fetchFiles.fulfilled, (state, action) => {
    const { payload } = action;

    fileAdapter.upsertMany(
      state,
      payload.page.map(responseObject => ({
        ...responseObject,
        fetchedTimestamp: Date.now(),
        requestStatus: REQUEST_STATE.FULFILLED,
        errorMessage: undefined,
      })),
    );
  });
};
