import { createSlice } from "@reduxjs/toolkit";
import { AdditionalRequestState } from "../dataTypes";
import { pageContentSliceName } from "./dataTypes";
import { pageContentAdapter } from "./pageContentAdapter";
import { addFetchCurrentPageContentReducers } from "./reducers/fetchCurrentPageContent";
import { addFetchFilesReducers } from "./reducers/fetchFiles";
import { addFetchHyperlinksReducers } from "./reducers/fetchHyperlinks";

const slice = createSlice({
  name: pageContentSliceName,
  initialState: pageContentAdapter.getInitialState<AdditionalRequestState>({}),
  reducers: {},
  extraReducers: builder => {
    addFetchCurrentPageContentReducers(builder);
    addFetchFilesReducers(builder);
    addFetchHyperlinksReducers(builder);
  },
});

const { reducer } = slice;

export default reducer;
