import { appDataName } from "../../app/dataTypes";
import { RootState } from "../../app/rootReducer";
import { pageContentSliceName } from "./dataTypes";
import { pageContentAdapter } from "./pageContentAdapter";

const selectSliceState = (state: RootState) => state[appDataName][pageContentSliceName];

const adapterSelectors = pageContentAdapter.getSelectors(selectSliceState);

export const selectErrorMessage = (state: RootState) => selectSliceState(state).errorMessage;

export const selectRequestState = (state: RootState) => selectSliceState(state).requestState;

export const selectPageContentEntities = adapterSelectors.selectEntities;
export const selectPageContents = adapterSelectors.selectAll;
export const selectPageContent = (id = "") => (state: RootState) => adapterSelectors.selectById(state, id);
