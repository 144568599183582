import { EntityActionReducerBuilderInterface, REQUEST_STATE } from "../../dataTypes";
import { updateCurrentUserData } from "../../session/reducers/updateCurrentUserData";
import { User } from "../dataTypes";
import { userAdapter } from "../userAdapter";

export const addUpdateCurrentUserDataReducers = (builder: EntityActionReducerBuilderInterface<User>) => {
  builder.addCase(updateCurrentUserData.fulfilled, (state, action) => {
    const { payload } = action;

    userAdapter.upsertOne(state, { ...payload, fetchedTimestamp: Date.now(), requestState: REQUEST_STATE.FULFILLED });
  });
};
