import { createSlice } from "@reduxjs/toolkit";
import { AdditionalPaginationState, AdditionalRequestState } from "../dataTypes";
import { webOrderContentSliceName } from "./dataTypes";
import { webOrderContentAdapter } from "./webOrderContentAdapter";
import { addFetchWebOrderContentReducers } from "./reducers/fetchWebOrderContent";
import { addFetchWebOrderContentsReducers } from "./reducers/fetchWebOrderContents";
import { addFetchLinesReducers } from "./reducers/fetchLines";
import { addFetchFilesReducers } from "./reducers/fetchFiles";
import { addCreateAndOrderWebOrderReducers } from "./reducers/createAndOrderWebOrder";

const slice = createSlice({
  name: webOrderContentSliceName,
  initialState: webOrderContentAdapter.getInitialState<AdditionalRequestState & AdditionalPaginationState>({
    count: 0,
  }),
  reducers: {},
  extraReducers: builder => {
    addCreateAndOrderWebOrderReducers(builder);
    addFetchFilesReducers(builder);
    addFetchWebOrderContentReducers(builder);
    addFetchWebOrderContentsReducers(builder);
    addFetchLinesReducers(builder);
  },
});

const { reducer } = slice;

export default reducer;
