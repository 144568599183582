import LoadingSpinner from "../shared/Spinner/LoadingSpinner";
import useGetServiceTypes from "../../features/serviceType/hooks/useGetServiceTypes";
import { SERVICE_TYPE_CATEGORIES } from "../../utils/constants";
import useLazyGetServiceType from "../../features/serviceType/hooks/useLazyGetServiceType";
import ServiceTypeInformation from "../Services/ServiceDetail/ServiceTypeInformation/ServicetypeInformation";
import { useTranslate } from "../../language/i18n";

const AccessCard = () => {
  const translate = useTranslate();

  const { isLoading: serviceTypesIsLoading, fetchedIds } = useGetServiceTypes({
    filter: { serviceTypeCategory: SERVICE_TYPE_CATEGORIES.ACCESSCARD },
  });

  const id = fetchedIds?.[0];

  const { serviceType, isLoading: serviceTypeIsLoading } = useLazyGetServiceType({ id, loadIfNotInitialized: true });

  if (serviceTypesIsLoading || serviceTypeIsLoading) {
    return <LoadingSpinner />;
  }

  return (
    <div>
      {serviceType ? (
        <ServiceTypeInformation serviceType={serviceType} />
      ) : (
        <p>{translate("SERVICE_TYPE_NOT_FOUND")}</p>
      )}
    </div>
  );
};

export default AccessCard;
