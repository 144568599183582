import PropTypes from "prop-types";
import { useTranslate } from "../../../../language/i18n";
import { NavLink } from "react-router-dom";
import classNames from "classnames";
import styles from "./Menu.module.scss";
import { MenuItem as MenuItemType } from "../../../../utils/menu/dataTypes";
import { memo } from "react";

const MenuItem = ({
  menuItem,
  toggleMenu,
  isActive,
  menuVisible,
}: {
  menuItem: MenuItemType;
  toggleMenu: (menuItem?: MenuItemType) => void;
  isActive: boolean;
  menuVisible: boolean;
}) => {
  const translate = useTranslate();

  return (
    <li className={styles.menuItem}>
      {menuItem.subMenus && menuItem.subMenus.length > 0 ? (
        <button
          className={classNames(styles.menuButton, isActive && menuVisible && styles.active)}
          onClick={() => toggleMenu(menuItem)}
        >
          {translate(menuItem.label)}
        </button>
      ) : (
        <NavLink
          to={menuItem.to ?? "/"}
          className={styles.menuLink}
          activeClassName={styles.selected}
          onClick={() => toggleMenu()}
        >
          {translate(menuItem.label)}
        </NavLink>
      )}
    </li>
  );
};

MenuItem.propTypes = {
  menuItem: PropTypes.object.isRequired,
  toggleMenu: PropTypes.func.isRequired,
  isActive: PropTypes.bool.isRequired,
  menuVisible: PropTypes.bool.isRequired,
};

export default memo(MenuItem);
