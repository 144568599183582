import { useCallback, useEffect, useRef } from "react";
import { shallowEqual } from "react-redux";
import { useAppSelector } from "../../../app/hooks";
import { REQUEST_STATE } from "../../dataTypes";
import { selectFilesById } from "../../file/fileSelectors";
import { ServiceTypeFilesFilter } from "../dataTypes";
import { selectServiceType } from "../serviceTypeSelectors";
import useFetchFiles from "./useFetchFiles";

const useLazyGetFiles = ({
  serviceTypeId,
  filter,
  loadIfNotInitialized = false,
}: {
  serviceTypeId?: string;
  filter?: ServiceTypeFilesFilter;
  loadIfNotInitialized?: boolean;
}) => {
  const fetchFiles = useFetchFiles();
  const serviceType = useAppSelector(selectServiceType(serviceTypeId));
  const hasInitalized = useRef(false);

  const filesObj = serviceType?.files;

  const requestState = filesObj?.requestState;
  const errorMessage = filesObj?.errorMessage;

  const files = useAppSelector(selectFilesById(filesObj?.ids), shallowEqual);

  const isUninitialized = requestState === undefined;
  const isLoading = requestState === REQUEST_STATE.PENDING;
  const isError = requestState === REQUEST_STATE.REJECTED;
  const isSuccess = requestState === REQUEST_STATE.FULFILLED;

  const loadFiles = useCallback(async () => {
    await fetchFiles({ serviceTypeId, filter });
  }, [serviceTypeId, fetchFiles, filter]);

  useEffect(() => {
    hasInitalized.current = false;
  }, [serviceTypeId, filter]);

  useEffect(() => {
    if (loadIfNotInitialized && isUninitialized && !hasInitalized.current) {
      hasInitalized.current = true;
      loadFiles();
    }
  }, [isUninitialized, loadIfNotInitialized, loadFiles]);

  return {
    files,
    isUninitialized,
    isLoading,
    isError,
    isSuccess,
    errorMessage,
    fetchFiles: loadFiles,
  };
};

export default useLazyGetFiles;
