import PropTypes from "prop-types";
import classNames from "classnames";
import { Image as ImageIcon } from "../../../assets/icons";
import { useMobile } from "../../../utils/viewport";
import Image from "../../shared/Image/Image";
import styles from "./ServiceImage.module.scss";
import { MylocFile } from "../../../features/file/dataTypes";
import { ValueOf } from "../../../utils/dataTypes";

const ServiceImage = ({
  image,
  preset = IMAGE_PRESET.SMALL,
  customCssClass,
}: {
  image?: MylocFile;
  preset?: ValueOf<typeof IMAGE_PRESET>;
  customCssClass?: string;
}) => {
  const isMobile = useMobile();

  if (!image) {
    return (
      <figure
        style={{ height: isMobile ? preset.MOBILE : preset.FULL }}
        className={classNames(styles.image, styles.missingImage)}
      >
        <ImageIcon size={preset == IMAGE_PRESET.TINY || preset === IMAGE_PRESET.ICON ? "25" : "80"} />
      </figure>
    );
  }

  const imageUrl = new URL(image.url);

  imageUrl.searchParams.append("width", preset.SCALE);
  imageUrl.searchParams.append("height", preset.SCALE);

  return (
    <Image
      style={{ height: isMobile ? preset.MOBILE : preset.FULL }}
      src={imageUrl.toString()}
      alt={image.name}
      customCssClass={classNames(styles.image, customCssClass)}
    />
  );
};

export const IMAGE_PRESET = {
  ICON: { FULL: "50", MOBILE: "50", SCALE: "100" },
  TINY: { FULL: "100", MOBILE: "100", SCALE: "250" },
  SMALL: { FULL: "250", MOBILE: "130", SCALE: "500" },
  MEDIUM: { FULL: "512", MOBILE: "400", SCALE: "1024" },
} as const;

ServiceImage.propTypes = {
  image: PropTypes.object,
  preset: PropTypes.oneOf(Object.values(IMAGE_PRESET)),
  customCssClass: PropTypes.string,
};

export default ServiceImage;
