import { PaginationFilter } from "../utils/pagination";
import { AdditionalRequestState, FetchedIdObject, IdObject, Owner } from "../dataTypes";

export const serviceTypeCategorySliceName = "serviceTypeCategory";

export interface ResponseServiceTypeCategory extends IdObject {
  category: string;
  owner: Owner;
  parentServiceTypeCategoryId?: string;
}

export interface ServiceTypeCategory extends ResponseServiceTypeCategory, AdditionalRequestState, FetchedIdObject {}

export interface ServiceTypeCategoriesFilter extends Omit<PaginationFilter, "sort"> {}
