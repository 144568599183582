import PropType from "prop-types";
import { useTranslate } from "../../../language/i18n";

import styles from "./PageSize.module.scss";
import SelectField from "../SelectField/SelectField";

export const PAGE_SIZE = { SMALL: 24, MEDIUM: 52, LARGE: 100 } as const;

const perPage = Object.values(PAGE_SIZE).map(value => ({ id: value.toString(), value: value }));

function PageSize({
  onChange,
  size = PAGE_SIZE.SMALL,
  customCssClass,
}: {
  onChange: (size: number) => void;
  size?: number;
  customCssClass?: string;
}) {
  const translate = useTranslate();

  return (
    <section className={customCssClass}>
      <p className={styles.text}>{translate("SHOW_PAGE_SIZE")}</p>
      <SelectField
        label={""}
        required
        customSettings={{ autocomplete: false }}
        options={perPage}
        selectedId={size.toString()}
        onSelect={item => onChange(item?.value ?? PAGE_SIZE.SMALL)}
        customCssClass={styles.select}
      />
    </section>
  );
}

PageSize.propTypes = {
  onChange: PropType.func.isRequired,
  size: PropType.number,
  customCssClass: PropType.string,
};

export default PageSize;
