import { createSlice } from "@reduxjs/toolkit";
import { AdditionalRequestState } from "../dataTypes";
import { fileSliceName } from "./dataTypes";
import { fileAdapter } from "./fileAdapter";
import { addCreateAndOrderWebOrderReducers } from "./reducers/createAndOrderWebOrder";
import { addFetchFileReducers } from "./reducers/fetchFile";
import { addFetchFilesForPageContentReducers } from "./reducers/fetchFilesForPageContent";
import { addFetchFilesForServiceTypeReducers } from "./reducers/fetchFilesForServiceType";
import { addFetchFilesForWebOrderContent } from "./reducers/fetchFilesForWebOrderContent";

const slice = createSlice({
  name: fileSliceName,
  initialState: fileAdapter.getInitialState<AdditionalRequestState>({}),
  reducers: {},
  extraReducers: builder => {
    addCreateAndOrderWebOrderReducers(builder);
    addFetchFileReducers(builder);
    addFetchFilesForPageContentReducers(builder);
    addFetchFilesForServiceTypeReducers(builder);
    addFetchFilesForWebOrderContent(builder);
  },
});

const { reducer } = slice;

export default reducer;
