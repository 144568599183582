import { appDataName } from "../../app/dataTypes";
import { RootState } from "../../app/rootReducer";
import { Hyperlink, hyperlinkSliceName } from "./dataTypes";
import { hyperlinkAdapter } from "./hyperlinkAdapter";

const selectSliceState = (state: RootState) => state[appDataName][hyperlinkSliceName];

const adapterSelectors = hyperlinkAdapter.getSelectors(selectSliceState);

export const selectErrorMessage = (state: RootState) => selectSliceState(state).errorMessage;

export const selectRequestState = (state: RootState) => selectSliceState(state).requestState;

export const selectHyperlinkEntities = adapterSelectors.selectEntities;
export const selectHyperlinks = adapterSelectors.selectAll;
export const selectHyperlink = (id = "") => (state: RootState) => adapterSelectors.selectById(state, id);
export const selectHyperlinksById = (ids: string[] = []) => (state: RootState) =>
  ids.reduce((hyperlinks, id) => {
    const hyperlink = adapterSelectors.selectById(state, id);

    return hyperlink ? [...hyperlinks, hyperlink] : hyperlinks;
  }, [] as Hyperlink[]);
