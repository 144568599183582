import { useCallback, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { FETCH_MASTER_DATA_TIME_OUT, REQUEST_STATE, UseFetchRef } from "../../dataTypes";
import { setError } from "../../dialog/dialogSlice";
import { useValidateUserIsLoggedIn } from "../../utils/useValidateUserIsLoggedIn";
import { fetchNextUserAccessMessage } from "../reducers/fetchNextUserAccessMessage";
import { selectNextUserAccessMessage } from "../sessionSelectors";

let ref: UseFetchRef;

const useFetchNextUserAccessMessage = () => {
  const dispatch = useAppDispatch();
  const result = useValidateUserIsLoggedIn();

  const nextUserAccessMessage = useAppSelector(selectNextUserAccessMessage);

  if ((ref as UseFetchRef | undefined) === undefined)
    ref = {
      fetchedTimestamp: nextUserAccessMessage?.fetchedTimestamp ?? 0,
      requestState: nextUserAccessMessage?.requestState,
    };

  useEffect(() => {
    if (!nextUserAccessMessage) ref = { fetchedTimestamp: 0, requestState: undefined };
    else
      ref = {
        fetchedTimestamp: nextUserAccessMessage.fetchedTimestamp,
        requestState: nextUserAccessMessage.requestState ?? ref.requestState,
      };
  }, [nextUserAccessMessage]);

  const loadUserAccessMessage = useCallback(async () => {
    if (result.isError) {
      dispatch(setError({ value: result.errorMessage }));
    } else {
      if (
        ref.requestState !== REQUEST_STATE.PENDING &&
        ref.fetchedTimestamp + FETCH_MASTER_DATA_TIME_OUT < Date.now()
      ) {
        ref = { fetchedTimestamp: Date.now(), requestState: REQUEST_STATE.PENDING };
        await dispatch(fetchNextUserAccessMessage());
      }
    }
  }, [dispatch, result]);

  return loadUserAccessMessage;
};

export default useFetchNextUserAccessMessage;
