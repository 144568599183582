import { useCallback, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { REQUEST_STATE, UseFetchRefs } from "../../dataTypes";
import mapUseFetchRefs from "../../utils/mapUseFetchRefs";
import mergeFetchedTimestampRefAndStateEntities from "../../utils/mergeFetchedTimestampRefAndStateEntities";
import { selectWebOrderContentEntities } from "../webOrderContentSelectors";
import { fetchWebOrderContent } from "../reducers/fetchWebOrderContent";
import { useValidateUserIsLoggedIn } from "../../utils/useValidateUserIsLoggedIn";
import { setError } from "../../dialog/dialogSlice";

let refs: UseFetchRefs;

const useFetchWebOrderContent = () => {
  const dispatch = useAppDispatch();
  const result = useValidateUserIsLoggedIn();

  const webOrderContent = useAppSelector(selectWebOrderContentEntities);

  if ((refs as UseFetchRefs | undefined) === undefined) refs = mapUseFetchRefs(webOrderContent);

  useEffect(() => {
    refs = mergeFetchedTimestampRefAndStateEntities(refs, mapUseFetchRefs(webOrderContent));
  }, [webOrderContent]);

  const loadWebOrderContent = useCallback(
    async (id?: string) => {
      if (result.isError) {
        dispatch(setError({ value: result.errorMessage }));
      } else {
        if (id) {
          const ref = refs[id];

          if (!ref || ref.requestState !== REQUEST_STATE.PENDING) {
            refs[id] = { fetchedTimestamp: Date.now(), requestState: REQUEST_STATE.PENDING };
            await dispatch(fetchWebOrderContent(id));
          }
        }
      }
    },
    [dispatch, result],
  );

  return loadWebOrderContent;
};

export default useFetchWebOrderContent;
