import PropType from "prop-types";
import useLazyGetWebOrderContent from "../../../../features/webOrderContent/hooks/useLazyGetWebOrderContent";
import classNames from "classnames";
import { generatePath, Link, useLocation } from "react-router-dom";
import Label from "../../../shared/Label/Label";
import { useTablet } from "../../../../utils/viewport";
import { useTranslate } from "../../../../language/i18n";
import pages from "../../../../utils/pages";
import { getTimestamp } from "../../../../utils/getTimestamp";
import styles from "../Cases.module.scss";
import useLazyGetWebOrder from "../../../../features/webOrder/hooks/useLazyGetWebOrder";
import useLazyGetLines from "../../../../features/webOrderContent/hooks/useLazyGetLines";
import useLazyGetServiceType from "../../../../features/serviceType/hooks/useLazyGetServiceType";
import LoadingSpinner from "../../../shared/Spinner/LoadingSpinner";

const CaseRow = ({ id }: { id: string }) => {
  const isTablet = useTablet();
  const translate = useTranslate();
  const location = useLocation();

  const { webOrderContent, isLoading: isWebOrderContentLoading } = useLazyGetWebOrderContent({
    id,
    loadIfNotInitialized: true,
  });

  const { lines, isLoading: isLinesLoading } = useLazyGetLines({
    webOrderContentId: webOrderContent?.id,
    filter: { amount: 1 },
    loadIfNotInitialized: true,
  });

  const line = lines.length ? lines[0] : undefined;

  const { webOrder, isLoading: isWebOrderLoading } = useLazyGetWebOrder({
    id: webOrderContent?.webOrderId,
    loadIfNotInitialized: true,
  });

  const { serviceType, isLoading: isServiceTypeLoading } = useLazyGetServiceType({
    id: line?.serviceTypeId,
    loadIfNotInitialized: true,
  });

  if (isLinesLoading || isWebOrderContentLoading || isWebOrderLoading || isServiceTypeLoading) {
    return <LoadingSpinner />;
  }

  return (
    <div>
      {webOrderContent && (
        <li className={styles.card}>
          <div className={styles.header}>
            <div className={styles.left}>
              <div className={styles.bold}>
                <Link
                  to={{
                    pathname: generatePath(pages.MY_CASES_INFO.PATH, { id }),
                    state: {
                      id,
                      referrer: `${location.pathname}${location.search}`,
                    },
                  }}
                >
                  {`${translate("CASE")} ${webOrderContent.contentNumber} / ${serviceType?.name ?? ""}`}
                </Link>
              </div>
              <Label label={translate("ORDERED")} value={getTimestamp(webOrder?.orderedTimestamp)} inline />
            </div>
          </div>
          <div>
            <span
              className={classNames(
                styles.status,
                styles[`status${webOrderContent.status.value}`],
                isTablet && styles.left,
              )}
            >
              {translate(`ORDER_STATUS_${webOrderContent.status.value}`)}
            </span>
          </div>
        </li>
      )}
    </div>
  );
};

CaseRow.propTypes = {
  id: PropType.string.isRequired,
};

export default CaseRow;
