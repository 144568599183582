import PropTypes from "prop-types";
import { File, FileExcel, FileImage, FilePdf, FileWord } from "../../../../assets/icons";

const TYPE = {
  DOC: "doc",
  DOCX: "docx",
  JPEG: "jpeg",
  JPG: "jpg",
  PDF: "pdf",
  PNG: "png",
  UNKNOWN: "",
  XLS: "xls",
  XLSX: "xlsx",
} as const;

export function getFileType(fileName: string) {
  const index = fileName.lastIndexOf(".");

  return index > 0 ? fileName.slice(index + 1).toLowerCase() : TYPE.UNKNOWN;
}

const FileIcon = ({
  fileType,
  fileName,
  size,
  color,
  customCssClass,
}: {
  fileType?: string;
  fileName: string;
  size?: number | string;
  color?: string;
  customCssClass?: string;
}) => {
  const type = fileType ? fileType.toLowerCase() : getFileType(fileName);

  switch (type) {
    case TYPE.DOC:
    case TYPE.DOCX:
      return <FileWord size={size} color={color} customCssClass={customCssClass} />;
    case TYPE.JPEG:
    case TYPE.JPG:
    case TYPE.PNG:
      return <FileImage size={size} color={color} customCssClass={customCssClass} />;
    case TYPE.PDF:
      return <FilePdf size={size} color={color} customCssClass={customCssClass} />;
    case TYPE.XLS:
    case TYPE.XLSX:
      return <FileExcel size={size} color={color} customCssClass={customCssClass} />;
    default:
      return <File size={size} color={color} customCssClass={customCssClass} />;
  }
};

FileIcon.propTypes = {
  fileType: PropTypes.string,
  fileName: PropTypes.string.isRequired,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: PropTypes.string,
  customCssClass: PropTypes.string,
};

export default FileIcon;
