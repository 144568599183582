import { useCallback, useEffect, useRef } from "react";
import { shallowEqual } from "react-redux";
import { useAppSelector } from "../../../app/hooks";
import { REQUEST_STATE } from "../../dataTypes";
import { selectFilesById } from "../../file/fileSelectors";
import { PageContentFilesFilter } from "../dataTypes";
import { selectPageContent } from "../pageContentSelectors";
import useFetchFiles from "./useFetchFiles";

const useLazyGetFiles = ({
  pageContentId,
  filter,
  loadIfNotInitialized = false,
}: {
  pageContentId?: string;
  filter?: PageContentFilesFilter;
  loadIfNotInitialized?: boolean;
}) => {
  const fetchFiles = useFetchFiles();
  const pageContent = useAppSelector(selectPageContent(pageContentId));
  const hasInitalized = useRef(false);

  const filesObj = pageContent?.files;

  const requestState = filesObj?.requestState;
  const errorMessage = filesObj?.errorMessage;

  const files = useAppSelector(selectFilesById(filesObj?.ids), shallowEqual);

  const isUninitialized = requestState === undefined;
  const isLoading = requestState === REQUEST_STATE.PENDING;
  const isError = requestState === REQUEST_STATE.REJECTED;
  const isSuccess = requestState === REQUEST_STATE.FULFILLED;

  const loadFiles = useCallback(async () => {
    await fetchFiles({ pageContentId: pageContentId, filter });
  }, [pageContentId, fetchFiles, filter]);

  useEffect(() => {
    hasInitalized.current = false;
  }, [pageContentId, filter]);

  useEffect(() => {
    if (loadIfNotInitialized && isUninitialized && !hasInitalized.current) {
      hasInitalized.current = true;
      loadFiles();
    }
  }, [isUninitialized, loadIfNotInitialized, loadFiles]);

  return {
    files,
    isUninitialized,
    isLoading,
    isError,
    isSuccess,
    errorMessage,
    fetchFiles: loadFiles,
  };
};

export default useLazyGetFiles;
