import PropType from "prop-types";
import { useEffect } from "react";
import styles from "./CaseInfo.module.scss";
import pages from "../../../../../utils/pages";
import Label from "../../../../shared/Label/Label";
import { useTranslate } from "../../../../../language/i18n";
import { WebOrderContent } from "../../../../../features/webOrderContent/dataTypes";
import Breadcrumbs from "../../../../shared/Breadcrumbs/Breadcrumbs";
import useLazyGetWebOrder from "../../../../../features/webOrder/hooks/useLazyGetWebOrder";
import { getTimestamp } from "../../../../../utils/getTimestamp";
import { LoadingSpinner } from "@myloc/myloc-gui";

const CaseInfo = ({
  caseDetails,
  setBreadcrumbs,
  referrer,
  serviceTypeName,
}: {
  caseDetails: WebOrderContent;
  setBreadcrumbs: (breadcrumbs: Parameters<typeof Breadcrumbs>["0"]["breadcrumbs"]) => void;
  referrer?: string;
  serviceTypeName: string;
}) => {
  const translate = useTranslate();
  const { webOrder, isLoading } = useLazyGetWebOrder({ id: caseDetails.webOrderId, loadIfNotInitialized: true });

  useEffect(() => {
    setBreadcrumbs([
      {
        text: `${translate(pages.MY_PAGE.NAME)} - ${translate(pages.MY_CASES.NAME)}`,
        link: referrer ?? pages.MY_CASES.PATH,
      },
      {
        text: `${translate("CASE")} - ${translate(pages.MY_CASES_INFO.NAME)}`,
      },
    ]);
  }, [referrer, setBreadcrumbs, translate]);

  if (isLoading) return <LoadingSpinner />;

  return (
    <div>
      <div className={styles.header}>
        <div className={styles.left}>
          <h4 className={styles.title}>{`${translate(pages.MY_CASES_INFO.NAME)}`}</h4>
        </div>
      </div>

      <div className={styles.content}>
        <div>
          <Label label={translate("ORDERED")} value={getTimestamp(webOrder?.orderedTimestamp)} />
          <Label label={translate("SERVICE_TYPE")} value={serviceTypeName} />
        </div>
        <div>
          <Label label={translate("STATUS")} value={webOrder?.status.label} />
          <Label label={translate("STATUS_COMMENT")} value={caseDetails.externalStatusComment} />
        </div>
      </div>
    </div>
  );
};

CaseInfo.propTypes = {
  caseDetails: PropType.object.isRequired,
  setBreadcrumbs: PropType.func.isRequired,
  referrer: PropType.string,
  serviceTypeName: PropType.string.isRequired,
};

export default CaseInfo;
