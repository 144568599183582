import { withPage } from "@myloc/myloc-utils";
import PropTypes from "prop-types";
import pages from "../../../utils/pages";
import Page from "../../shared/Page/Page";

import { generatePath, useParams, Switch, Route } from "react-router-dom";
import { useState } from "react";
import SubPageMenu from "../../shared/SubPageMenu/SubPageMenu";
import CaseInfo from "./Sections/CaseInfo/CaseInfo";
import { useTranslate } from "../../../language/i18n";
import styles from "./CaseDetailsPage.module.scss";
import useGetWebOrderContent from "../../../features/webOrderContent/hooks/useGetWebOrderContent";
import useLazyGetServiceType from "../../../features/serviceType/hooks/useLazyGetServiceType";
import useLazyGetLines from "../../../features/webOrderContent/hooks/useLazyGetLines";
import LoadingSpinner from "../../shared/Spinner/LoadingSpinner";
import CaseAttachments from "./Sections/CaseAttachment/CaseAttachments";
import Form from "./Sections/Form/Form";

interface CaseParams {
  id?: string;
}

function CaseDetailsPage({ referrer }: { referrer?: string }) {
  const translate = useTranslate();
  const { id } = useParams<CaseParams>();
  const { webOrderContent, isLoading: isWebOrderContentLoading } = useGetWebOrderContent({ id });
  const { lines, isLoading: isLinesLoading } = useLazyGetLines({
    webOrderContentId: id,
    filter: { amount: 1 },
    loadIfNotInitialized: true,
  });

  const { serviceType, isLoading: isServiceTypeLoading } = useLazyGetServiceType({
    id: lines.length ? lines[0].serviceTypeId : undefined,
    loadIfNotInitialized: true,
  });

  const links = [
    { NAME: pages.MY_CASES_INFO.NAME, PATH: generatePath(pages.MY_CASES_INFO.PATH, { id }) },
    { NAME: pages.MY_CASES_FORM_DATA.NAME, PATH: generatePath(pages.MY_CASES_FORM_DATA.PATH, { id }) },
    { NAME: pages.MY_CASES_ATTACHMENTS.NAME, PATH: generatePath(pages.MY_CASES_ATTACHMENTS.PATH, { id }) },
  ];

  const [breadcrumbs, setBreadcrumbs] = useState([
    { text: translate(pages.MY_CASES.NAME), link: pages.MY_CASES.PATH },
    { text: translate(pages.MY_CASES_INFO.NAME) },
  ]);

  return (
    <Page
      breadcrumbs={breadcrumbs}
      title={translate("CASE")}
      description={`${webOrderContent?.contentNumber ?? ""} / ${serviceType?.name ?? ""}`}
      customCssClass={styles.page}
      isLoading={isWebOrderContentLoading || isLinesLoading || isServiceTypeLoading}
    >
      {!webOrderContent || !serviceType?.name ? (
        <LoadingSpinner />
      ) : (
        <div className={styles.pageContent}>
          <SubPageMenu links={links} />

          <Switch>
            <Route exact path={pages.MY_CASES_INFO.PATH}>
              <CaseInfo
                caseDetails={webOrderContent}
                setBreadcrumbs={setBreadcrumbs}
                referrer={referrer}
                serviceTypeName={serviceType.name}
              />
            </Route>
            <Route exact path={pages.MY_CASES_FORM_DATA.PATH}>
              <Form formId={webOrderContent.formId} setBreadcrumbs={setBreadcrumbs} referrer={referrer} />
            </Route>
            <Route exact path={pages.MY_CASES_ATTACHMENTS.PATH}>
              <CaseAttachments caseId={webOrderContent.id} setBreadcrumbs={setBreadcrumbs} referrer={referrer} />
            </Route>
          </Switch>
        </div>
      )}
    </Page>
  );
}

CaseDetailsPage.propTypes = {
  referrer: PropTypes.string,
};

export default withPage(CaseDetailsPage);
