import { URL } from "@myloc/myloc-utils";
import { isFiltrationFilter, isFiltrationFilterArray } from "./isFiltrationFilter";
import { isSortFilter, isSortFilterArray } from "./isSortFilter";
import { mapFiltrationFilter, mapFiltrationFilterArray } from "./mapFiltrationFilter";
import { mapSortFilter, mapSortFilterArray } from "./mapSortFilter";

export default function setQueryFilters(url: URL, filters?: { [filter: string]: any }) {
  for (const filter in filters) {
    let value = filters[filter];

    if (isFiltrationFilterArray(value)) value = mapFiltrationFilterArray(value);
    else if (isFiltrationFilter(value)) value = mapFiltrationFilter(value);
    else if (isSortFilterArray(value)) value = mapSortFilterArray(value);
    else if (isSortFilter(value)) value = mapSortFilter(value);
    else value = filters[filter];

    if (value !== undefined && value !== null && value !== "") url.addParameter(filter, value);
  }
}
