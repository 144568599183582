import { AdditionalRequestState, FetchedIdObject, IdObject } from "../dataTypes";

export const userAccessMessageSliceName = "userAccessMessage";

export interface ResponseUserAccessMessage extends IdObject {
  message: string;
  description: string;
}

export interface UserAccessMessage extends ResponseUserAccessMessage, AdditionalRequestState, FetchedIdObject {}

export interface UpdateUserAccessMessageRequest {
  accept: boolean;
}
