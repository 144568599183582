import { Method, Request, sendRequest } from "@myloc/myloc-utils";
import { AppDispatch } from "../../../app/store";
import { api } from "../../../config/settings";
import HttpStatusCodes from "../../../utils/HttpStatusCodes";
import { EntityActionReducerBuilderInterface, REQUEST_STATE } from "../../dataTypes";
import { createAsyncThunk } from "../../utils/createAsyncThunk";
import defaultRestOptions from "../../utils/defaultRestOptions";
import { ResponseServiceTypeCategory, ServiceTypeCategory, serviceTypeCategorySliceName } from "../dataTypes";
import { serviceTypeCategoryAdapter } from "../serviceTypeCategoryAdapter";

async function getServiceTypeCategory(dispatch: AppDispatch, id: string) {
  const url = api.serviceTypeCategory.serviceTypeCategory(id);
  const request = new Request(url, Method.GET);

  return await sendRequest(request, {}, await defaultRestOptions({ dispatch }));
}

export const fetchServiceTypeCategory = createAsyncThunk<ResponseServiceTypeCategory, string>(
  serviceTypeCategorySliceName + "/fetchServiceTypeCategory",
  (dispatch, id) => getServiceTypeCategory(dispatch, id),
);

export const addFetchServiceTypeCategoryReducers = (
  builder: EntityActionReducerBuilderInterface<ServiceTypeCategory>,
) => {
  builder.addCase(fetchServiceTypeCategory.pending, (state, action) => {
    const id = action.meta.arg;
    const serviceTypeCategory = state.entities[id] ?? ({ id } as ServiceTypeCategory);

    serviceTypeCategoryAdapter.upsertOne(state, {
      ...serviceTypeCategory,
      requestState: REQUEST_STATE.PENDING,
      errorMessage: undefined,
    });
  });
  builder.addCase(fetchServiceTypeCategory.fulfilled, (state, action) => {
    const { payload, meta } = action;
    const id = meta.arg;

    serviceTypeCategoryAdapter.updateOne(state, {
      id,
      changes: { ...payload, fetchedTimestamp: Date.now(), requestState: REQUEST_STATE.FULFILLED },
    });
  });
  builder.addCase(fetchServiceTypeCategory.rejected, (state, action) => {
    const { payload, meta } = action;

    if (payload) {
      const id = meta.arg;

      if (payload.httpStatusCode === HttpStatusCodes.NOT_FOUND) serviceTypeCategoryAdapter.removeOne(state, id);
      else
        serviceTypeCategoryAdapter.updateOne(state, {
          id,
          changes: { requestState: REQUEST_STATE.REJECTED, errorMessage: payload.message },
        });
    }
  });
};
