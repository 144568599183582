import { createSlice } from "@reduxjs/toolkit";
import { AdditionalRequestState } from "../dataTypes";
import { webOrderSliceName } from "./dataTypes";
import { webOrderAdapter } from "./webOrderAdapter";
import { addFetchWebOrderReducers } from "./reducers/fetchWebOrder";
import { addCreateAndOrderWebOrderReducers } from "./reducers/createAndOrderWebOrder";

const slice = createSlice({
  name: webOrderSliceName,
  initialState: webOrderAdapter.getInitialState<AdditionalRequestState>({}),
  reducers: {},
  extraReducers: builder => {
    addCreateAndOrderWebOrderReducers(builder);
    addFetchWebOrderReducers(builder);
  },
});

const { reducer } = slice;

export default reducer;
