import { useMemo } from "react";
import { useAppSelector } from "../../app/hooks";
import { useTranslate } from "../../language/i18n";
import HttpStatusCodes from "../../utils/HttpStatusCodes";
import { selectSessionId } from "../session/sessionSelectors";
import { getValidationResult } from "./getValidationResults";

const validateUserIsLoggedIn = (translate: ReturnType<typeof useTranslate>, sessionId?: string) => {
  if (!sessionId) return getValidationResult(true, translate("USER_IS_NOT_LOGGED_IN"), HttpStatusCodes.UNAUTHORIZED);
  return getValidationResult(false);
};

const useValidateUserIsLoggedIn = () => {
  const translate = useTranslate();
  const sessionId = useAppSelector(selectSessionId);

  return useMemo(() => validateUserIsLoggedIn(translate, sessionId), [sessionId, translate]);
};

export { useValidateUserIsLoggedIn };
