import PropType from "prop-types";
import { useCallback, useState } from "react";
import { ServiceTypesFilter } from "../../../features/serviceType/dataTypes";
import Paginate from "../../shared/Pagination/Paginate";
import { selectCount } from "../../../features/serviceType/serviceTypeSelectors";
import useFetchServiceTypes from "../../../features/serviceType/hooks/useFetchServiceTypes";
import ServiceCard from "../ServiceCard/ServiceCard";
import { useTranslate } from "../../../language/i18n";
import styles from "./ServiceList.module.scss";

const ServiceList = ({
  filters,
  searchValue,
  setSearchValue,
}: {
  filters: ServiceTypesFilter;
  searchValue?: string;
  setSearchValue: (text: string) => void;
}) => {
  const translate = useTranslate();
  const [ids, setIds] = useState<string[]>();
  const loadServiceTypes = useFetchServiceTypes();

  const fetchServiceTypes = useCallback(
    (filter: ServiceTypesFilter) => {
      return loadServiceTypes({ filter });
    },
    [loadServiceTypes],
  );

  return (
    <Paginate filter={filters} data={ids} setData={setIds} request={fetchServiceTypes} countSelect={selectCount}>
      <div className={styles.row}>
        <input
          className={styles.input}
          placeholder={translate("SEARCH")}
          value={searchValue}
          onChange={e => setSearchValue(e.target.value)}
        />
      </div>
      <ul className={styles.serviceTypesList}>
        {ids && ids.length > 0 && ids.map(id => <ServiceCard key={id} id={id} />)}
      </ul>
    </Paginate>
  );
};

ServiceList.propTypes = {
  filters: PropType.object.isRequired,
  searchValue: PropType.string,
  setSearchValue: PropType.func.isRequired,
};

export default ServiceList;
