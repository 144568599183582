import { ValueOf } from "../../utils/dataTypes";
import { AdditionalRequestState, FetchedIdObject, IdObject, LabelValue } from "../dataTypes";
import { CreateWebOrderContentRequest, CreateWebOrderContentResponse } from "../webOrderContent/dataTypes";

export const webOrderSliceName = "webOrder";

export const CUSTOMER_TYPE = {
  CLIENT: "CLIENT",
  FACILITY: "FACILITY",
  PERSON: "PERSON",
} as const;

interface CustomerType extends LabelValue {
  value: ValueOf<typeof CUSTOMER_TYPE>;
}

export const STATUS = {
  PRELIMINARY: 10,
  ORDERED: 20,
  IN_PROCESS: 80,
  COMPLETED: 90,
  CANCELLED: 99,
} as const;

interface Status extends LabelValue {
  value: ValueOf<typeof STATUS>;
}

export interface ResponseWebOrder extends IdObject {
  orderNumber: number;
  customerType: CustomerType;
  customerId: string;
  status: Status;
  orderedTimestamp?: number;
  completedTimestamp?: number;
}

export interface WebOrder extends ResponseWebOrder, AdditionalRequestState, FetchedIdObject {}

export interface CreateWebOrderResponse {
  webOrder: ResponseWebOrder;
  contents?: CreateWebOrderContentResponse[];
}

interface WebOrderCustomerRequest extends IdObject {
  type: ValueOf<typeof CUSTOMER_TYPE>;
}

export interface CreateWebOrderRequest {
  customer: WebOrderCustomerRequest;
  contents?: CreateWebOrderContentRequest[];
  files?: Blob[];
}
