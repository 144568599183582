import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { withPage } from "@myloc/myloc-utils";
import { useTranslate } from "../../../language/i18n";
import pages from "../../../utils/pages";
import { Form, InputField, Button, THEME } from "@myloc/myloc-gui";
import styles from "./ResetPasswordPage.module.scss";
import useUpdatePassword from "../../../features/password/hooks/useUpdatePassword";
import { UpdatePasswordRequest } from "../../../features/password/dataTypes";

function ResetPasswordPage({ search }: { search?: string }) {
  const translate = useTranslate();
  const history = useHistory();
  const { updatePassword, updatePasswordWithToken, isSuccess, isError } = useUpdatePassword();
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const isUpdatingPassword = useRef(false);

  const searchParams = new URLSearchParams(search);
  const token = searchParams.get("resettoken");

  useEffect(() => {
    if (isUpdatingPassword.current) {
      if (isSuccess) {
        history.push("/");
      }
    }

    isUpdatingPassword.current = !(isSuccess || isError);
  }, [history, isError, isSuccess]);

  const handleNewPassword = async () => {
    setIsLoading(true);

    const data: UpdatePasswordRequest = {
      password: password,
      passwordRepeat: repeatPassword,
    };

    if (token) await updatePasswordWithToken({ ...data, token });
    else await updatePassword({ ...data, previous: "" });

    setIsLoading(false);
  };

  const disabled = password === "" || password !== repeatPassword;

  //TODO add password pattern?
  return (
    <div className={styles.passwordContainer}>
      <Form onSubmit={handleNewPassword} className={styles.form}>
        <h2 className={styles.title}>{translate(pages.RESET_PASSWORD.NAME)}</h2>
        <InputField
          label={translate("NEW_PASSWORD")}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.currentTarget.value)}
          value={password}
          required
          type={"password"}
        />
        <InputField
          label={translate("REPEAT_PASSWORD")}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setRepeatPassword(e.currentTarget.value)}
          value={repeatPassword}
          required
          type={"password"}
        />
        <div className={styles.buttons}>
          <Button theme={THEME.PLAIN_PRIMARY} onClick={() => history.push("/")}>
            {translate("CANCEL")}
          </Button>
          <Button type="submit" isLoading={isLoading} disabled={disabled}>
            {translate("SAVE_NEW_PASSWORD")}
          </Button>
        </div>
      </Form>
    </div>
  );
}

ResetPasswordPage.propTypes = {
  search: PropTypes.string,
};

export default withPage(ResetPasswordPage);
