import { Dictionary } from "@reduxjs/toolkit";
import cloneDeep from "lodash.clonedeep";
import { useCallback, useEffect } from "react";
import isEqual from "react-fast-compare";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { RequestState, REQUEST_STATE, UseFetchRef, UseFetchRefs } from "../../dataTypes";
import { setError } from "../../dialog/dialogSlice";
import mergeFetchedTimestampRefAndStateEntities from "../../utils/mergeFetchedTimestampRefAndStateEntities";
import { useValidateUserIsLoggedIn } from "../../utils/useValidateUserIsLoggedIn";
import { PageContent, PageContentHyperlinksFilter } from "../dataTypes";
import { selectPageContentEntities } from "../pageContentSelectors";
import { fetchHyperlinks } from "../reducers/fetchHyperlinks";

interface Refs extends UseFetchRefs {
  [id: string]: (UseFetchRef & { hyperlinks: { requestState?: RequestState } }) | undefined;
}

let refs: Refs;
const filterRef: { [pageContentId: string]: PageContentHyperlinksFilter | undefined } = {};

const mapPageContents = (pageContents: Dictionary<PageContent>) => {
  return Object.entries(pageContents).reduce((result, [id, pageContent]) => {
    result[id] = pageContent
      ? {
          hyperlinks: { requestState: pageContent.hyperlinks?.requestState },
          fetchedTimestamp: pageContent.fetchedTimestamp,
          requestState: pageContent.requestState,
        }
      : undefined;

    return result;
  }, {} as Refs);
};

const useFetchHyperlinks = () => {
  const dispatch = useAppDispatch();
  const result = useValidateUserIsLoggedIn();

  const pageContents = useAppSelector(selectPageContentEntities);

  if ((refs as UseFetchRefs | undefined) === undefined) refs = mapPageContents(pageContents);

  useEffect(() => {
    refs = mergeFetchedTimestampRefAndStateEntities(refs, mapPageContents(pageContents));
  }, [pageContents]);

  const loadHyperlinks = useCallback(
    async ({ pageContentId, filter }: { pageContentId?: string; filter?: PageContentHyperlinksFilter }) => {
      if (result.isError) {
        dispatch(setError({ value: result.errorMessage }));
      } else {
        if (pageContentId) {
          const ref = refs[pageContentId];

          if (!isEqual(filter, filterRef[pageContentId]) || ref?.hyperlinks.requestState !== REQUEST_STATE.PENDING) {
            refs[pageContentId] = {
              hyperlinks: { requestState: REQUEST_STATE.PENDING },
              fetchedTimestamp: Date.now(),
              requestState: REQUEST_STATE.PENDING,
            };
            filterRef[pageContentId] = cloneDeep(filter);
            await dispatch(fetchHyperlinks({ pageContentId, filter }));
          }
        }
      }
    },
    [dispatch, result],
  );

  return loadHyperlinks;
};

export default useFetchHyperlinks;
