import { useEffect, useRef } from "react";
import useForceUpdate from "../../../hooks/useForceUpdate";
import useLazyGetForm from "./useLazyGetForm";

const useGetForm = ({ id }: { id?: string }) => {
  const { isLoading: isLazyLoading, fetchForm, ...rest } = useLazyGetForm({ id });
  const forceUpdate = useForceUpdate();
  const initiationStatus = useRef<"initiating" | "initiated">();

  useEffect(() => {
    const initiateForm = async () => {
      if (!initiationStatus.current) {
        initiationStatus.current = "initiating";
        forceUpdate();
        await fetchForm();
        initiationStatus.current = "initiated";
      }
    };

    initiateForm();
  }, [fetchForm, forceUpdate]);

  const isLoading = !initiationStatus.current || isLazyLoading;

  return { ...rest, isLoading, refetchForm: fetchForm };
};

export default useGetForm;
