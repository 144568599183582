import { createSlice } from "@reduxjs/toolkit";
import { AdditionalPaginationState, AdditionalRequestState } from "../dataTypes";
import { serviceTypeSliceName } from "./dataTypes";
import { serviceTypeAdapter } from "./serviceTypeAdapter";
import { addFetchServiceTypeReducers } from "./reducers/fetchServiceType";
import { addFetchServiceTypesReducers } from "./reducers/fetchServiceTypes";
import { addFetchFilesReducers } from "./reducers/fetchFiles";

const slice = createSlice({
  name: serviceTypeSliceName,
  initialState: serviceTypeAdapter.getInitialState<AdditionalRequestState & AdditionalPaginationState>({ count: 0 }),
  reducers: {},
  extraReducers: builder => {
    addFetchFilesReducers(builder);
    addFetchServiceTypeReducers(builder);
    addFetchServiceTypesReducers(builder);
  },
});

const { reducer } = slice;

export default reducer;
