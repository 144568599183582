import { Method, Request, sendRequest } from "@myloc/myloc-utils";
import { AppDispatch } from "../../../app/store";
import { api } from "../../../config/settings";
import setQueryFilters from "../../utils/setQueryFilters";
import {
  EntityActionReducerBuilderInterface,
  PaginationResponse,
  FulfilledUpdateMethod,
  FULFILLED_UPDATE_METHOD,
  REQUEST_STATE,
} from "../../dataTypes";
import { ResponseFile } from "../../file/dataTypes";
import { createAsyncThunk } from "../../utils/createAsyncThunk";
import defaultRestOptions from "../../utils/defaultRestOptions";
import { WebOrderContent, WebOrderContentFilesFilter, webOrderContentSliceName } from "../dataTypes";
import { webOrderContentAdapter } from "../webOrderContentAdapter";

async function getFiles(dispatch: AppDispatch, webOrderContentId: string, filters?: WebOrderContentFilesFilter) {
  const url = api.webOrderContent.files(webOrderContentId);

  setQueryFilters(url, filters);

  const request = new Request(url, Method.GET);

  return await sendRequest(request, {}, await defaultRestOptions({ dispatch }));
}

export const fetchFiles = createAsyncThunk<
  PaginationResponse<ResponseFile>,
  { webOrderContentId: string; filter?: WebOrderContentFilesFilter; fulfilledUpdateMethod?: FulfilledUpdateMethod }
>(webOrderContentSliceName + "/fetchFiles", (dispatch, { webOrderContentId, filter }) =>
  getFiles(dispatch, webOrderContentId, filter),
);

export const addFetchFilesReducers = (builder: EntityActionReducerBuilderInterface<WebOrderContent>) => {
  builder.addCase(fetchFiles.pending, (state, action) => {
    const {
      meta: {
        arg: { webOrderContentId },
      },
    } = action;

    const webOrderContent = state.entities[webOrderContentId] ?? ({ id: webOrderContentId } as WebOrderContent);

    if (!webOrderContent.files) webOrderContent.files = { ids: [] };

    webOrderContent.files.errorMessage = undefined;
    webOrderContent.files.requestState = REQUEST_STATE.PENDING;
    webOrderContentAdapter.upsertOne(state, webOrderContent);
  });
  builder.addCase(fetchFiles.fulfilled, (state, action) => {
    const {
      meta: {
        arg: { webOrderContentId, fulfilledUpdateMethod = FULFILLED_UPDATE_METHOD.UPSERT_MANY },
      },
      payload,
    } = action;

    const webOrderContent = state.entities[webOrderContentId];

    if (webOrderContent) {
      if (webOrderContent.files) {
        const ids = fulfilledUpdateMethod === FULFILLED_UPDATE_METHOD.SET_ALL ? [] : webOrderContent.files.ids;

        payload.page.forEach(file => {
          if (!ids.includes(file.id)) ids.push(file.id);
        });

        webOrderContent.files.ids = ids;
        webOrderContent.files.requestState = REQUEST_STATE.FULFILLED;
      }
    }
  });
  builder.addCase(fetchFiles.rejected, (state, action) => {
    const {
      meta: {
        arg: { webOrderContentId },
      },
      payload,
    } = action;

    const webOrderContent = state.entities[webOrderContentId];

    if (webOrderContent) {
      if (webOrderContent.files) {
        webOrderContent.files.requestState = REQUEST_STATE.REJECTED;
        webOrderContent.files.errorMessage = payload?.message;
      }
    }
  });
};
