import { SESSION_ID, REMEMBER_ME } from "./storeVariables";

const LOCAL_STORAGE = "LOCAL_STORAGE";
const SESSION_STORAGE = "SESSION_STORAGE";

type LOCAL_STORAGE = "LOCAL_STORAGE";
type SESSION_STORAGE = "SESSION_STORAGE";

const _moveTo = (destinationStorage: LOCAL_STORAGE | SESSION_STORAGE) => {
  const session = sessionStorage.getItem(SESSION_ID);
  const storage = localStorage.getItem(SESSION_ID);

  if (session && storage) {
    if (destinationStorage === LOCAL_STORAGE) localStorage.setItem(SESSION_ID, session);
    else sessionStorage.setItem(SESSION_ID, storage);
  }
};

const _getRememberMe = (rememberMe?: string | boolean) => {
  if (rememberMe === undefined) {
    //Try to get the remember me setting, first from local Storage, then session Storage
    const getLocalRememberMe = localStorage.getItem(REMEMBER_ME);

    if (getLocalRememberMe) rememberMe = JSON.parse(getLocalRememberMe);

    if (rememberMe === undefined) {
      const getSessionRememberMe = sessionStorage.getItem(REMEMBER_ME);

      if (getSessionRememberMe) rememberMe = JSON.parse(getSessionRememberMe);
    }
  }

  return typeof rememberMe === "string" ? rememberMe.toUpperCase() === "TRUE" : !!rememberMe;
};

function _getStorage(rememberMe?: boolean) {
  return !rememberMe ? sessionStorage : localStorage;
}

//TODO, LOAD_ITEM SHOULD NOT BE USED FOR rememberMe!

function loadItem(item: string) {
  //Use local storage only if rememberMe is true
  const data = _getStorage(_getRememberMe()).getItem(item);

  //If no success - return null
  if (data === null) {
    return data;
  }

  try {
    //Try to parse as json object
    return JSON.parse(data);
  } catch (e) {
    //No success, try as boolean
    if (data === "true") return true;
    else if (data === "false") return false;

    return data === "null" ? null : data;
  }
}

function saveItem(item: string, rememberMe: boolean, data?: any) {
  if (typeof data !== "string") {
    data = data === undefined || data === null ? undefined : JSON.stringify(data);
  }

  //If it is the rememberMe setting itself, use it!
  if (item === REMEMBER_ME) {
    const previousRememberMe = _getRememberMe(data);

    if (rememberMe === previousRememberMe) return;
    if (data) rememberMe = !!data;

    if (!rememberMe) {
      _moveTo(SESSION_STORAGE);
      localStorage.clear();
    } else {
      _moveTo(LOCAL_STORAGE);
    }
  }

  if (data) {
    _getStorage(rememberMe).setItem(item, data);
  } else {
    deleteItem(item);
  }
}

function deleteItem(item: string) {
  localStorage.removeItem(item);
  sessionStorage.removeItem(item);
}

export default { loadItem, saveItem, deleteItem };
