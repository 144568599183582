import { EntityActionReducerBuilderInterface, REQUEST_STATE } from "../../dataTypes";
import { createAndOrderWebOrder } from "../../webOrder/reducers/createAndOrderWebOrder";
import { MylocFile, ResponseFile } from "../dataTypes";
import { fileAdapter } from "../fileAdapter";

export const addCreateAndOrderWebOrderReducers = (builder: EntityActionReducerBuilderInterface<MylocFile>) => {
  builder.addCase(createAndOrderWebOrder.fulfilled, (state, action) => {
    const contents = action.payload.contents;

    if (contents) {
      const files = contents.reduce((files, content) => {
        return content.files ? [...files, ...content.files] : files;
      }, [] as ResponseFile[]);

      fileAdapter.addMany(
        state,
        files.map(file => ({ ...file, fetchedTimestamp: Date.now(), requestState: REQUEST_STATE.FULFILLED })),
      );
    }
  });
};
