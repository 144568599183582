import PropTypes from "prop-types";
import classNames from "classnames";
import { ArrowDown, ArrowUp, Cross, IconButton } from "@myloc/myloc-gui";
import { useRef, useState } from "react";
import { OptionalSelected, ValueOf } from "../../../utils/dataTypes";
import { useTranslate } from "../../../language/i18n";
import OnClickOutside from "../../../utils/OnClickOutside";
import isOutOfViewport from "../../../utils/isOutOfViewport";

import styles from "./SelectField.module.scss";
import colors from "../../../style/colors.scss";
import ListItem from "./ListItem/ListItem";

const SIZE = {
  THIN: "thin",
  DEFAULT: "default",
} as const;

export interface SelectFieldOption extends Record<string, any> {}

export interface CustomSettingType<T extends SelectFieldOption> {
  id?: keyof T;
  display?: keyof T;
  autocomplete?: boolean;
  template?: (item: T) => JSX.Element;
}

const SelectField = <T extends SelectFieldOption>({
  options,
  onSelect,
  selectedId,
  label,
  customSettings,
  disabled,
  customCssClass,
  customSelectionCss,
  name,
  required,
  prepopulate = true,
  size = SIZE.DEFAULT,
}: {
  options: readonly Readonly<T>[];
  onSelect: (selected?: T) => void;
  selectedId?: T[keyof T];
  label: string;
  customSettings?: CustomSettingType<T>;
  disabled?: boolean;
  customCssClass?: string;
  customSelectionCss?: string;
  name?: string;
  required?: boolean;
  prepopulate?: boolean;
  size?: ValueOf<typeof SIZE>;
}) => {
  const translate = useTranslate();

  const [value, setValue] = useState<string | number | null>(null);
  const [isOpen, setOpen] = useState(false);
  const [hasFocus, setHasFocus] = useState(false);
  const [highlightedIndex, setHighlightedIndex] = useState(0);
  const searchRef = useRef<HTMLInputElement>(null);

  const inputDisabled = disabled ?? options.length === 0;

  const settings: OptionalSelected<Required<CustomSettingType<T>>, "template"> = {
    display: "value",
    id: "id",
    autocomplete: true,
    ...customSettings,
  };

  const handleOnSelect = (item: T) => {
    onSelect(item);
    setValue(null);
    setOpen(false);
    setHasFocus(false);
    setHighlightedIndex(-1);
  };

  const handleOnFocus = () => {
    if (value === null && selectedId) {
      const option = options.find(option => option[settings.id] === selectedId);

      if (option) {
        const displayValue: any = option[settings.display];

        if (displayValue && typeof displayValue.toString === "function") {
          setValue(displayValue.toString());
        }
      }
    }

    setHasFocus(true);
    setHighlightedIndex(-1);
  };

  const onChangeValue: React.ChangeEventHandler<HTMLInputElement> = e => {
    const val = e.currentTarget.value;

    setHighlightedIndex(0);
    setOpen(true);
    setValue(val);
  };

  const clear = (event: React.SyntheticEvent) => {
    event.preventDefault();

    setOpen(false);
    setValue(null);
    onSelect(undefined);
  };

  const refCallback = (el: HTMLUListElement | null) => {
    if (!el) return;
    if (isOutOfViewport(el).bottom) el.classList.add(styles["positionAbove"]);
  };

  const toggleOpen = () => {
    settings.autocomplete && setValue("");
    setOpen(open => !open);
  };

  const onClickOutside = () => {
    setOpen(false);
    setValue(null);
  };

  const onPressEscape = () => {
    setOpen(false);
    searchRef.current?.focus();
  };

  const filteredItems = () => {
    if (!value || !settings.autocomplete) return options;
    return options.filter(suggestion => {
      const fieldValue: any = suggestion[settings.display];

      if (fieldValue && typeof fieldValue.toLowerCase === "function") {
        return (fieldValue.toLowerCase().indexOf(value.toString().toLowerCase()) ?? -1) > -1;
      }

      return false;
    });
  };

  const highlight = (highlightIndex: number) => {
    if (!isOpen) return;
    if (highlightIndex < 0) highlightIndex = Math.max(0, filteredItems().length - 1);
    if (highlightIndex >= filteredItems().length) highlightIndex = 0;

    setHighlightedIndex(highlightIndex);
  };

  const onKeyDown: React.KeyboardEventHandler = event => {
    switch (event.key) {
      case "Escape":
        onPressEscape();
        break;

      case "ArrowDown":
        highlight(highlightedIndex + 1);
        event.preventDefault();
        break;

      case "ArrowUp":
        highlight(highlightedIndex - 1);
        event.preventDefault();
        break;

      case "Enter":
        handleOnSelect(filteredItems()[highlightedIndex]);
        break;

      case " ":
        value === "" && handleOnSelect(filteredItems()[highlightedIndex]);
        break;

      case "Tab":
        isOpen && event.preventDefault();
        break;
    }
  };

  const getId = (item: Readonly<T>) => {
    const itemId: any = item[settings.id];

    if (typeof itemId === "string" || typeof itemId === "number") {
      return itemId;
    } else if (typeof itemId.toString === "function") {
      return itemId.toString();
    }

    throw Error("Id is not string or number");
  };

  const ListItems = () => {
    const items = filteredItems();

    return items.length ? (
      <>
        {items.map((item, index) => (
          <ListItem
            key={getId(item)}
            item={item}
            settings={settings}
            handleOnSelect={handleOnSelect}
            isSelected={selectedId === item[settings.id]}
            isHighlighted={index === highlightedIndex}
          />
        ))}
      </>
    ) : (
      <li className={classNames(styles.listItem, styles.noMatch)}>{translate("NO_RESULT_FOUND")}</li>
    );
  };

  const checkIfSelectedIdIsDefined = () => {
    return selectedId === 0 || selectedId === false || !!selectedId;
  };

  return (
    <OnClickOutside call={onClickOutside}>
      <div className={classNames(styles.selectField, customCssClass)}>
        <label>
          <span
            className={classNames(
              styles.label,
              styles[size],
              (value || isOpen || checkIfSelectedIdIsDefined()) && styles.small,
              hasFocus && styles.focus,
            )}
          >
            {label} {required && label && " *"}
          </span>
          <input
            type="text"
            name={name}
            onFocus={handleOnFocus}
            onClick={toggleOpen}
            onChange={onChangeValue}
            onKeyDown={onKeyDown}
            readOnly={!settings.autocomplete}
            value={value ?? (checkIfSelectedIdIsDefined() && selectedItem(options, settings, selectedId)) ?? ""}
            disabled={inputDisabled}
            className={classNames(styles.selectionInput, styles[size], customSelectionCss)}
            required={required}
          />
          {!inputDisabled && (
            <div className={classNames(styles.fieldIcons, styles[size])}>
              {checkIfSelectedIdIsDefined() && !required && (
                <IconButton onClick={clear} customCssClass={styles.removeBtn}>
                  <Cross size="20" />
                </IconButton>
              )}
              <Arrow isOpen={isOpen} />
            </div>
          )}
        </label>
        {((prepopulate && isOpen) || (!prepopulate && value)) && (
          <ul className={classNames(styles.list, styles[size])} ref={refCallback}>
            <ListItems />
          </ul>
        )}
      </div>
    </OnClickOutside>
  );
};

function selectedItem<T extends SelectFieldOption>(
  options: readonly Readonly<T>[],
  settings: OptionalSelected<Required<CustomSettingType<T>>, "template">,
  selectedId?: T[keyof T],
) {
  return (options.find(option => option[settings.id] == selectedId)?.[settings.display] as any) ?? "";
}

const Arrow = ({ isOpen }: { isOpen: boolean }) => {
  return isOpen ? (
    <ArrowUp color={colors.primary} customCssClass={styles.arrow} />
  ) : (
    <ArrowDown customCssClass={styles.arrow} />
  );
};

Arrow.propTypes = {
  isOpen: PropTypes.bool.isRequired,
};

SelectField.propTypes = {
  options: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
  selectedId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  customSettings: PropTypes.shape({
    autocomplete: PropTypes.bool,
    template: PropTypes.func,
    display: PropTypes.string,
    id: PropTypes.string,
  }),
  name: PropTypes.string,
  customCssClass: PropTypes.string,
  customSelectionCss: PropTypes.string,
  required: PropTypes.bool,
  prepopulate: PropTypes.bool,
  size: PropTypes.oneOf(Object.values(SIZE)),
} as unknown;

export default SelectField;
export { SIZE };
