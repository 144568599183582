import PropTypes from "prop-types";
import { useHistory } from "@myloc/myloc-utils";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useLocation } from "react-router";
import { useAppSelector } from "../app/hooks";
import { RootState } from "../app/rootReducer";
import { PAGE_SIZE } from "../components/shared/Pagination/PageSize";
import { PaginationFilter, QUERY_PARAM } from "../features/utils/pagination";

const usePagination = <T extends PaginationFilter, R>({
  filter,
  request,
  loadOnMount = true,
  setData,
  countSelect,
  triggerData,
}: {
  filter: Readonly<T>;
  request?: (filter: T) => Promise<R[] | undefined>;
  loadOnMount?: boolean;
  setData: (ids: R[]) => void;
  countSelect: (state: RootState) => number;
  triggerData?: unknown;
}) => {
  const history = useHistory();
  const location = useLocation();
  const params = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const count = useAppSelector(countSelect);
  const [page, setPage] = useState(1);
  const mounted = useRef(loadOnMount);
  const [pagination, setPagination] = useState({
    from: parseInt(params.get(QUERY_PARAM.FROM) ?? "0", 10),
    amount: parseInt(params.get(QUERY_PARAM.AMOUNT) ?? PAGE_SIZE.SMALL.toString(), 10),
  });

  const shouldRequestAndCalculate = useRef(false);
  const triggerDataRef = useRef(triggerData);

  const handlePageChange = useCallback(
    (toPage: number) => {
      if (count > pagination.amount) {
        setPage(toPage);

        const amount = parseInt(params.get(QUERY_PARAM.AMOUNT) ?? pagination.amount.toString(), 10);
        const calculatedFrom = (toPage - 1) * amount;

        setPagination(pagination => {
          return pagination.amount !== amount || pagination.from !== calculatedFrom
            ? {
                ...pagination,
                from: calculatedFrom,
                amount,
              }
            : pagination;
        });
      }
    },
    [pagination.amount, count, params],
  );

  useEffect(() => {
    if (count > pagination.amount) {
      const amount = parseInt(params.get(QUERY_PARAM.AMOUNT) ?? pagination.amount.toString(), 10);

      if (
        params.get(QUERY_PARAM.FROM) !== pagination.from.toString() ||
        params.get(QUERY_PARAM.AMOUNT) !== pagination.amount.toString()
      ) {
        params.set(QUERY_PARAM.FROM, pagination.from.toString());
        params.set(QUERY_PARAM.AMOUNT, amount.toString());

        window.scrollTo({ top: 0 });

        history.push({ PATH: location.pathname, NAME: "", STATE: undefined }, params);
      }
    } else {
      if (params.get(QUERY_PARAM.FROM) !== null || params.get(QUERY_PARAM.AMOUNT) !== null) {
        params.delete(QUERY_PARAM.FROM);
        params.delete(QUERY_PARAM.AMOUNT);

        history.push({ PATH: location.pathname, NAME: "", STATE: undefined }, params);
      }
    }
  }, [count, history, location.pathname, pagination.amount, pagination.from, params]);

  const doRequestAndCalculatePagination = useCallback(async () => {
    if (request) {
      const ids = await request({ ...pagination, ...filter });

      if (ids) {
        setData(ids);
      }
    }
  }, [filter, request, setData, pagination]);

  useEffect(() => {
    if (
      mounted.current &&
      (triggerDataRef.current === triggerData || shouldRequestAndCalculate.current || page === 1)
    ) {
      shouldRequestAndCalculate.current = false;
      doRequestAndCalculatePagination();
    }
  }, [doRequestAndCalculatePagination, triggerData, page]);

  useEffect(() => {
    mounted.current = true;

    if (pagination.from !== 0 && triggerDataRef.current === triggerData) {
      handlePageChange(Math.ceil(pagination.from / pagination.amount) + 1);
    }
  }, [pagination.from, pagination.amount, handlePageChange, triggerData]);

  useEffect(() => {
    if (triggerDataRef.current !== triggerData) {
      triggerDataRef.current = triggerData;
      shouldRequestAndCalculate.current = true;
      handlePageChange(1);
    }
  }, [triggerData, handlePageChange]);

  const handlePageSizeChange = useCallback(
    (size: number) => {
      if (size !== pagination.amount) {
        params.set(QUERY_PARAM.FROM, "0");
        params.set(QUERY_PARAM.AMOUNT, size.toString());
        history.push({ PATH: location.pathname, NAME: "", STATE: undefined }, params);
        setPagination(pagination => ({ ...pagination, from: 0, amount: size }));
      }
    },
    [history, location.pathname, pagination.amount, params],
  );

  return {
    handlePageSizeChange,
    handlePageChange,
    settings: pagination,
    page,
  };
};

usePagination.propTypes = {
  filter: PropTypes.object.isRequired,
  request: PropTypes.func,
  loadOnMount: PropTypes.bool,
  setData: PropTypes.func.isRequired,
  countSelect: PropTypes.func.isRequired,
  triggerData: PropTypes.any,
};

export default usePagination;
