import { Method, Request, sendRequest } from "@myloc/myloc-utils";
import { AppDispatch } from "../../../app/store";
import { api } from "../../../config/settings";
import setQueryFilters from "../../utils/setQueryFilters";
import {
  AdditionalPaginationState,
  EntityActionReducerBuilderInterface,
  PaginationResponse,
  FulfilledUpdateMethod,
  REQUEST_STATE,
  FULFILLED_UPDATE_METHOD,
} from "../../dataTypes";
import { createAsyncThunk } from "../../utils/createAsyncThunk";
import defaultRestOptions from "../../utils/defaultRestOptions";
import { ResponseServiceType, ServiceType, ServiceTypesFilter, serviceTypeSliceName } from "../dataTypes";
import { serviceTypeAdapter } from "../serviceTypeAdapter";

async function getServiceTypes(dispatch: AppDispatch, filters?: ServiceTypesFilter) {
  const url = api.serviceType.serviceTypes();

  setQueryFilters(url, filters);

  const request = new Request(url, Method.GET);

  return await sendRequest(request, {}, await defaultRestOptions({ dispatch }));
}

export const fetchServiceTypes = createAsyncThunk<
  PaginationResponse<ResponseServiceType>,
  { filter?: ServiceTypesFilter | undefined; fulfilledUpdateMethod?: FulfilledUpdateMethod }
>(serviceTypeSliceName + "/fetchServiceTypes", (dispatch, { filter }) => getServiceTypes(dispatch, filter));

export const addFetchServiceTypesReducers = (
  builder: EntityActionReducerBuilderInterface<ServiceType, AdditionalPaginationState>,
) => {
  builder.addCase(fetchServiceTypes.pending, state => {
    state.requestState = REQUEST_STATE.PENDING;
    state.errorMessage = undefined;
  });
  builder.addCase(fetchServiceTypes.fulfilled, (state, action) => {
    const {
      payload,
      meta: {
        arg: { fulfilledUpdateMethod = FULFILLED_UPDATE_METHOD.UPSERT_MANY },
      },
    } = action;

    const mapResponse = (responseObject: ResponseServiceType): ServiceType => ({
      ...responseObject,
      fetchedTimestamp: Date.now(),
      hasFetchedDetail: false,
      requestState: REQUEST_STATE.FULFILLED,
      errorMessage: undefined,
    });

    switch (fulfilledUpdateMethod) {
      case FULFILLED_UPDATE_METHOD.SET_ALL:
        serviceTypeAdapter.setAll(state, payload.page.map(mapResponse));
        break;
      case FULFILLED_UPDATE_METHOD.UPSERT_MANY:
        serviceTypeAdapter.upsertMany(state, payload.page.map(mapResponse));
        break;
    }

    state.count = payload.count;
    state.requestState = REQUEST_STATE.FULFILLED;
  });
  builder.addCase(fetchServiceTypes.rejected, (state, action) => {
    state.requestState = REQUEST_STATE.REJECTED;
    state.errorMessage = action.payload?.message;
  });
};
