import { Method, Request, sendRequest } from "@myloc/myloc-utils";
import { AppDispatch } from "../../../app/store";
import { api } from "../../../config/settings";
import HttpStatusCodes from "../../../utils/HttpStatusCodes";
import { EntityActionReducerBuilderInterface, REQUEST_STATE } from "../../dataTypes";
import { createAsyncThunk } from "../../utils/createAsyncThunk";
import defaultRestOptions from "../../utils/defaultRestOptions";
import { ServiceType, serviceTypeSliceName, ResponseServiceType } from "../dataTypes";
import { serviceTypeAdapter } from "../serviceTypeAdapter";

async function getServiceType(dispatch: AppDispatch, id: string) {
  const url = api.serviceType.serviceType(id);
  const request = new Request(url, Method.GET);

  return await sendRequest(request, {}, await defaultRestOptions({ dispatch }));
}

export const fetchServiceType = createAsyncThunk<ResponseServiceType, string>(
  serviceTypeSliceName + "/fetchServiceType",
  (dispatch, id) => getServiceType(dispatch, id),
);

export const addFetchServiceTypeReducers = (builder: EntityActionReducerBuilderInterface<ServiceType>) => {
  builder.addCase(fetchServiceType.pending, (state, action) => {
    const id = action.meta.arg;
    const serviceType = state.entities[id] ?? ({ id } as ServiceType);

    serviceTypeAdapter.upsertOne(state, {
      ...serviceType,
      requestState: REQUEST_STATE.PENDING,
      errorMessage: undefined,
    });
  });
  builder.addCase(fetchServiceType.fulfilled, (state, action) => {
    const { payload, meta } = action;
    const id = meta.arg;

    serviceTypeAdapter.updateOne(state, {
      id,
      changes: {
        ...payload,
        fetchedTimestamp: Date.now(),
        hasFetchedDetail: true,
        requestState: REQUEST_STATE.FULFILLED,
      },
    });
  });
  builder.addCase(fetchServiceType.rejected, (state, action) => {
    const { payload, meta } = action;

    if (payload) {
      const id = meta.arg;

      if (payload.httpStatusCode === HttpStatusCodes.NOT_FOUND) serviceTypeAdapter.removeOne(state, id);
      else
        serviceTypeAdapter.updateOne(state, {
          id,
          changes: { requestState: REQUEST_STATE.REJECTED, errorMessage: payload.message },
        });
    }
  });
};
