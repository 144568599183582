import PropTypes from "prop-types";

import Page from "../shared/Page/Page";
import LoginWelcome from "./Welcome/LoginWelcome";
import styles from "./StartPage.module.scss";
import ContentWelcome from "./Welcome/ContentWelcome";
import { useAppSelector } from "../../app/hooks";
import { selectSessionId } from "../../features/session/sessionSelectors";
import { useTranslate } from "../../language/i18n";

const StartPage = ({ desiredPage }: { desiredPage?: string }) => {
  const translate = useTranslate();
  const loggedIn = !!useAppSelector(selectSessionId);

  const title = translate("WELCOME_TITLE");
  const text = translate("WELCOME_TEXT");

  return (
    <Page customCssClass={styles.page}>
      {loggedIn ? (
        <ContentWelcome />
      ) : (
        <LoginWelcome title={title} text={text} desiredPage={desiredPage} customCssClass={styles.welcomeSegment} />
      )}
    </Page>
  );
};

StartPage.propTypes = {
  desiredPage: PropTypes.string,
};

export default StartPage;
