import { Method, Request, sendRequest } from "@myloc/myloc-utils";
import { AppDispatch } from "../../../app/store";
import { api } from "../../../config/settings";
import HttpStatusCodes from "../../../utils/HttpStatusCodes";
import { EntityActionReducerBuilderInterface, REQUEST_STATE } from "../../dataTypes";
import { createAsyncThunk } from "../../utils/createAsyncThunk";
import defaultRestOptions from "../../utils/defaultRestOptions";
import { Form, formSliceName, ResponseForm } from "../dataTypes";
import { formAdapter } from "../formAdapter";

async function getForm(dispatch: AppDispatch, id: string) {
  const url = api.form.form(id);
  const request = new Request(url, Method.GET);

  return await sendRequest(request, {}, await defaultRestOptions({ dispatch }));
}

export const fetchForm = createAsyncThunk<ResponseForm, string>(formSliceName + "/fetchForm", (dispatch, id) =>
  getForm(dispatch, id),
);

export const addFetchFormReducers = (builder: EntityActionReducerBuilderInterface<Form>) => {
  builder.addCase(fetchForm.pending, (state, action) => {
    const id = action.meta.arg;
    const form = state.entities[id] ?? ({ id } as Form);

    formAdapter.upsertOne(state, { ...form, requestState: REQUEST_STATE.PENDING, errorMessage: undefined });
  });
  builder.addCase(fetchForm.fulfilled, (state, action) => {
    const { payload, meta } = action;
    const id = meta.arg;

    formAdapter.updateOne(state, {
      id,
      changes: { ...payload, fetchedTimestamp: Date.now(), requestState: REQUEST_STATE.FULFILLED },
    });
  });
  builder.addCase(fetchForm.rejected, (state, action) => {
    const { payload, meta } = action;

    if (payload) {
      const id = meta.arg;

      if (payload.httpStatusCode === HttpStatusCodes.NOT_FOUND) formAdapter.removeOne(state, id);
      else
        formAdapter.updateOne(state, {
          id,
          changes: { requestState: REQUEST_STATE.REJECTED, errorMessage: payload.message },
        });
    }
  });
};
