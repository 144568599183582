import { appDataName } from "../../app/dataTypes";
import { RootState } from "../../app/rootReducer";
import { WebOrderContentLine, webOrderContentLineSliceName } from "./dataTypes";
import { webOrderContentLineAdapter } from "./webOrderContentLineAdapter";

const selectSliceState = (state: RootState) => state[appDataName][webOrderContentLineSliceName];

const adapterSelectors = webOrderContentLineAdapter.getSelectors(selectSliceState);

export const selectErrorMessage = (state: RootState) => selectSliceState(state).errorMessage;

export const selectRequestState = (state: RootState) => selectSliceState(state).requestState;

export const selectWebOrderContentLineEntities = adapterSelectors.selectEntities;
export const selectWebOrderContentLines = adapterSelectors.selectAll;
export const selectWebOrderContentLine = (id = "") => (state: RootState) => adapterSelectors.selectById(state, id);
export const selectWebOrderContentLinesById = (ids: string[] = []) => (state: RootState) =>
  ids.reduce((lines, id) => {
    const line = adapterSelectors.selectById(state, id);

    return line ? [...lines, line] : lines;
  }, [] as WebOrderContentLine[]);
