import { Method, Request, sendRequest } from "@myloc/myloc-utils";
import { AppDispatch } from "../../../app/store";
import { api } from "../../../config/settings";
import HttpStatusCodes from "../../../utils/HttpStatusCodes";
import { EntityActionReducerBuilderInterface, REQUEST_STATE } from "../../dataTypes";
import { createAsyncThunk } from "../../utils/createAsyncThunk";
import defaultRestOptions from "../../utils/defaultRestOptions";
import { contactInformationAdapter } from "../contactInformationAdapter";
import { ContactInformation, contactInformationSliceName, ResponseContactInformation } from "../dataTypes";

async function getContactInformation(dispatch: AppDispatch, id: string) {
  const url = api.contactInformation.contactInformation(id);
  const request = new Request(url, Method.GET);

  return await sendRequest(request, {}, await defaultRestOptions({ dispatch }));
}

export const fetchContactInformation = createAsyncThunk<ResponseContactInformation, string>(
  contactInformationSliceName + "/fetchContactInformation",
  (disptach, id) => getContactInformation(disptach, id),
);

export const addFetchContactInformationReducers = (
  builder: EntityActionReducerBuilderInterface<ContactInformation>,
) => {
  builder.addCase(fetchContactInformation.pending, (state, action) => {
    const id = action.meta.arg;
    const contactInformation = state.entities[id] ?? ({ id } as ContactInformation);

    contactInformationAdapter.upsertOne(state, {
      ...contactInformation,
      requestState: REQUEST_STATE.PENDING,
      errorMessage: undefined,
    });
  });
  builder.addCase(fetchContactInformation.fulfilled, (state, action) => {
    const { payload, meta } = action;
    const id = meta.arg;

    contactInformationAdapter.updateOne(state, {
      id,
      changes: { ...payload, fetchedTimestamp: Date.now(), requestState: REQUEST_STATE.FULFILLED },
    });
  });
  builder.addCase(fetchContactInformation.rejected, (state, action) => {
    const { payload, meta } = action;

    if (payload) {
      const id = meta.arg;

      if (payload.httpStatusCode === HttpStatusCodes.NOT_FOUND) contactInformationAdapter.removeOne(state, id);
      else {
        contactInformationAdapter.updateOne(state, {
          id,
          changes: { requestState: REQUEST_STATE.REJECTED, errorMessage: payload.message },
        });
      }
    }
  });
};
