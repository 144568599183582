import { Method, Request, sendRequest } from "@myloc/myloc-utils";
import { AppDispatch } from "../../../app/store";
import { api } from "../../../config/settings";
import setQueryFilters from "../../utils/setQueryFilters";
import { EntityActionReducerBuilderInterface, PaginationResponse, REQUEST_STATE } from "../../dataTypes";
import { ResponseFile } from "../../file/dataTypes";
import { createAsyncThunk } from "../../utils/createAsyncThunk";
import defaultRestOptions from "../../utils/defaultRestOptions";
import { ServiceType, ServiceTypeFilesFilter, serviceTypeSliceName } from "../dataTypes";
import { serviceTypeAdapter } from "../serviceTypeAdapter";

async function getFiles(dispatch: AppDispatch, serviceTypeId: string, filters?: ServiceTypeFilesFilter) {
  const url = api.serviceType.files(serviceTypeId);

  setQueryFilters(url, filters);

  const request = new Request(url, Method.GET);

  return await sendRequest(request, {}, await defaultRestOptions({ dispatch }));
}

export const fetchFiles = createAsyncThunk<
  PaginationResponse<ResponseFile>,
  { serviceTypeId: string; filter?: ServiceTypeFilesFilter }
>(serviceTypeSliceName + "/fetchFiles", (dispatch, { serviceTypeId, filter }) =>
  getFiles(dispatch, serviceTypeId, filter),
);

export const addFetchFilesReducers = (builder: EntityActionReducerBuilderInterface<ServiceType>) => {
  builder.addCase(fetchFiles.pending, (state, action) => {
    const {
      meta: {
        arg: { serviceTypeId },
      },
    } = action;

    const serviceType = state.entities[serviceTypeId] ?? ({ id: serviceTypeId } as ServiceType);

    if (!serviceType.files) serviceType.files = { ids: [] };

    serviceType.files.errorMessage = undefined;
    serviceType.files.requestState = REQUEST_STATE.PENDING;
    serviceTypeAdapter.upsertOne(state, serviceType);
  });
  builder.addCase(fetchFiles.fulfilled, (state, action) => {
    const {
      meta: {
        arg: { serviceTypeId },
      },
      payload,
    } = action;

    const serviceType = state.entities[serviceTypeId];

    if (serviceType) {
      if (serviceType.files) {
        const ids = [...serviceType.files.ids];

        payload.page.forEach(file => {
          if (!ids.includes(file.id)) ids.push(file.id);
        });
        serviceType.files.ids = ids;

        serviceType.files.requestState = REQUEST_STATE.FULFILLED;
      }
    }
  });
  builder.addCase(fetchFiles.rejected, (state, action) => {
    const {
      meta: {
        arg: { serviceTypeId },
      },
      payload,
    } = action;

    const serviceType = state.entities[serviceTypeId];

    if (serviceType) {
      if (serviceType.files) {
        serviceType.files.requestState = REQUEST_STATE.REJECTED;
        serviceType.files.errorMessage = payload?.message;
      }
    }
  });
};
