import queryString from "query-string";
import { useCallback, useEffect, useState } from "react";

import storage from "./store/storage";
import { REMEMBER_ME, SESSION_ID } from "./store/storeVariables";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { selectIsInitialized, selectSingleSignOut } from "../features/session/sessionSelectors";
import useLogIn from "../features/session/hooks/useLogIn";
import useFetchSessionData from "../features/session/hooks/useFetchSessionData";
import { saveSessionId } from "../features/session/utils/saveSessionId";
import { setIsInitialized } from "../features/session/sessionSlice";
import { setRememberMe } from "../features/session/utils/setRememberMe";
import { setLoginToken } from "../features/session/utils/setLoginToken";
import useIsSingleSignOn from "../features/session/hooks/useIsSingleSignOn";

const useSingleSignOut = () => {
  const singleSignOut = useAppSelector(selectSingleSignOut);

  useEffect(() => {
    if (singleSignOut) {
      const domain = window.location.hostname;
      const redirectLocation =
        "/MyL/redirect_uri?logout=https%3A%2F%2F" + domain + encodeURIComponent("/MyL") + "%2Flogged-out.html";

      //@ts-ignore:next-line
      window.location = redirectLocation;
    }
  }, [singleSignOut]);
};

const useInitializeSingleSignOn = () => {
  const dispatch = useAppDispatch();
  const loadSessionData = useFetchSessionData();
  const isSingleSignOn = useIsSingleSignOn();
  const sessionInitialized = useAppSelector(selectIsInitialized);

  const initializeSingleSignOnSession = useCallback(async () => {
    setRememberMe(dispatch, false);

    //Doesn't need a session, is created on the fly from mod_auth_openidc in reversed proxy
    const payload = await loadSessionData();

    if (payload?.isError === false) saveSessionId(payload.sessionId);

    dispatch(setIsInitialized(true));
  }, [dispatch, loadSessionData]);

  useEffect(() => {
    if (isSingleSignOn === true && !sessionInitialized) initializeSingleSignOnSession();
  }, [initializeSingleSignOnSession, isSingleSignOn, sessionInitialized]);
};

const useInitializeStandardLogInSession = (useMylocLogin?: boolean) => {
  const dispatch = useAppDispatch();
  const loadSessionData = useFetchSessionData();
  const sessionInitialized = useAppSelector(selectIsInitialized);

  const initializeStandardLoginSession = useCallback(async () => {
    const rememberMe = await storage.loadItem(REMEMBER_ME);

    setRememberMe(dispatch, !!rememberMe);

    const sessionId = await storage.loadItem(SESSION_ID);

    if (sessionId) {
      const payload = await loadSessionData();

      if (payload) {
        if (!payload.isError) saveSessionId(payload.sessionId);
        else saveSessionId(undefined, rememberMe);
      }
    }

    dispatch(setIsInitialized(true));
  }, [dispatch, loadSessionData]);

  useEffect(() => {
    if (useMylocLogin === false && !sessionInitialized) initializeStandardLoginSession();
  }, [initializeStandardLoginSession, sessionInitialized, useMylocLogin]);
};

const useInitializeMylocLoginSession = (useMylocLogin?: boolean) => {
  const dispatch = useAppDispatch();
  const { loginWithMylocToken } = useLogIn();
  const loadSessionData = useFetchSessionData();
  const sessionInitialized = useAppSelector(selectIsInitialized);

  const doLoginWithToken = useCallback(
    async (mylocLoginToken: string) => {
      await loginWithMylocToken({ mylocLoginToken });
    },
    [loginWithMylocToken],
  );

  const mylocAuthentication = useCallback(
    async (
      mylocLoginToken?: ReturnType<typeof queryString["parse"]>[keyof ReturnType<typeof queryString["parse"]>],
    ) => {
      if (mylocLoginToken) {
        setLoginToken(dispatch, mylocLoginToken as string, false);
        await doLoginWithToken(mylocLoginToken as string);
      }
    },
    [dispatch, doLoginWithToken],
  );

  const initializeMylocLoginSession = useCallback(async () => {
    const query = queryString.parse(location.search);

    //If a virtualSessionId exists, use it instead of logging in again
    if (query.virtualSessionId) {
      saveSessionId(encodeURIComponent(query.virtualSessionId as string));
      await loadSessionData();
    } else await mylocAuthentication(query.mylocLoginToken);

    dispatch(setIsInitialized(true));
  }, [dispatch, loadSessionData, mylocAuthentication]);

  useEffect(() => {
    if (useMylocLogin === true && !sessionInitialized) initializeMylocLoginSession();
  }, [initializeMylocLoginSession, sessionInitialized, useMylocLogin]);
};

const useInitializeLogIn = () => {
  const [useMylocLogin, setUseMylocLogin] = useState<boolean>();
  const isSingleSignOn = useIsSingleSignOn();

  useEffect(() => {
    if (isSingleSignOn === false) setUseMylocLogin(isMylocLogin());
  }, [isSingleSignOn]);

  useInitializeStandardLogInSession(useMylocLogin);
  useInitializeMylocLoginSession(useMylocLogin);
};

const useInit = () => {
  useSingleSignOut();
  useInitializeSingleSignOn();
  useInitializeLogIn();
};

const isMylocLogin = () => {
  const mylocToken = queryString.parse(location.search).mylocLoginToken ?? undefined;

  return mylocToken !== undefined;
};

export default useInit;
