import PropTypes from "prop-types";
import classNames from "classnames";
import styles from "./Drawer.module.scss";
import { ReactNode, useEffect } from "react";

const Drawer = ({
  title,
  children,
  visible,
  customCssClass,
}: {
  title: string;
  children: ReactNode;
  visible: boolean;
  customCssClass?: string;
}) => {
  useEffect(() => {
    document.body.style.overflow = visible ? "hidden" : "";
  }, [visible]);

  useEffect(() => {
    return function cleanUp() {
      document.body.style.overflow = "";
    };
  }, []);

  return (
    <div className={classNames(styles.drawer, !visible && styles.closed, customCssClass)}>
      {title && (
        <section className={styles.title}>
          <h2>{title}</h2>
        </section>
      )}

      <section className={classNames(styles.content, title && styles.withTitle)}>{children}</section>
    </div>
  );
};

Drawer.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  visible: PropTypes.bool.isRequired,
  customCssClass: PropTypes.string,
};

export default Drawer;
