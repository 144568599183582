import { useEffect, useRef } from "react";
import useForceUpdate from "../../../hooks/useForceUpdate";
import { FulfilledUpdateMethod } from "../../dataTypes";
import { ServiceTypesFilter } from "../dataTypes";
import useLazyGetServiceTypes from "./useLazyGetServiceTypes";

const useGetServiceTypes = ({
  filter,
  fulfilledUpdateMethod,
}: {
  filter?: ServiceTypesFilter;
  fulfilledUpdateMethod?: FulfilledUpdateMethod;
}) => {
  const { isLoading: isLazyLoading, fetchServiceTypes, ...rest } = useLazyGetServiceTypes({
    filter,
    fulfilledUpdateMethod,
  });

  const forceUpdate = useForceUpdate();

  const initiationStatus = useRef<"initiating" | "initiated">();

  useEffect(() => {
    const initiateServiceTypes = async () => {
      if (!initiationStatus.current) {
        initiationStatus.current = "initiating";
        forceUpdate();
        await fetchServiceTypes();
        initiationStatus.current = "initiated";
      }
    };

    initiateServiceTypes();
  }, [fetchServiceTypes, forceUpdate]);

  const isLoading = !initiationStatus.current || isLazyLoading;

  return { ...rest, isLoading, refetchServiceTypes: fetchServiceTypes };
};

export default useGetServiceTypes;
