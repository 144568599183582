import { AdditionalRequestState, FetchedTimestamp } from "../dataTypes";

export const passwordPolicySliceName = "passwordPolicy";

export interface ResponsePasswordRule {
  label: string;
  regex: string;
  amount: number;
}

export interface ResponsePasswordPolicy {
  regexValidation: string;
  rules: ResponsePasswordRule[];
}

export interface PasswordPolicy extends ResponsePasswordPolicy, AdditionalRequestState, FetchedTimestamp {}

export interface PasswordPolicyState extends AdditionalRequestState {
  passwordPolicy?: PasswordPolicy;
}

export interface PasswordPolicyFilter {
  token?: string;
}
