import classNames from "classnames";
import PropTypes from "prop-types";
import { useTranslate } from "../../../../../language/i18n";
import { NavLink } from "react-router-dom";
import { Collapse } from "@myloc/myloc-gui";
import { MenuItem as MenuItemType } from "../../../../../utils/menu/dataTypes";
import { ChevronDown, ChevronUp } from "../../../../../assets/icons";

import styles from "../MenuDrawer.module.scss";
import SubMenu from "../SubMenu/SubMenu";

const MenuItem = ({
  menuItem,
  toggleMenu,
  isActive,
  subMenuVisible,
}: {
  menuItem: MenuItemType;
  toggleMenu: (menuItem?: MenuItemType) => void;
  isActive: boolean;
  subMenuVisible: boolean;
}) => {
  const translate = useTranslate();

  return (
    <li className={styles.menuItem}>
      {menuItem.subMenus && menuItem.subMenus.length > 0 ? (
        <>
          <button
            className={classNames(styles.menuButton, isActive && subMenuVisible && styles.active)}
            onClick={() => toggleMenu(menuItem)}
          >
            {translate(menuItem.label)}
            {isActive && subMenuVisible ? (
              <ChevronUp customCssClass={styles.icon} />
            ) : (
              <ChevronDown customCssClass={styles.icon} />
            )}
          </button>
          {isActive && (
            <Collapse visible={subMenuVisible} customCssClass={styles.submenuWrapper}>
              <div className={styles.subMenuContent}>
                {menuItem.subMenus.map(subMenu => (
                  <SubMenu key={subMenu.id} subMenu={subMenu} toggleMenu={toggleMenu} />
                ))}
              </div>
            </Collapse>
          )}
        </>
      ) : (
        <NavLink to={menuItem.to ?? "/"} className={styles.menuLink} onClick={() => toggleMenu(menuItem)}>
          {translate(menuItem.label)}
        </NavLink>
      )}
    </li>
  );
};

MenuItem.propTypes = {
  menuItem: PropTypes.object.isRequired,
  toggleMenu: PropTypes.func.isRequired,
  isActive: PropTypes.bool.isRequired,
  subMenuVisible: PropTypes.bool.isRequired,
};

export default MenuItem;
