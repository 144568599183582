import { useCallback, useEffect, useRef } from "react";
import { shallowEqual } from "react-redux";
import { useAppSelector } from "../../../app/hooks";
import { REQUEST_STATE } from "../../dataTypes";
import { selectHyperlinksById } from "../../hyperlink/hyperlinkSelectors";
import { PageContentHyperlinksFilter } from "../dataTypes";
import { selectPageContent } from "../pageContentSelectors";
import useFetchHyperlinks from "./useFetchHyperlinks";

const useLazyGetHyperlinks = ({
  pageContentId,
  filter,
  loadIfNotInitialized = false,
}: {
  pageContentId?: string;
  filter?: PageContentHyperlinksFilter;
  loadIfNotInitialized?: boolean;
}) => {
  const fetchHyperlinks = useFetchHyperlinks();
  const pageContent = useAppSelector(selectPageContent(pageContentId));
  const hasInitalized = useRef(false);

  const hyperlinksObj = pageContent?.hyperlinks;

  const requestState = hyperlinksObj?.requestState;
  const errorMessage = hyperlinksObj?.errorMessage;

  const hyperlinks = useAppSelector(selectHyperlinksById(hyperlinksObj?.ids), shallowEqual);

  const isUninitialized = requestState === undefined;
  const isLoading = requestState === REQUEST_STATE.PENDING;
  const isError = requestState === REQUEST_STATE.REJECTED;
  const isSuccess = requestState === REQUEST_STATE.FULFILLED;

  const loadHyperlinks = useCallback(async () => {
    await fetchHyperlinks({ pageContentId: pageContentId, filter });
  }, [pageContentId, fetchHyperlinks, filter]);

  useEffect(() => {
    hasInitalized.current = false;
  }, [pageContentId, filter]);

  useEffect(() => {
    if (loadIfNotInitialized && isUninitialized && !hasInitalized.current) {
      hasInitalized.current = true;
      loadHyperlinks();
    }
  }, [isUninitialized, loadIfNotInitialized, loadHyperlinks]);

  return {
    hyperlinks,
    isUninitialized,
    isLoading,
    isError,
    isSuccess,
    errorMessage,
    fetchHyperlinks: loadHyperlinks,
  };
};

export default useLazyGetHyperlinks;
