import PropTypes from "prop-types";
import { useRef, useEffect, useCallback, ReactNode } from "react";
import { createPortal } from "react-dom";
import classNames from "classnames";
import { Cross, LoadingSpinner } from "@myloc/myloc-gui";
import styles from "./Modal.module.scss";

const Modal = ({
  visible,
  title,
  children,
  onClose,
  small,
  customCssClass,
  showCloseButton,
}: {
  visible: boolean;
  title: string;
  children: ReactNode;
  onClose: () => void;
  small: boolean;
  customCssClass?: string;
  showCloseButton?: boolean;
}) => {
  const isCurrentlyVisible = useRef(visible);

  let modalRoot = document.getElementById("modal-root");

  if (!modalRoot) {
    modalRoot = document.createElement("div");
    modalRoot.setAttribute("id", "modal-root");
    document.body.appendChild(modalRoot);
  }

  const onCloseActions = () => {
    document.body.classList.remove(styles.disableScroll);

    onClose();
  };

  useEffect(() => {
    if (isCurrentlyVisible.current !== visible) {
      if (visible) document.body.classList.add(styles.disableScroll);
      else document.body.classList.remove(styles.disableScroll);
    }

    isCurrentlyVisible.current = visible;
  }, [visible]);

  useEffect(() => {
    return () => {
      document.body.classList.remove(styles.disableScroll);
    };
  }, []);

  return visible ? (
    createPortal(
      <ModalContent
        title={title}
        onClose={onCloseActions}
        small={small}
        customCssClass={customCssClass}
        showCloseButton={showCloseButton}
      >
        {children}
      </ModalContent>,
      modalRoot,
    )
  ) : (
    <></>
  );
};

const ModalContent = ({
  title,
  children,
  onClose,
  isLoading,
  small,
  footerContent,
  showCloseButton = true,
  customCssClass,
  customFooterClass,
}: {
  title: string;
  children?: ReactNode;
  onClose: () => void;
  isLoading?: boolean;
  small?: boolean;
  footerContent?: ReactNode;
  showCloseButton?: boolean;
  customCssClass?: string;
  customFooterClass?: string;
}) => {
  const elementRef = useRef<HTMLElement>(null);

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const escapeListener = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        handleClose();
      }
    },
    [handleClose],
  );

  const clickListener = useCallback(
    (e: MouseEvent) => {
      if (!elementRef.current?.contains(e.target as HTMLInputElement)) {
        handleClose();
      }
    },
    [handleClose],
  );

  useEffect(() => {
    document.addEventListener("mousedown", clickListener);

    document.addEventListener("keyup", escapeListener);

    return () => {
      document.removeEventListener("mousedown", clickListener);

      document.removeEventListener("keyup", escapeListener);
    };
  }, [clickListener, escapeListener]);

  return (
    <div className={classNames(styles.wrapper)}>
      <div className={classNames(styles.outerContainer, small && styles.small)}>
        <section ref={elementRef} className={classNames(styles.modal, customCssClass)}>
          {showCloseButton && (
            <button onClick={handleClose} className={styles.closeButton}>
              <Cross />
            </button>
          )}

          {title && <h1 className={styles.title}>{title}</h1>}
          {isLoading ? <LoadingSpinner /> : <div className={styles.content}>{children}</div>}

          {footerContent && <footer className={classNames(styles.footer, customFooterClass)}>{footerContent}</footer>}
        </section>
      </div>
    </div>
  );
};

Modal.propTypes = {
  visible: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,
  small: PropTypes.bool.isRequired,
  customCssClass: PropTypes.string,
  showCloseButton: PropTypes.bool,
};

ModalContent.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  small: PropTypes.bool,
  footerContent: PropTypes.node,
  customCssClass: PropTypes.string,
  customFooterClass: PropTypes.string,
  showCloseButton: PropTypes.bool,
};

export default Modal;
