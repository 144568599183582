import { FormData } from "@myloc/myg-forms";
import LoadingSpinner from "../../../../shared/Spinner/LoadingSpinner";
import Breadcrumbs from "../../../../shared/Breadcrumbs/Breadcrumbs";
import { useEffect } from "react";
import { useTranslate } from "../../../../../language/i18n";
import pages from "../../../../../utils/pages";
import styles from "./FormData.module.scss";
import useGetAggregatedForm from "../../../../../features/form/hooks/useGetAggregatedForm";

const Form = ({
  formId,
  setBreadcrumbs,
  referrer,
}: {
  formId?: string;
  setBreadcrumbs: (breadcrumbs: Parameters<typeof Breadcrumbs>["0"]["breadcrumbs"]) => void;
  referrer?: string;
}) => {
  const translate = useTranslate();
  const { form, isLoading, isFilesLoading } = useGetAggregatedForm({ id: formId, loadFiles: false });

  useEffect(() => {
    setBreadcrumbs([
      {
        text: `${translate(pages.MY_PAGE.NAME)} - ${translate(pages.MY_CASES.NAME)}`,
        link: referrer ?? pages.MY_CASES.PATH,
      },
      {
        text: `${translate("CASE")} - ${translate(pages.MY_CASES_FORM_DATA.NAME)}`,
      },
    ]);
  }, [referrer, setBreadcrumbs, translate]);

  if (isLoading || isFilesLoading) {
    return <LoadingSpinner />;
  }

  return (
    <section>
      <h3 className={styles.title}>{translate("SUB_NAV_MY_CASES_FORM_DATA")}</h3>
      {form ? <FormData form={form} /> : <p>{translate("NO_FORM_DATA")}</p>}
    </section>
  );
};

export default Form;
