import { useCallback, useEffect, useRef } from "react";
import { useAppSelector } from "../../../app/hooks";
import { REQUEST_STATE } from "../../dataTypes";
import { selectWebOrder } from "../webOrderSelectors";
import useFetchWebOrder from "./useFetchWebOrder";

const useLazyGetWebOrder = ({ id, loadIfNotInitialized = false }: { id?: string; loadIfNotInitialized?: boolean }) => {
  const fetchWebOrder = useFetchWebOrder();
  const webOrder = useAppSelector(selectWebOrder(id));
  const hasInitalized = useRef(false);

  const requestState = webOrder?.requestState;
  const errorMessage = webOrder?.errorMessage;

  const isUninitialized = requestState === undefined;
  const isLoading = requestState === REQUEST_STATE.PENDING;
  const isError = requestState === REQUEST_STATE.REJECTED;
  const isSuccess = requestState === REQUEST_STATE.FULFILLED;

  const loadWebOrder = useCallback(async () => {
    await fetchWebOrder(id);
  }, [fetchWebOrder, id]);

  useEffect(() => {
    hasInitalized.current = false;
  }, [id]);

  useEffect(() => {
    if (loadIfNotInitialized && isUninitialized && !hasInitalized.current) {
      hasInitalized.current = true;
      loadWebOrder();
    }
  }, [isUninitialized, loadWebOrder, loadIfNotInitialized]);

  return {
    webOrder,
    isUninitialized,
    isLoading,
    isError,
    isSuccess,
    errorMessage,
    fetchWebOrder: loadWebOrder,
  };
};

export default useLazyGetWebOrder;
