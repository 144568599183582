import { Method, Request, sendRequest } from "@myloc/myloc-utils";
import { AppDispatch } from "../../../app/store";
import { api } from "../../../config/settings";
import HttpStatusCodes from "../../../utils/HttpStatusCodes";
import { EntityActionReducerBuilderInterface, REQUEST_STATE } from "../../dataTypes";
import { createAsyncThunk } from "../../utils/createAsyncThunk";
import defaultRestOptions from "../../utils/defaultRestOptions";
import { FormDefinition, formDefinitionSliceName, ResponseFormDefinition } from "../dataTypes";
import { formDefinitionAdapter } from "../formDefinitionAdapter";

async function getFormDefinition(dispatch: AppDispatch, id: string) {
  const url = api.formDefinition.formDefinition(id);
  const request = new Request(url, Method.GET);

  return await sendRequest(request, {}, await defaultRestOptions({ dispatch }));
}

export const fetchFormDefinition = createAsyncThunk<ResponseFormDefinition, string>(
  formDefinitionSliceName + "/fetchFormDefinition",
  (dispatch, id) => getFormDefinition(dispatch, id),
);

export const addFetchFormDefinitionReducers = (builder: EntityActionReducerBuilderInterface<FormDefinition>) => {
  builder.addCase(fetchFormDefinition.pending, (state, action) => {
    const id = action.meta.arg;
    const formDefinition = state.entities[id] ?? ({ id } as FormDefinition);

    formDefinitionAdapter.upsertOne(state, {
      ...formDefinition,
      requestState: REQUEST_STATE.PENDING,
      errorMessage: undefined,
    });
  });
  builder.addCase(fetchFormDefinition.fulfilled, (state, action) => {
    const { payload, meta } = action;
    const id = meta.arg;

    formDefinitionAdapter.updateOne(state, {
      id,
      changes: { ...payload, fetchedTimestamp: Date.now(), requestState: REQUEST_STATE.FULFILLED },
    });
  });
  builder.addCase(fetchFormDefinition.rejected, (state, action) => {
    const { payload, meta } = action;

    if (payload) {
      const id = meta.arg;

      if (payload.httpStatusCode === HttpStatusCodes.NOT_FOUND) formDefinitionAdapter.removeOne(state, id);
      else
        formDefinitionAdapter.updateOne(state, {
          id,
          changes: { requestState: REQUEST_STATE.REJECTED, errorMessage: payload.message },
        });
    }
  });
};
