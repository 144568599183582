import PropTypes from "prop-types";
import classNames from "classnames";
import styles from "./Welcome.module.scss";
import Image from "../../shared/Image/Image";
import logo from "../../../assets/logos/logo-riksbanken.png";
import { ReactNode } from "react";

const Welcome = ({
  title,
  text,
  children,
  customCssClass,
}: {
  title: string;
  text?: string;
  children?: ReactNode;
  customCssClass?: string;
}) => {
  return (
    <section className={classNames(styles.container, customCssClass)}>
      <article className={classNames(styles.contentContainer, styles.textContainer)}>
        <h2 className={styles.sectionTitle}>{title}</h2>
        <p className={styles.text}>{text}</p>
      </article>
      <div className={styles.image}>
        <Image src={logo} alt="Myloc - Logo" customCssClass={styles.imageContainer} />
      </div>
      <article className={classNames(styles.contentContainer, styles.infoContainer)}>{children}</article>
    </section>
  );
};

Welcome.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
  children: PropTypes.node,
  customCssClass: PropTypes.string,
};

export default Welcome;
