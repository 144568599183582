import { Dictionary } from "@reduxjs/toolkit";
import cloneDeep from "lodash.clonedeep";
import { useCallback, useEffect } from "react";
import isEqual from "react-fast-compare";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { RequestState, REQUEST_STATE, UseFetchRef, UseFetchRefs } from "../../dataTypes";
import { setError } from "../../dialog/dialogSlice";
import mergeFetchedTimestampRefAndStateEntities from "../../utils/mergeFetchedTimestampRefAndStateEntities";
import { useValidateUserIsLoggedIn } from "../../utils/useValidateUserIsLoggedIn";
import { ServiceType, ServiceTypeFilesFilter } from "../dataTypes";
import { fetchFiles } from "../reducers/fetchFiles";
import { selectServiceTypeEntities } from "../serviceTypeSelectors";

interface Refs extends UseFetchRefs {
  [id: string]: (UseFetchRef & { files: { requestState?: RequestState } }) | undefined;
}

let refs: Refs;
const filterRef: { [serviceTypeId: string]: ServiceTypeFilesFilter | undefined } = {};

const mapServiceTypes = (serviceTypes: Dictionary<ServiceType>) => {
  return Object.entries(serviceTypes).reduce((result, [id, serviceType]) => {
    result[id] = serviceType
      ? {
          files: { requestState: serviceType.files?.requestState },
          fetchedTimestamp: serviceType.fetchedTimestamp,
          requestState: serviceType.requestState,
        }
      : undefined;

    return result;
  }, {} as Refs);
};

const useFetchFiles = () => {
  const dispatch = useAppDispatch();
  const result = useValidateUserIsLoggedIn();

  const serviceTypes = useAppSelector(selectServiceTypeEntities);

  if ((refs as UseFetchRefs | undefined) === undefined) refs = mapServiceTypes(serviceTypes);

  useEffect(() => {
    refs = mergeFetchedTimestampRefAndStateEntities(refs, mapServiceTypes(serviceTypes));
  }, [serviceTypes]);

  const loadFiles = useCallback(
    async ({ serviceTypeId, filter }: { serviceTypeId?: string; filter?: ServiceTypeFilesFilter }) => {
      if (result.isError) {
        dispatch(setError({ value: result.errorMessage }));
      } else {
        if (serviceTypeId) {
          const ref = refs[serviceTypeId];

          if (!isEqual(filter, filterRef[serviceTypeId]) || ref?.files.requestState !== REQUEST_STATE.PENDING) {
            refs[serviceTypeId] = {
              files: { requestState: REQUEST_STATE.PENDING },
              fetchedTimestamp: Date.now(),
              requestState: REQUEST_STATE.PENDING,
            };
            filterRef[serviceTypeId] = cloneDeep(filter);
            await dispatch(fetchFiles({ serviceTypeId, filter }));
          }
        }
      }
    },
    [dispatch, result],
  );

  return loadFiles;
};

export default useFetchFiles;
