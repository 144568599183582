import { createSlice } from "@reduxjs/toolkit";
import { AdditionalRequestState } from "../dataTypes";
import { formSliceName } from "./dataTypes";
import { formAdapter } from "./formAdapter";
import { addFetchFormReducers } from "./reducers/fetchForm";

const slice = createSlice({
  name: formSliceName,
  initialState: formAdapter.getInitialState<AdditionalRequestState>({}),
  reducers: {},
  extraReducers: builder => {
    addFetchFormReducers(builder);
  },
});

const { reducer } = slice;

export default reducer;
