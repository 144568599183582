import { AdditionalRequestState, FetchedIdObject, IdObject } from "../dataTypes";

export const webOrderContentLineSliceName = "webOrderContentLine";

export interface ResponseWebOrderContentLine extends IdObject {
  webOrderContentId: number;
  line: number;
  lineSub: number;
  serviceTypeId?: string;
  quantity: number;
}

export interface WebOrderContentLine extends ResponseWebOrderContentLine, AdditionalRequestState, FetchedIdObject {}

export interface CreateWebOrderContentLineRequest {
  serviceTypeId?: string;
}
