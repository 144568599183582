import { Method, Request, sendRequest } from "@myloc/myloc-utils";
import { AppDispatch } from "../../../app/store";
import { api } from "../../../config/settings";
import setQueryFilters from "../../utils/setQueryFilters";
import { EntityActionReducerBuilderInterface, PaginationResponse, REQUEST_STATE } from "../../dataTypes";
import { ResponseHyperlink } from "../../hyperlink/dataTypes";
import { createAsyncThunk } from "../../utils/createAsyncThunk";
import defaultRestOptions from "../../utils/defaultRestOptions";
import { PageContent, PageContentHyperlinksFilter, pageContentSliceName } from "../dataTypes";
import { pageContentAdapter } from "../pageContentAdapter";

async function getHyperlinks(dispatch: AppDispatch, pageContentId: string, filters?: PageContentHyperlinksFilter) {
  const url = api.pageContent.hyperlinks(pageContentId);

  setQueryFilters(url, filters);

  const request = new Request(url, Method.GET);

  return await sendRequest(request, {}, await defaultRestOptions({ dispatch }));
}

export const fetchHyperlinks = createAsyncThunk<
  PaginationResponse<ResponseHyperlink>,
  { pageContentId: string; filter?: PageContentHyperlinksFilter }
>(pageContentSliceName + "/fetchHyperlinks", (dispatch, { pageContentId, filter }) =>
  getHyperlinks(dispatch, pageContentId, filter),
);

export const addFetchHyperlinksReducers = (builder: EntityActionReducerBuilderInterface<PageContent>) => {
  builder.addCase(fetchHyperlinks.pending, (state, action) => {
    const {
      meta: {
        arg: { pageContentId },
      },
    } = action;

    const pageContent = state.entities[pageContentId] ?? ({ id: pageContentId } as PageContent);

    if (!pageContent.hyperlinks) pageContent.hyperlinks = { ids: [] };

    pageContent.hyperlinks.errorMessage = undefined;
    pageContent.hyperlinks.requestState = REQUEST_STATE.PENDING;
    pageContentAdapter.upsertOne(state, pageContent);
  });
  builder.addCase(fetchHyperlinks.fulfilled, (state, action) => {
    const {
      meta: {
        arg: { pageContentId },
      },
      payload,
    } = action;

    const pageContent = state.entities[pageContentId];

    if (pageContent) {
      if (pageContent.hyperlinks) {
        const ids = [...pageContent.hyperlinks.ids];

        payload.page.forEach(hyperlink => {
          if (!ids.includes(hyperlink.id)) ids.push(hyperlink.id);
        });
        pageContent.hyperlinks.ids = ids;

        pageContent.hyperlinks.requestState = REQUEST_STATE.FULFILLED;
      }
    }
  });
  builder.addCase(fetchHyperlinks.rejected, (state, action) => {
    const {
      meta: {
        arg: { pageContentId },
      },
      payload,
    } = action;

    const pageContent = state.entities[pageContentId];

    if (pageContent) {
      if (pageContent.hyperlinks) {
        pageContent.hyperlinks.requestState = REQUEST_STATE.REJECTED;
        pageContent.hyperlinks.errorMessage = payload?.message;
      }
    }
  });
};
