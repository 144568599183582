import { createSlice } from "@reduxjs/toolkit";
import { AdditionalPaginationState, AdditionalRequestState } from "../dataTypes";
import { serviceTypeCategorySliceName } from "./dataTypes";
import { serviceTypeCategoryAdapter } from "./serviceTypeCategoryAdapter";
import { addFetchServiceTypeCategoriesReducers } from "./reducers/fetchServiceTypeCategories";
import { addFetchServiceTypeCategoryReducers } from "./reducers/fetchServiceTypeCategory";

const slice = createSlice({
  name: serviceTypeCategorySliceName,
  initialState: serviceTypeCategoryAdapter.getInitialState<AdditionalRequestState & AdditionalPaginationState>({
    count: 0,
  }),
  reducers: {},
  extraReducers: builder => {
    addFetchServiceTypeCategoriesReducers(builder);
    addFetchServiceTypeCategoryReducers(builder);
  },
});

const { reducer } = slice;

export default reducer;
