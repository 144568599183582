import pages from "../../utils/pages";
import Page from "../shared/Page/Page";
import { useHistory } from "@myloc/myloc-utils";
import { useTranslate } from "../../language/i18n";
import ServiceList from "./ServiceList/ServiceList";
import { ServiceTypesFilter } from "../../features/serviceType/dataTypes";
import { useCallback, useState, useEffect, useMemo } from "react";
import useDebounce from "../../hooks/useDebounce";
import { SERVICE_TYPE_CATEGORIES } from "../../utils/constants";
import { useLocation } from "react-router";
import { getSortFilter } from "../../features/utils/pagination";
import { ORDER_BY_OPERATOR } from "../../features/utils/SqlOperators";

const FILTERS: readonly (keyof ServiceTypesFilter)[] = ["freeTextSearch"] as const;

function ServicesPage() {
  const history = useHistory();
  const translate = useTranslate();
  const location = useLocation();
  const params = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const errorReportOnly = Boolean(location.pathname.match(/^\/report-error/));

  const getChoicesFromParams = useCallback(() => {
    const filter = FILTERS.reduce(
      (choice, key) => ({
        ...choice,
        [key]: params.get(key) ?? "",
      }),
      {} as ServiceTypesFilter,
    );

    filter.serviceTypeCategory = errorReportOnly ? SERVICE_TYPE_CATEGORIES.ERROR : SERVICE_TYPE_CATEGORIES.ORDER;

    filter.sort = [getSortFilter({ id: "name", order: ORDER_BY_OPERATOR.ASC })];

    return filter;
  }, [params, errorReportOnly]);

  const [filters, setFilters] = useState(getChoicesFromParams);

  const [searchValue, setSearchValue] = useState(params.get("freeTextSearch") ?? "");

  const debouncedSearchValue = useDebounce(searchValue);

  const setSearchValueAndParams = useCallback(
    (value: string) => {
      if (value) {
        params.set("freeTextSearch", value);
      } else {
        params.delete("freeTextSearch");
      }

      setSearchValue(value);

      history.replace({ PATH: location.pathname, NAME: "", STATE: undefined }, params);
    },
    [history, params, location.pathname],
  );

  useEffect(() => {
    setFilters(currFilters =>
      currFilters.freeTextSearch !== debouncedSearchValue
        ? { ...currFilters, freeTextSearch: debouncedSearchValue }
        : currFilters,
    );
  }, [debouncedSearchValue]);

  useEffect(() => {
    if (
      (errorReportOnly && filters.serviceTypeCategory !== SERVICE_TYPE_CATEGORIES.ERROR) ||
      (!errorReportOnly && filters.serviceTypeCategory !== SERVICE_TYPE_CATEGORIES.ORDER)
    ) {
      setSearchValueAndParams("");

      setFilters(currFilters => ({
        ...currFilters,
        serviceTypeCategory: errorReportOnly ? SERVICE_TYPE_CATEGORIES.ERROR : SERVICE_TYPE_CATEGORIES.ORDER,
      }));
    }
  }, [errorReportOnly, setSearchValueAndParams, filters.serviceTypeCategory]);

  return (
    <Page title={errorReportOnly ? translate(pages.REPORT_ERROR.NAME) : translate(pages.SERVICES.NAME)}>
      <ServiceList filters={filters} searchValue={searchValue} setSearchValue={setSearchValueAndParams} />
    </Page>
  );
}

export default ServicesPage;
