import { useEffect, useState } from "react";
import classNames from "classnames";
import { withPage } from "@myloc/myloc-utils";
import { Button, THEME } from "@myloc/myloc-gui";
import { useTranslate } from "../../language/i18n";
import pages from "../../utils/pages";
import Page from "../shared/Page/Page";
import styles from "./MyPage.module.scss";
import { useDispatch } from "react-redux";
import Profile from "./Sections/Profile";
import Cases from "./Sections/Cases";
import { Route, Switch } from "react-router";
import { NavLink } from "react-router-dom";
import useLogOut from "../../features/session/hooks/useLogOut";
import { setError } from "../../features/dialog/dialogSlice";
import Breadcrumbs from "../shared/Breadcrumbs/Breadcrumbs";
import useLazyGetCurrentUser from "../../features/session/hooks/useLazyGetCurrentUser";

function MyPage() {
  const translate = useTranslate();
  const dispatch = useDispatch();
  const { logOut, isError, isLoading: isSigningOut } = useLogOut();
  const [breadcrumbs, setBreadcrumbs] = useState<Parameters<typeof Breadcrumbs>[0]["breadcrumbs"]>([
    { text: `${translate(pages.MY_PAGE.NAME)} - ${translate(pages.MY_CASES.NAME)}` },
  ]);

  const { user } = useLazyGetCurrentUser({ loadIfNotInitialized: true });

  useEffect(() => {
    if (isError) dispatch(setError({ value: translate("UNABLE_TO_LOG_OUT") }));
  }, [isError, translate, dispatch]);

  const clientName = `${user?.firstName} ${user?.surname}`;

  const links = [pages.MY_CASES, pages.MY_PROFILE];

  return (
    <Page
      title={translate(pages.MY_PAGE.NAME)}
      description={clientName}
      customCssClass={styles.page}
      breadcrumbs={breadcrumbs}
    >
      <div className={styles.pageContent}>
        <nav className={styles.navigation}>
          <ul>
            {links.map(link => (
              <li key={link.PATH} className={classNames(styles.navButton)}>
                <NavLink activeClassName={styles.active} to={link.PATH}>
                  {translate(link.NAME)}
                </NavLink>
              </li>
            ))}
          </ul>

          <div className={styles.logout}>
            <Button theme={THEME.PLAIN} customCssClass={styles.navButton} isLoading={isSigningOut} onClick={logOut}>
              {translate("LOGOUT")}
            </Button>
          </div>
        </nav>

        <Switch>
          <Route exact path={pages.MY_CASES.PATH}>
            <Cases setBreadcrumbs={setBreadcrumbs} />
          </Route>

          <Route exact path={pages.MY_PROFILE.PATH}>
            <Profile setBreadcrumbs={setBreadcrumbs} />
          </Route>
        </Switch>
      </div>
    </Page>
  );
}

export default withPage(MyPage);
