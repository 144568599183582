import { useCallback, useEffect } from "react";
import { useAppSelector } from "../../../app/hooks";
import { REQUEST_STATE } from "../../dataTypes";
import { selectNextUserAccessMessage } from "../../session/sessionSelectors";
import { selectUserAccessMessage } from "../../userAccessMessage/userAccessMessageSelectors";
import useFetchNextUserAccessMessage from "./useFetchNextUserAccessMessage";

const useLazyGetNextUserAccessMessage = ({ loadIfNotInitialized }: { loadIfNotInitialized?: boolean }) => {
  const fetchUserAccessMessage = useFetchNextUserAccessMessage();

  const nextUserAccessMessage = useAppSelector(selectNextUserAccessMessage);
  const requestState = nextUserAccessMessage?.requestState;
  const errorMessage = nextUserAccessMessage?.errorMessage;

  const userAccessMessage = useAppSelector(selectUserAccessMessage(nextUserAccessMessage?.id));

  const isUninitialized = requestState === undefined;
  const isLoading = requestState === REQUEST_STATE.PENDING;
  const isSuccess = requestState === REQUEST_STATE.FULFILLED;
  const isError = requestState === REQUEST_STATE.REJECTED;

  const loadUserAccessMessage = useCallback(async () => {
    await fetchUserAccessMessage();
  }, [fetchUserAccessMessage]);

  useEffect(() => {
    if (loadIfNotInitialized && isUninitialized) loadUserAccessMessage();
  }, [isUninitialized, loadIfNotInitialized, loadUserAccessMessage]);

  return {
    userAccessMessage,
    isUninitialized,
    isLoading,
    isSuccess,
    isError,
    errorMessage,
    fetchUserAccessMessage: loadUserAccessMessage,
  };
};

export default useLazyGetNextUserAccessMessage;
