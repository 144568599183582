import { combineReducers } from "@reduxjs/toolkit";
import contactInformationSlice from "../features/contactInformation/contactInformationSlice";
import { contactInformationSliceName } from "../features/contactInformation/dataTypes";
import { fileSliceName } from "../features/file/dataTypes";
import fileSlice from "../features/file/fileSlice";
import { formSliceName } from "../features/form/dataTypes";
import formSlice from "../features/form/formSlice";
import { formDefinitionSliceName } from "../features/formDefinition/dataTypes";
import formDefinitionSlice from "../features/formDefinition/formDefinitionSlice";
import { hyperlinkSliceName } from "../features/hyperlink/dataTypes";
import hyperlinkSlice from "../features/hyperlink/hyperlinkSlice";
import { pageContentSliceName } from "../features/pageContent/dataTypes";
import pageContentSlice from "../features/pageContent/pageContentSlice";
import { passwordPolicySliceName } from "../features/passwordPolicy/dataTypes";
import passwordPolicySlice from "../features/passwordPolicy/passwordPolicySlice";
import { serviceTypeSliceName } from "../features/serviceType/dataTypes";
import serviceTypeSlice from "../features/serviceType/serviceTypeSlice";
import { serviceTypeCategorySliceName } from "../features/serviceTypeCategory/dataTypes";
import serviceTypeCategorySlice from "../features/serviceTypeCategory/serviceTypeCategorySlice";
import { userSliceName } from "../features/user/dataTypes";
import userSlice from "../features/user/userSlice";
import { userAccessMessageSliceName } from "../features/userAccessMessage/dataTypes";
import userAccessMessageSlice from "../features/userAccessMessage/userAccessMessageSlice";
import { webOrderSliceName } from "../features/webOrder/dataTypes";
import webOrderSlice from "../features/webOrder/webOrderSlice";
import { webOrderContentSliceName } from "../features/webOrderContent/dataTypes";
import webOrderContentSlice from "../features/webOrderContent/webOrderContentSlice";
import { webOrderContentLineSliceName } from "../features/webOrderContentLine/dataTypes";
import webOrderContentLineSlice from "../features/webOrderContentLine/webOrderContentLineSlice";

const appDataReducer = combineReducers({
  [contactInformationSliceName]: contactInformationSlice,
  [fileSliceName]: fileSlice,
  [formSliceName]: formSlice,
  [formDefinitionSliceName]: formDefinitionSlice,
  [hyperlinkSliceName]: hyperlinkSlice,
  [pageContentSliceName]: pageContentSlice,
  [passwordPolicySliceName]: passwordPolicySlice,
  [serviceTypeCategorySliceName]: serviceTypeCategorySlice,
  [serviceTypeSliceName]: serviceTypeSlice,
  [userAccessMessageSliceName]: userAccessMessageSlice,
  [userSliceName]: userSlice,
  [webOrderSliceName]: webOrderSlice,
  [webOrderContentSliceName]: webOrderContentSlice,
  [webOrderContentLineSliceName]: webOrderContentLineSlice,
});

export default appDataReducer;
