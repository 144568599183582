import useUpdateUserAccessMessage from "../../userAccessMessage/hooks/useUpdateUserAccessMessage";
import useLazyGetNextUserAccessMessage from "./useLazyGetNextUserAccessMessage";

const useUpdateNextUserAccessMessage = () => {
  const {
    userAccessMessage,
    isUninitialized,
    isLoading,
    isSuccess,
    isError,
    errorMessage,
  } = useLazyGetNextUserAccessMessage({ loadIfNotInitialized: true });

  const { updateUserAccessMessage } = useUpdateUserAccessMessage(userAccessMessage?.id);

  return {
    userAccessMessage,
    isUninitialized,
    isLoading,
    isSuccess,
    isError,
    errorMessage,
    updateUserAccessMessage,
  };
};

export default useUpdateNextUserAccessMessage;
