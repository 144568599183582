import { EntityActionReducerBuilderInterface, REQUEST_STATE } from "../../dataTypes";
import { fetchLines } from "../../webOrderContent/reducers/fetchLines";
import { WebOrderContentLine } from "../dataTypes";
import { webOrderContentLineAdapter } from "../webOrderContentLineAdapter";

export const addFetchLinesForWebOrderContent = (builder: EntityActionReducerBuilderInterface<WebOrderContentLine>) => {
  builder.addCase(fetchLines.fulfilled, (state, action) => {
    const { payload } = action;

    webOrderContentLineAdapter.upsertMany(
      state,
      payload.page.map(responseObject => ({
        ...responseObject,
        fetchedTimestamp: Date.now(),
        requestStatus: REQUEST_STATE.FULFILLED,
        errorMessage: undefined,
      })),
    );
  });
};
