import { Method, Request, sendRequest } from "@myloc/myloc-utils";
import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { AppDispatch } from "../../../app/store";
import { api } from "../../../config/settings";
import setQueryFilters from "../../utils/setQueryFilters";
import { REQUEST_STATE } from "../../dataTypes";
import { createAsyncThunk } from "../../utils/createAsyncThunk";
import defaultRestOptions from "../../utils/defaultRestOptions";
import {
  PasswordPolicyFilter,
  passwordPolicySliceName,
  PasswordPolicyState,
  ResponsePasswordPolicy,
} from "../dataTypes";

async function getPasswordPolicy(dispatch: AppDispatch, filters?: PasswordPolicyFilter) {
  const url = api.password.passwordRules();

  setQueryFilters(url, filters);

  const request = new Request(url, Method.GET);

  return await sendRequest(request, {}, await defaultRestOptions({ dispatch }));
}

export const fetchPasswordPolicy = createAsyncThunk<ResponsePasswordPolicy, PasswordPolicyFilter | undefined>(
  passwordPolicySliceName + "/fetchPasswordPolicy",
  (dispatch, filters) => getPasswordPolicy(dispatch, filters),
);

export const addFetchPasswordPolicyReducers = (builder: ActionReducerMapBuilder<PasswordPolicyState>) => {
  builder.addCase(fetchPasswordPolicy.pending, state => {
    state.requestState = REQUEST_STATE.PENDING;
    state.errorMessage = undefined;
  });
  builder.addCase(fetchPasswordPolicy.fulfilled, (state, action) => {
    const { payload } = action;

    state.passwordPolicy = { ...payload, fetchedTimestamp: Date.now(), requestState: REQUEST_STATE.FULFILLED };
    state.requestState = REQUEST_STATE.FULFILLED;
  });
  builder.addCase(fetchPasswordPolicy.rejected, (state, action) => {
    state.requestState = REQUEST_STATE.REJECTED;
    state.errorMessage = action.payload?.message;
  });
};
