import PropType from "prop-types";
import classNames from "classnames";
import { RadioButton } from "@myloc/myloc-gui";
import styles from "./RadioButtonGroup.module.scss";

export interface RadioButton {
  id: string;
  value: string;
}

const RadioButtonGroup = ({
  label,
  name,
  selectedId,
  values,
  displayValue,
  onChange,
  disabled,
  customCssClass,
}: {
  label?: string;
  name?: string;
  selectedId?: string;
  values: RadioButton[];
  displayValue?: string;
  onChange: (selected: RadioButton) => void;
  disabled?: boolean;
  customCssClass?: string;
}) => {
  return (
    <div>
      {label && <p id={`${name}-title`}>{label}</p>}
      <div className={classNames(styles.container, customCssClass)} aria-labelledby={`${name}-title`} role="radiogroup">
        {values.map(value => (
          <RadioButton
            key={value.id}
            name="select-receiver"
            id={value.id}
            label={displayValue ?? value.value}
            checked={value.id === selectedId}
            onChange={() => onChange(value)}
            disabled={disabled}
          />
        ))}
      </div>
    </div>
  );
};

RadioButtonGroup.propTypes = {
  label: PropType.string,
  name: PropType.string,
  selectedId: PropType.string,
  values: PropType.arrayOf(
    PropType.shape({
      id: PropType.string.isRequired,
      value: PropType.string.isRequired,
    }),
  ).isRequired,
  displayValue: PropType.string,
  onChange: PropType.func.isRequired,
  disabled: PropType.bool,
  customCssClass: PropType.string,
};

export default RadioButtonGroup;
