import { useCallback, useEffect } from "react";
import { useAppSelector } from "../../../app/hooks";
import { REQUEST_STATE } from "../../dataTypes";
import { selectCurrentUser } from "../../session/sessionSelectors";
import { selectUser } from "../../user/userSelectors";
import useFetchCurrentUser from "./useFetchCurrentUser";

const useLazyGetCurrentUser = ({ loadIfNotInitialized }: { loadIfNotInitialized?: boolean }) => {
  const fetchUser = useFetchCurrentUser();

  const currentUser = useAppSelector(selectCurrentUser);
  const requestState = currentUser?.requestState;
  const errorMessage = currentUser?.errorMessage;

  const user = useAppSelector(selectUser(currentUser?.id));

  const isUninitialized = requestState === undefined;
  const isLoading = requestState === REQUEST_STATE.PENDING;
  const isSuccess = requestState === REQUEST_STATE.FULFILLED;
  const isError = requestState === REQUEST_STATE.REJECTED;

  const loadUser = useCallback(async () => {
    await fetchUser();
  }, [fetchUser]);

  useEffect(() => {
    if (loadIfNotInitialized && isUninitialized) loadUser();
  }, [isUninitialized, loadIfNotInitialized, loadUser]);

  return {
    user,
    isUninitialized,
    isLoading,
    isSuccess,
    isError,
    errorMessage,
    fetchUser: loadUser,
  };
};

export default useLazyGetCurrentUser;
