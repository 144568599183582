import { useCallback, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { FETCH_MASTER_DATA_TIME_OUT, REQUEST_STATE, UseFetchRef } from "../../dataTypes";
import { setError } from "../../dialog/dialogSlice";
import { useValidateUserIsLoggedIn } from "../../utils/useValidateUserIsLoggedIn";
import { fetchCurrentUser } from "../reducers/fetchCurrentUser";
import { selectCurrentUser } from "../sessionSelectors";

let ref: UseFetchRef;

const useFetchCurrentUser = () => {
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(selectCurrentUser);
  const result = useValidateUserIsLoggedIn();

  if ((ref as UseFetchRef | undefined) === undefined)
    ref = { fetchedTimestamp: currentUser?.fetchedTimestamp ?? 0, requestState: currentUser?.requestState };

  useEffect(() => {
    if (!currentUser) ref = { fetchedTimestamp: 0, requestState: undefined };
    else
      ref = {
        fetchedTimestamp: currentUser.fetchedTimestamp,
        requestState: currentUser.requestState ?? ref.requestState,
      };
  }, [currentUser]);

  const loadUser = useCallback(async () => {
    if (result.isError) {
      dispatch(setError({ value: result.errorMessage }));
    } else {
      if (
        ref.requestState !== REQUEST_STATE.PENDING &&
        ref.fetchedTimestamp + FETCH_MASTER_DATA_TIME_OUT < Date.now()
      ) {
        ref = { fetchedTimestamp: Date.now(), requestState: REQUEST_STATE.PENDING };
        await dispatch(fetchCurrentUser());
      }
    }
  }, [dispatch, result]);

  return loadUser;
};

export default useFetchCurrentUser;
