import { EntityActionReducerBuilderInterface, REQUEST_STATE } from "../../dataTypes";
import { fetchNextUserAccessMessage } from "../../session/reducers/fetchNextUserAccessMessage";
import { UserAccessMessage } from "../dataTypes";
import { userAccessMessageAdapter } from "../userAccessMessageAdapter";

export const addFetchNextUserAccessMessageReducers = (
  builder: EntityActionReducerBuilderInterface<UserAccessMessage>,
) => {
  builder.addCase(fetchNextUserAccessMessage.fulfilled, (state, action) => {
    const { payload } = action;

    if (payload) {
      userAccessMessageAdapter.upsertOne(state, {
        ...payload,
        fetchedTimestamp: Date.now(),
        requestState: REQUEST_STATE.FULFILLED,
      });
    }
  });
};
