import { useState } from "react";
import OnClickOutside from "../../../../utils/OnClickOutside";
import Collapse from "../../../shared/Collapse/Collapse";
import styles from "./Menu.module.scss";
import { createMenu } from "../../../../utils/menu/menu";
import { MenuItem as MenuItemType } from "../../../../utils/menu/dataTypes";
import MenuItem from "./MenuItem";
import SubMenu from "./SubMenu";
import { useAppSelector } from "../../../../app/hooks";
import { selectSessionId } from "../../../../features/session/sessionSelectors";

function Menu() {
  const userLoggedIn = !!useAppSelector(selectSessionId);
  const [activeMenuItem, setActiveMenuItem] = useState<MenuItemType>();
  const [menuVisible, setMenuVisible] = useState(false);
  const menuItems = createMenu("MENU");

  let hideMenu: number;

  const handleOnMouseLeave = () => {
    hideMenu = window.setTimeout(() => {
      setMenuVisible(false);
    }, 1000);
  };

  const handleOnMouseEnter = () => {
    clearTimeout(hideMenu);
  };

  const toggleMenu = (item?: MenuItemType) => {
    if (item && item.id === activeMenuItem?.id) {
      setMenuVisible(menuVisible => !menuVisible);
    } else if (item) {
      setMenuVisible(true);
    } else {
      setMenuVisible(false);
    }

    setActiveMenuItem(item);
  };

  return (
    <OnClickOutside call={() => setMenuVisible(false)}>
      <nav className={styles.navBar} onMouseLeave={handleOnMouseLeave} onMouseEnter={handleOnMouseEnter}>
        {userLoggedIn && (
          <>
            <div className={styles.navContent}>
              <ul className={styles.menu}>
                {menuItems.map(item => (
                  <MenuItem
                    key={item.id}
                    menuItem={item}
                    toggleMenu={toggleMenu}
                    isActive={item.id === activeMenuItem?.id}
                    menuVisible={menuVisible}
                  />
                ))}
              </ul>
            </div>

            <Collapse visible={menuVisible} customCssClass={styles.submenuWrapper}>
              <div className={styles.subMenuContent}>
                {activeMenuItem?.subMenus?.map(subMenu => (
                  <SubMenu key={subMenu.id} subMenu={subMenu} toggleMenu={toggleMenu} />
                ))}
              </div>
            </Collapse>
          </>
        )}
      </nav>
    </OnClickOutside>
  );
}

export default Menu;
