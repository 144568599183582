const pages = Object.freeze({
  ACCESS_CARD: {
    PATH: "/access-card",
    NAME: "ACCESS_CARD_TITLE",
  },
  REPORT_ERROR: {
    PATH: "/report-error",
    NAME: "REPORT_ERROR_TITLE",
  },
  HOME: "/",
  INFORMATION: {
    PATH: "/information",
    NAME: "INFORMATION_TITLE",
  },
  SERVICES: {
    PATH: "/services",
    NAME: "SERVICES_TITLE",
  },
  SERVICE_DETAIL: {
    PATH: "/services/:id",
    NAME: "SERVICES_DETAIL_TITLE",
  },
  SERVICE_CONFIRMATION: {
    PATH: "/confirmation",
    NAME: "CONFIRMATION_TITLE",
  },
  REPORT_ERROR_DETAIL: {
    PATH: "/report-error/:id",
    NAME: "SERVICES_DETAIL_TITLE",
  },
  MY_PAGE: {
    PATH: "/my-page",
    NAME: "MY_PAGE_TITLE",
  },
  USER_SETTINGS: {
    PATH: "/settings",
    NAME: "USER_SETTINGS",
  },
  MY_CASES: {
    PATH: "/my-page/cases",
    NAME: "SUB_NAV_MY_CASES",
  },
  MY_CASES_DETAILS: {
    PATH: "/my-page/cases/:id",
    NAME: "SUB_NAV_MY_CASES_DETAILS",
  },

  MY_CASES_INFO: {
    PATH: "/my-page/cases/:id/info",
    NAME: "SUB_NAV_MY_CASES_INFO",
  },
  MY_CASES_FORM_DATA: {
    PATH: "/my-page/cases/:id/form-data",
    NAME: "SUB_NAV_MY_CASES_FORM_DATA",
  },
  MY_CASES_ATTACHMENTS: {
    PATH: "/my-page/cases/:id/attachments",
    NAME: "SUB_NAV_MY_CASES_ATTACHMENTS",
  },

  MY_PROFILE: {
    PATH: "/my-page/profile",
    NAME: "SUB_NAV_MY_PROFILE",
  },
  REGISTER: "account/register",
  RESET_PASSWORD: {
    PATH: "/password/reset",
    NAME: "RESET_PASSWORD_TITLE",
  },
});

export default pages;
