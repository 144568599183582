import { useEffect, useRef } from "react";
import useForceUpdate from "../../../hooks/useForceUpdate";
import useLazyGetFormDefinition from "./useLazyGetFormDefinition";

const useGetFormDefinition = ({ id }: { id?: string }) => {
  const { isLoading: isLazyLoading, fetchFormDefinition, ...rest } = useLazyGetFormDefinition({ id });
  const forceUpdate = useForceUpdate();
  const initiationStatus = useRef<"initiating" | "initiated">();

  useEffect(() => {
    const initiateFormDefinition = async () => {
      if (!initiationStatus.current) {
        initiationStatus.current = "initiating";
        forceUpdate();
        await fetchFormDefinition();
        initiationStatus.current = "initiated";
      }
    };

    initiateFormDefinition();
  }, [fetchFormDefinition, forceUpdate]);

  const isLoading = !initiationStatus.current || isLazyLoading;

  return { ...rest, isLoading, refetchFormDefinition: fetchFormDefinition };
};

export default useGetFormDefinition;
