import { Switch, BrowserRouter as Router, Route, Redirect } from "react-router-dom";

import classNames from "classnames";
import { useMobile } from "../../utils/viewport";

import pages from "../../utils/pages";
import styles from "./Site.module.scss";

import Footer from "./Footer/Footer";
import Header from "./Header/Header";
import MobileHeader from "./Header/MobileHeader/MobileHeader";
import MobileMenu from "./MobileMenu/MobileMenu";
import NotFoundPage from "../NotFound/NotFoundPage";
import ResetPasswordPage from "../Start/ResetPassword/ResetPasswordPage";
import StartPage from "../Start/StartPage";
import UserFeedback from "../shared/UserFeedback/UserFeedback";
import UserMessageToAcceptModal from "../UserSettings/UserMessageToAcceptModal";
import UserSettingsPage from "../UserSettings/UserSettingsPage";
import MyPage from "../MyPage/MyPage";
import ServicesPage from "../Services/ServicesPage";
import CaseDetailsPage from "../MyPage/CaseDetailsPage/CaseDetailsPage";
import ServiceDetail from "../Services/ServiceDetail/ServiceDetail";
import ServiceConfirmation from "../Services/ServiceConfirmation/ServiceConfirmation";
import AccessCard from "../AccessCard/AccessCard";
import { useAppSelector } from "../../app/hooks";
import { selectIsInitialized, selectIsSingleSignOn, selectSessionId } from "../../features/session/sessionSelectors";
import useInit from "../../utils/useInit";
import LoadingSpinner from "../shared/Spinner/LoadingSpinner";

const Site = () => {
  useInit();

  const isSmallScreen = useMobile();
  const userLoggedIn = !!useAppSelector(selectSessionId);
  const isInitialized = useAppSelector(selectIsInitialized);

  if (!isInitialized) {
    return <LoadingSpinner />;
  }

  if (window.location.pathname.match(/^\/embedded\//)) {
    return (
      <Router>
        <UserFeedback />
      </Router>
    );
  }

  return (
    <Router>
      {isSmallScreen ? <MobileHeader /> : <Header />}
      <main className={classNames(styles.main, isSmallScreen && userLoggedIn && styles.loggedInMobile)}>
        <PageRouter />
        <UserFeedback />
      </main>
      {<Footer />}
      {isSmallScreen && <MobileMenu />}
      {userLoggedIn && <UserMessageToAcceptModal />}
    </Router>
  );
};

const PageRouter = () => {
  const sessionId = useAppSelector(selectSessionId);
  const isSingleSignOn = useAppSelector(selectIsSingleSignOn);

  return (
    <>
      {!sessionId && !isSingleSignOn ? (
        <Switch>
          <Route exact path={pages.HOME}>
            <StartPage desiredPage={location.pathname + location.search} />
          </Route>
          <Route exact path={pages.RESET_PASSWORD.PATH}>
            <ResetPasswordPage />
          </Route>
          <Redirect to={pages.HOME} />
        </Switch>
      ) : (
        <Switch>
          <Route exact path={pages.HOME}>
            <StartPage />
          </Route>

          <Route path={pages.USER_SETTINGS.PATH}>
            <UserSettingsPage />
          </Route>

          <Route path={pages.MY_CASES_DETAILS.PATH}>
            <CaseDetailsPage />
          </Route>

          <Route path={pages.MY_CASES_INFO.PATH}>
            <CaseDetailsPage />
          </Route>

          <Route path={pages.MY_PAGE.PATH}>
            <MyPage />
          </Route>

          <Route path={pages.SERVICE_CONFIRMATION.PATH}>
            <ServiceConfirmation />
          </Route>

          <Route exact path={pages.SERVICES.PATH}>
            <ServicesPage />
          </Route>

          <Route exact path={pages.SERVICE_DETAIL.PATH}>
            <ServiceDetail />
          </Route>

          <Route exact path={pages.REPORT_ERROR_DETAIL.PATH}>
            <ServiceDetail />
          </Route>

          <Route exact path={pages.REPORT_ERROR.PATH}>
            <ServicesPage />
          </Route>
          <Route exact path={pages.ACCESS_CARD.PATH}>
            <AccessCard />
          </Route>
          <Route exact path={pages.INFORMATION.PATH}>
            <StartPage />
          </Route>
          <Route>
            <NotFoundPage />
          </Route>
        </Switch>
      )}
    </>
  );
};

export default Site;
