import { useCallback, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { REQUEST_STATE, UseFetchRefs } from "../../dataTypes";
import { setError } from "../../dialog/dialogSlice";
import mapUseFetchRefs from "../../utils/mapUseFetchRefs";
import mergeFetchedTimestampRefAndStateEntities from "../../utils/mergeFetchedTimestampRefAndStateEntities";
import { useValidateUserIsLoggedIn } from "../../utils/useValidateUserIsLoggedIn";
import { selectFileEntities } from "../fileSelectors";
import { fetchFile } from "../reducers/fetchFile";

let refs: UseFetchRefs;

const useFetchFile = () => {
  const dispatch = useAppDispatch();
  const result = useValidateUserIsLoggedIn();

  const files = useAppSelector(selectFileEntities);

  if ((refs as UseFetchRefs | undefined) === undefined) {
    refs = mapUseFetchRefs(files);
  }

  useEffect(() => {
    refs = mergeFetchedTimestampRefAndStateEntities(refs, mapUseFetchRefs(files));
  }, [files]);

  const loadFile = useCallback(
    async (id?: string) => {
      if (result.isError) {
        dispatch(setError({ value: result.errorMessage }));
      } else {
        if (id) {
          const ref = refs[id];

          if (!ref || ref.requestState !== REQUEST_STATE.PENDING) {
            refs[id] = { fetchedTimestamp: Date.now(), requestState: REQUEST_STATE.PENDING };
            await dispatch(fetchFile(id));
          }
        }
      }
    },
    [dispatch, result],
  );

  return loadFile;
};

export default useFetchFile;
