import { withPage } from "@myloc/myloc-utils";
import { Link } from "react-router-dom";
import { useTranslate } from "../../../language/i18n";
import pages from "../../../utils/pages";
import Page from "../../shared/Page/Page";

const ServiceConfirmation = () => {
  const translate = useTranslate();

  return (
    <Page title={translate(pages.SERVICE_CONFIRMATION.NAME)}>
      <p>
        {translate("CONFIRMATION_MESSAGE")}
        <Link to={pages.MY_CASES.PATH}> {translate("YOUR_CASES")}</Link>.
      </p>
    </Page>
  );
};

export default withPage(ServiceConfirmation);
