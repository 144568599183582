import { Method, Request, sendRequest } from "@myloc/myloc-utils";
import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { AppDispatch } from "../../../app/store";
import { api } from "../../../config/settings";
import { REQUEST_STATE } from "../../dataTypes";
import { ResponseUser } from "../../user/dataTypes";
import { createAsyncThunk } from "../../utils/createAsyncThunk";
import defaultRestOptions from "../../utils/defaultRestOptions";
import { CurrentUser, sessionSliceName, SessionState, UpdateUserCurrentDataRequest } from "../dataTypes";

async function sendUpdateCurrentUserData(dispatch: AppDispatch, data: UpdateUserCurrentDataRequest) {
  const url = api.user.currentUser();
  const request = new Request(url, Method.PUT);

  return await sendRequest(request, data, await defaultRestOptions({ dispatch }));
}

export const updateCurrentUserData = createAsyncThunk<ResponseUser, UpdateUserCurrentDataRequest>(
  sessionSliceName + "/updateCurrentUserData",
  (dispatch, request) => sendUpdateCurrentUserData(dispatch, request),
);

export const addUpdateCurrentUserDataReducers = (builder: ActionReducerMapBuilder<SessionState>) => {
  builder.addCase(updateCurrentUserData.pending, state => {
    if (!state.currentUser) state.currentUser = {} as CurrentUser;

    state.currentUser.requestState = REQUEST_STATE.PENDING;
    state.currentUser.errorMessage = undefined;
  });
  builder.addCase(updateCurrentUserData.fulfilled, (state, action) => {
    const { payload } = action;

    state.currentUser = { id: payload.id, fetchedTimestamp: Date.now(), requestState: REQUEST_STATE.FULFILLED };
  });
  builder.addCase(updateCurrentUserData.rejected, (state, action) => {
    if (!state.currentUser) state.currentUser = {} as CurrentUser;

    state.currentUser.requestState = REQUEST_STATE.REJECTED;
    state.currentUser.errorMessage = action.payload?.message;
  });
};
