import { Link } from "react-router-dom";
import pages from "../../../../utils/pages";
import logo from "../../../../assets/logos/Myloc-logga.svg";
import styles from "./MobileHeader.module.scss";

function MobileHeader() {
  return (
    <header className={styles.header}>
      <section className={styles.topBar}>
        <figure>
          <Link to={pages.HOME}>
            <img src={logo} alt="Myloc logo" className={styles.logo} />
          </Link>
        </figure>
      </section>
    </header>
  );
}

export default MobileHeader;
