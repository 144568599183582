import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { sessionSliceName, SessionState } from "./dataTypes";
import { addFetchCurrentPageContentReducers } from "./reducers/fetchCurrentPageContent";
import { addFetchCurrentUserReducers } from "./reducers/fetchCurrentUser";
import { addFetchNextUserAccessMessageReducers } from "./reducers/fetchNextUserAccessMessage";
import { addFetchSessionDataReducers } from "./reducers/fetchSessionData";
import { addLogInReducers } from "./reducers/logIn";
import { addLogOutReducers } from "./reducers/logOut";
import { addUpdateCurrentUserDataReducers } from "./reducers/updateCurrentUserData";
import { addUpdateUserAccessMessageReducers } from "./reducers/updateUserAccessMessage";

const initialState: SessionState = {
  isInitialized: false,
};

const slice = createSlice({
  name: sessionSliceName,
  initialState,
  reducers: {
    setIsInitialized(state, action: PayloadAction<boolean>) {
      state.isInitialized = action.payload;
    },
    setRememberMe(state, action: PayloadAction<boolean>) {
      state.rememberMe = action.payload;
    },
    setIsSingleSignOn(state, action: PayloadAction<boolean>) {
      state.isSingleSignOn = action.payload;
    },
    setIsSingleSignOut(state, action: PayloadAction<boolean>) {
      state.singleSignOut = action.payload;
    },
    setLoginToken(state, action: PayloadAction<string>) {
      state.loginToken = action.payload;
    },
  },
  extraReducers: builder => {
    addFetchCurrentUserReducers(builder);
    addFetchCurrentPageContentReducers(builder);
    addFetchNextUserAccessMessageReducers(builder);
    addFetchSessionDataReducers(builder);
    addLogInReducers(builder);
    addLogOutReducers(builder);
    addUpdateCurrentUserDataReducers(builder);
    addUpdateUserAccessMessageReducers(builder);
  },
});

const { actions, reducer } = slice;

export const { setIsInitialized, setIsSingleSignOn, setIsSingleSignOut, setRememberMe, setLoginToken } = actions;

export default reducer;
