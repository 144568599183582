import PropTypes from "prop-types";
import { URL } from "@myloc/myloc-utils";
import { TYPE, TARGET } from "../Constants/constants";
import styles from "./ExternalLink.module.scss";
import { ReactNode } from "react";
import { Hyperlink } from "../../../../features/hyperlink/dataTypes";
import { ValueOf } from "../../../../utils/dataTypes";

const ExternalLink = ({
  url,
  type = TYPE.URL,
  target,
  children,
  customCssClass,
}: {
  url: URL | string;
  type?: ValueOf<typeof TYPE>;
  target?: ValueOf<typeof TARGET>;
  children: ReactNode;
  customCssClass?: string;
}) => (
  <a href={type + (url instanceof URL ? url.toString : url)} target={target} className={customCssClass}>
    {children}
  </a>
);

ExternalLink.propTypes = {
  url: PropTypes.oneOfType([PropTypes.instanceOf(URL), PropTypes.string]).isRequired,
  type: PropTypes.oneOf(Object.values(TYPE)),
  target: PropTypes.oneOf(Object.values(TARGET)),
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]).isRequired,
  customCssClass: PropTypes.string,
};

export const ExternalLinkList = ({
  externalLinks,
  target,
  customCssClass,
}: {
  externalLinks: Array<Hyperlink>;
  target: ValueOf<typeof TARGET>;
  customCssClass?: string;
}) => (
  <ul className={customCssClass}>
    {externalLinks.map(link => (
      <li key={link.id} className={styles.link}>
        <ExternalLink url={link.url} target={target}>
          {link.name}
        </ExternalLink>
      </li>
    ))}
  </ul>
);

ExternalLinkList.propTypes = {
  externalLinks: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }),
  ).isRequired,
  target: PropTypes.oneOf(Object.values(TARGET)).isRequired,
  customCssClass: PropTypes.string,
};

export default ExternalLink;
