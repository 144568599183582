import { EntityActionReducerBuilderInterface, REQUEST_STATE } from "../../dataTypes";
import { createAndOrderWebOrder } from "../../webOrder/reducers/createAndOrderWebOrder";
import { ResponseWebOrderContentLine, WebOrderContentLine } from "../dataTypes";
import { webOrderContentLineAdapter } from "../webOrderContentLineAdapter";

export const addCreateAndOrderWebOrderReducers = (
  builder: EntityActionReducerBuilderInterface<WebOrderContentLine>,
) => {
  builder.addCase(createAndOrderWebOrder.fulfilled, (state, action) => {
    const contents = action.payload.contents;

    if (contents) {
      const lines = contents.reduce((lines, content) => {
        return content.lines ? [...lines, ...content.lines] : lines;
      }, [] as ResponseWebOrderContentLine[]);

      webOrderContentLineAdapter.addMany(
        state,
        lines.map(line => ({ ...line, fetchedTimestamp: Date.now(), requestState: REQUEST_STATE.FULFILLED })),
      );
    }
  });
};
