import { Dictionary } from "@reduxjs/toolkit";
import cloneDeep from "lodash.clonedeep";
import { useCallback, useEffect } from "react";
import isEqual from "react-fast-compare";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { RequestState, UseFetchRef, UseFetchRefs, FulfilledUpdateMethod, REQUEST_STATE } from "../../dataTypes";
import { setError } from "../../dialog/dialogSlice";
import mergeFetchedTimestampRefAndStateEntities from "../../utils/mergeFetchedTimestampRefAndStateEntities";
import { useValidateUserIsLoggedIn } from "../../utils/useValidateUserIsLoggedIn";
import { WebOrderContent, WebOrderContentLinesFilter } from "../dataTypes";
import { fetchLines } from "../reducers/fetchLines";
import { selectWebOrderContentEntities } from "../webOrderContentSelectors";

interface Refs extends UseFetchRefs {
  [id: string]: (UseFetchRef & { lines: { requestState?: RequestState } }) | undefined;
}

let refs: Refs;
const filterRef: { [webOrderContentId: string]: WebOrderContentLinesFilter | undefined } = {};

const mapWebOrderContents = (webOrderContents: Dictionary<WebOrderContent>) => {
  return Object.entries(webOrderContents).reduce((result, [id, webOrderContent]) => {
    result[id] = webOrderContent
      ? {
          lines: { requestState: webOrderContent.lines?.requestState },
          fetchedTimestamp: webOrderContent.fetchedTimestamp,
          requestState: webOrderContent.requestState,
        }
      : undefined;

    return result;
  }, {} as Refs);
};

const useFetchLines = () => {
  const dispatch = useAppDispatch();
  const result = useValidateUserIsLoggedIn();

  const webOrderContents = useAppSelector(selectWebOrderContentEntities);

  if ((refs as UseFetchRefs | undefined) === undefined) refs = mapWebOrderContents(webOrderContents);

  useEffect(() => {
    refs = mergeFetchedTimestampRefAndStateEntities(refs, mapWebOrderContents(webOrderContents));
  }, [webOrderContents]);

  const loadLines = useCallback(
    async ({
      webOrderContentId,
      filter,
      fulfilledUpdateMethod,
    }: {
      webOrderContentId?: string;
      filter?: WebOrderContentLinesFilter;
      fulfilledUpdateMethod?: FulfilledUpdateMethod;
    }) => {
      if (result.isError) {
        dispatch(setError({ value: result.errorMessage }));
      } else {
        if (webOrderContentId) {
          const ref = refs[webOrderContentId];

          if (!isEqual(filter, filterRef[webOrderContentId]) || ref?.lines.requestState !== REQUEST_STATE.PENDING) {
            refs[webOrderContentId] = {
              lines: { requestState: REQUEST_STATE.PENDING },
              fetchedTimestamp: Date.now(),
              requestState: REQUEST_STATE.PENDING,
            };
            filterRef[webOrderContentId] = cloneDeep(filter);

            await dispatch(fetchLines({ webOrderContentId, filter, fulfilledUpdateMethod }));
          }
        }
      }
    },
    [dispatch, result],
  );

  return loadLines;
};

export default useFetchLines;
