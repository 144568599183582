import { appDataName } from "../../app/dataTypes";
import { RootState } from "../../app/rootReducer";
import { formSliceName } from "./dataTypes";
import { formAdapter } from "./formAdapter";

const selectSliceState = (state: RootState) => state[appDataName][formSliceName];

const adapterSelectors = formAdapter.getSelectors(selectSliceState);

export const selectErrorMessage = (state: RootState) => selectSliceState(state).errorMessage;

export const selectRequestState = (state: RootState) => selectSliceState(state).requestState;

export const selectFormEntities = adapterSelectors.selectEntities;
export const selectForms = adapterSelectors.selectAll;
export const selectForm = (id = "") => (state: RootState) => adapterSelectors.selectById(state, id);
