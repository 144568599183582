import PropTypes from "prop-types";
import classNames from "classnames";
import { DownloadLink } from "../../Link";
import FileIcon from "../FileIcon/FileIcon";
import styles from "./FileDownload.module.scss";
import { MylocFile, UploadFile } from "../../../../features/file/dataTypes";
import { getTimestamp } from "../../../../utils/getTimestamp";

export const FileDownload = ({
  file,
  iconSize = 40,
  customCssClass,
}: {
  file: MylocFile | UploadFile;
  iconSize?: number;
  customCssClass?: string;
}) => {
  return (
    <DownloadLink url={file.url} customCssClass={classNames(styles.link, customCssClass)}>
      <FileIcon size={iconSize} customCssClass={styles.checkIcon} fileName={file.name} />
      <div className={styles.title}>
        <p>{file.name}</p>
        {"createdTimestamp" in file && <sub>{getTimestamp(file.createdTimestamp)}</sub>}
      </div>
    </DownloadLink>
  );
};

const FileDownloadList = ({ files, customCssClass }: { files: Array<MylocFile>; customCssClass?: string }) => {
  return (
    <ul className={customCssClass}>
      {files.map(file => (
        <li key={file.id}>
          <FileDownload file={file} />
        </li>
      ))}
    </ul>
  );
};

export default FileDownloadList;

FileDownload.propTypes = {
  file: PropTypes.shape({
    url: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    createdTimestamp: PropTypes.number,
  }).isRequired,
  iconSize: PropTypes.number,
  customCssClass: PropTypes.string,
};
