import { appDataName } from "../../app/dataTypes";
import { RootState } from "../../app/rootReducer";
import { webOrderContentSliceName } from "./dataTypes";
import { webOrderContentAdapter } from "./webOrderContentAdapter";

const selectSliceState = (state: RootState) => state[appDataName][webOrderContentSliceName];

const adapterSelectors = webOrderContentAdapter.getSelectors(selectSliceState);

export const selectErrorMessage = (state: RootState) => selectSliceState(state).errorMessage;

export const selectRequestState = (state: RootState) => selectSliceState(state).requestState;

export const selectCount = (state: RootState) => selectSliceState(state).count;

export const selectWebOrderContentEntities = adapterSelectors.selectEntities;
export const selectWebOrderContents = adapterSelectors.selectAll;
export const selectWebOrderContent = (id = "") => (state: RootState) => adapterSelectors.selectById(state, id);
