import { createSlice } from "@reduxjs/toolkit";
import { AdditionalRequestState } from "../dataTypes";
import { webOrderContentLineSliceName } from "./dataTypes";
import { webOrderContentLineAdapter } from "./webOrderContentLineAdapter";
import { addFetchWebOrderContentLineReducers } from "./reducers/fetchWebOrderContentLine";
import { addCreateAndOrderWebOrderReducers } from "./reducers/createAndOrderWebOrder";
import { addFetchLinesForWebOrderContent } from "./reducers/fetchLinesForWebOrderContent";

const slice = createSlice({
  name: webOrderContentLineSliceName,
  initialState: webOrderContentLineAdapter.getInitialState<AdditionalRequestState>({}),
  reducers: {},
  extraReducers: builder => {
    addCreateAndOrderWebOrderReducers(builder);
    addFetchWebOrderContentLineReducers(builder);
    addFetchLinesForWebOrderContent(builder);
  },
});

const { reducer } = slice;

export default reducer;
