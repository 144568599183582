import { useCallback, useRef, useState } from "react";
import { useAppDispatch } from "../../../app/hooks";
import { useTranslate } from "../../../language/i18n";
import HttpStatusCodes from "../../../utils/HttpStatusCodes";
import { setError } from "../../dialog/dialogSlice";
import { getValidationResult } from "../../utils/getValidationResults";
import { CreateWebOrderRequest } from "../dataTypes";
import { createAndOrderWebOrder as createAndOrderWebOrderAction } from "../reducers/createAndOrderWebOrder";
import { useValidateUserIsLoggedIn } from "../../utils/useValidateUserIsLoggedIn";
import { PartialDeep } from "type-fest";

const useCreateAndOrderWebOrder = () => {
  const dispatch = useAppDispatch();
  const translate = useTranslate();
  const result = useValidateUserIsLoggedIn();

  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const isCreating = useRef(false);

  const validateCreateAndOrderWebOrder = useCallback(
    (request?: PartialDeep<CreateWebOrderRequest>) => {
      if (result.isError) return result;
      if (!request) return getValidationResult(true, translate("DATA_MISSING"), HttpStatusCodes.BAD_REQUEST);
      if (!request.customer)
        return getValidationResult(true, translate("CUSTOMER_INFO_MISSING"), HttpStatusCodes.BAD_REQUEST);
      if (!request.customer.type)
        return getValidationResult(true, translate("CUSTOMER_TYPE_MISSING"), HttpStatusCodes.BAD_REQUEST);
      if (!request.customer.id)
        return getValidationResult(true, translate("CUSTOMER_ID_MISSING"), HttpStatusCodes.BAD_REQUEST);
      return getValidationResult(false);
    },
    [translate, result],
  );

  const createAndOrderWebOrder = useCallback(
    async (request: CreateWebOrderRequest) => {
      if (!isCreating.current) {
        isCreating.current = true;
        setIsLoading(true);
        setIsSuccess(false);
        setIsError(false);
        setErrorMessage(undefined);

        const result = validateCreateAndOrderWebOrder(request);
        let orderId: string | undefined;

        if (result.isError) {
          dispatch(setError({ value: result.errorMessage }));
          setIsError(result.isError);
          setErrorMessage(result.errorMessage);
        } else {
          const response = (await dispatch(createAndOrderWebOrderAction(request))).payload;

          if (response) {
            if ("webOrder" in response) orderId = response.webOrder.id;
            else setErrorMessage(response.message);

            setIsSuccess(!!orderId);
          }

          setIsError(!orderId);
        }

        setIsLoading(false);
        isCreating.current = false;

        return orderId;
      }
    },
    [dispatch, validateCreateAndOrderWebOrder],
  );

  return { createAndOrderWebOrder, isLoading, isSuccess, isError, errorMessage };
};

export default useCreateAndOrderWebOrder;
