import { Method, Request, sendRequest } from "@myloc/myloc-utils";
import { AppDispatch } from "../../../app/store";
import { api } from "../../../config/settings";
import setQueryFilters from "../../utils/setQueryFilters";
import {
  EntityActionReducerBuilderInterface,
  PaginationResponse,
  FulfilledUpdateMethod,
  FULFILLED_UPDATE_METHOD,
  REQUEST_STATE,
} from "../../dataTypes";
import { createAsyncThunk } from "../../utils/createAsyncThunk";
import defaultRestOptions from "../../utils/defaultRestOptions";
import { ResponseWebOrderContentLine } from "../../webOrderContentLine/dataTypes";
import { WebOrderContent, WebOrderContentLinesFilter, webOrderContentSliceName } from "../dataTypes";
import { webOrderContentAdapter } from "../webOrderContentAdapter";

async function getLines(dispatch: AppDispatch, webOrderContentId: string, filters?: WebOrderContentLinesFilter) {
  const url = api.webOrderContent.lines(webOrderContentId);

  setQueryFilters(url, filters);

  const request = new Request(url, Method.GET);

  return await sendRequest(request, {}, await defaultRestOptions({ dispatch }));
}

export const fetchLines = createAsyncThunk<
  PaginationResponse<ResponseWebOrderContentLine>,
  { webOrderContentId: string; filter?: WebOrderContentLinesFilter; fulfilledUpdateMethod?: FulfilledUpdateMethod }
>(webOrderContentSliceName + "/fetchWebOrderContentLines", (dispatch, { webOrderContentId, filter }) =>
  getLines(dispatch, webOrderContentId, filter),
);

export const addFetchLinesReducers = (builder: EntityActionReducerBuilderInterface<WebOrderContent>) => {
  builder.addCase(fetchLines.pending, (state, action) => {
    const {
      meta: {
        arg: { webOrderContentId },
      },
    } = action;

    const webOrderContent = state.entities[webOrderContentId] ?? ({ id: webOrderContentId } as WebOrderContent);

    if (!webOrderContent.lines) webOrderContent.lines = { ids: [] };

    webOrderContent.lines.errorMessage = undefined;
    webOrderContent.lines.requestState = REQUEST_STATE.PENDING;
    webOrderContentAdapter.upsertOne(state, webOrderContent);
  });
  builder.addCase(fetchLines.fulfilled, (state, action) => {
    const {
      meta: {
        arg: { webOrderContentId, fulfilledUpdateMethod = FULFILLED_UPDATE_METHOD.UPSERT_MANY },
      },
      payload,
    } = action;

    const webOrderContent = state.entities[webOrderContentId];

    if (webOrderContent) {
      if (webOrderContent.lines) {
        const ids = fulfilledUpdateMethod === FULFILLED_UPDATE_METHOD.SET_ALL ? [] : webOrderContent.lines.ids;

        payload.page.forEach(line => {
          if (!ids.includes(line.id)) ids.push(line.id);
        });

        webOrderContent.lines.ids = ids;
        webOrderContent.lines.requestState = REQUEST_STATE.FULFILLED;
      }
    }
  });
  builder.addCase(fetchLines.rejected, (state, action) => {
    const {
      meta: {
        arg: { webOrderContentId },
      },
      payload,
    } = action;

    const webOrderContent = state.entities[webOrderContentId];

    if (webOrderContent) {
      if (webOrderContent.lines) {
        webOrderContent.lines.requestState = REQUEST_STATE.REJECTED;
        webOrderContent.lines.errorMessage = payload?.message;
      }
    }
  });
};
