import React, { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import PropType from "prop-types";
import { Button, THEME } from "@myloc/myloc-gui";
import { useMobile } from "../../../utils/viewport";
import { useTranslate } from "../../../language/i18n";
import styles from "./TopFilter.module.scss";
import { Form, InputField } from "@myloc/myloc-gui";
import { useHistory } from "@myloc/myloc-utils";
import { useLocation } from "react-router-dom";
import classNames from "classnames";
import { FilterListIcon, Search } from "../../../assets/icons";
import { IdObject } from "../../../features/dataTypes";

const TopFilter = <T extends Record<string, any>>({
  children,
  onSubmit,
  filters,
  setFilters,
  fields,
  label,
  path,
}: {
  children?: ReactNode;
  onSubmit: () => void;
  filters: Readonly<T>;
  setFilters: (filter?: T) => void;
  fields: Readonly<(keyof T)[]>;
  label: string;
  path: string;
}) => {
  const history = useHistory();
  const translate = useTranslate();
  const isMobile = useMobile();
  const location = useLocation();
  const params = useMemo(() => new URLSearchParams(location.search), [location.search]);

  const inputFieldId = "inputFieldId";

  const onClickToggleFilters = () => {
    setShowFilters(showFilters => !showFilters);
  };

  const getValue = useCallback(
    (key: keyof T) => {
      const fetchedKey = params.get(key.toString()) ?? "";

      if (fetchedKey.includes(",")) {
        const split = fetchedKey.split(",");
        const arr: IdObject[] = [];

        split.forEach(item => {
          arr.push({ id: item });
        });

        return arr;
      } else return fetchedKey;
    },
    [params],
  );

  const getChoicesFromParams = useCallback(() => {
    return fields.reduce(
      (choice, key) => ({
        ...choice,
        [key]: getValue(key),
      }),
      {} as T,
    );
  }, [fields, getValue]);

  const [showFilters, setShowFilters] = useState(() => !!Object.values(getChoicesFromParams()).find(choice => choice));
  const [search, setSearch] = useState(params.get("q") ?? "");

  useEffect(() => {
    setShowFilters(!!Object.values(getChoicesFromParams()).find(choice => choice));
  }, [getChoicesFromParams]);

  useEffect(() => {
    setFilters(getChoicesFromParams());
  }, [getChoicesFromParams, setFilters]);

  const saveFilters = useCallback(() => {
    let paramsChanged = false;

    Object.entries(filters).forEach(([key, value]) => {
      if (value && value !== params.get(key)) {
        if (Array.isArray(value)) {
          const ids: string[] = [];

          value.forEach(filter => {
            ids.push(filter.id);
          });

          if (ids.length) {
            params.set(key, ids.join(","));
            paramsChanged = true;
          }
        } else {
          params.set(key, value);
          paramsChanged = true;
        }
      } else if (!value && params.get(key)) {
        params.delete(key);
        paramsChanged = true;
      }

      if (paramsChanged) {
        history.replace({ PATH: path, NAME: "", STATE: undefined }, params);
      }
    });
  }, [filters, history, params, path]);

  const clearFilters = useCallback(() => {
    Object.keys(filters).forEach(key => params.delete(key));
  }, [filters, params]);

  useEffect(() => {
    let paramsChanged = false;

    if (search && search !== params.get("q")) {
      params.set("q", search);
      paramsChanged = true;
    } else if (!search && params.get("q")) {
      params.delete("q");
      paramsChanged = true;
    }

    showFilters ? saveFilters() : clearFilters();

    if (paramsChanged) {
      history.replace({ PATH: path, NAME: "", STATE: undefined }, params);
    }
  }, [clearFilters, history, params, path, saveFilters, search, showFilters]);

  const clear = () => {
    clearFilters();
    setSearch("");
    selectInputField();
    setFilters();
    history.replace({ PATH: path, NAME: "", STATE: undefined }, params);
  };

  const clearSearch = () => {
    setSearch("");
    selectInputField();
  };

  function selectInputField() {
    const input = document.getElementById(inputFieldId) as HTMLInputElement | null;

    input?.select();
  }

  return (
    <section>
      <Form onSubmit={onSubmit}>
        <div className={styles.generalSearch}>
          <InputField
            id={inputFieldId}
            label={label}
            value={search}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSearch(event.target.value)}
            customCssClass={styles.noErrorField}
            customInputCssClass={styles.input}
            onClear={clearSearch}
          />
          <div className={styles.buttons}>
            <Button
              type="button"
              customCssClass={classNames(styles.icon, isMobile ? styles.mobileSearch : "")}
              onClick={onClickToggleFilters}
            >
              <FilterListIcon customCssClass={styles.space} />
            </Button>

            <Button type="submit" customCssClass={isMobile ? styles.mobileSearch : ""}>
              {isMobile ? <Search /> : translate("SEARCH")}
            </Button>
          </div>
        </div>

        {showFilters && (
          <div className={styles.filters}>
            {children}

            <div className={styles.clearButton}>
              <Button type="button" customCssClass={styles.noBorder} theme={THEME.SECONDARY} onClick={clear}>
                {translate("CLEAR")}
              </Button>
            </div>
          </div>
        )}
      </Form>
    </section>
  );
};

TopFilter.propTypes = {
  children: PropType.node,
  onSubmit: PropType.func.isRequired,
  filters: PropType.object.isRequired,
  setFilters: PropType.func.isRequired,
  fields: PropType.array.isRequired,
  label: PropType.string.isRequired,
  path: PropType.string.isRequired,
} as unknown;

export default TopFilter;
