import { appDataName } from "../../app/dataTypes";
import { RootState } from "../../app/rootReducer";
import { serviceTypeSliceName } from "./dataTypes";
import { serviceTypeAdapter } from "./serviceTypeAdapter";

const selectSliceState = (state: RootState) => state[appDataName][serviceTypeSliceName];

const adapterSelectors = serviceTypeAdapter.getSelectors(selectSliceState);

export const selectErrorMessage = (state: RootState) => selectSliceState(state).errorMessage;

export const selectRequestState = (state: RootState) => selectSliceState(state).requestState;

export const selectCount = (state: RootState) => selectSliceState(state).count;

export const selectServiceTypeEntities = adapterSelectors.selectEntities;
export const selectServiceTypes = adapterSelectors.selectAll;
export const selectServiceType = (id = "") => (state: RootState) => adapterSelectors.selectById(state, id);
