import { PaginationFilter, SortFilter } from "../utils/pagination";
import {
  AdditionalRequestState,
  ExecutingOrganizationUnit,
  FetchedDetail,
  FetchedIdObject,
  IdObject,
  LabelValue,
  Owner,
  ReferenceIds,
} from "../dataTypes";

export const serviceTypeSliceName = "serviceType";

interface Status extends LabelValue {
  value: "PRELIMINARY" | "ACTIVE" | "ARCHIVED";
}

export interface ResponseServiceType extends IdObject {
  serviceType: string;
  owner: Owner;
  status: Status;
  name: string;
  description?: string;
  externalNote?: string;
  executingOrganizationUnit: ExecutingOrganizationUnit;
  serviceTypeCategoryId?: string;
  formDefinitionId?: string;
}

export interface ServiceType extends ResponseServiceType, AdditionalRequestState, FetchedIdObject, FetchedDetail {
  files?: ReferenceIds;
}

interface ServiceTypesSorting extends SortFilter {
  id: "name";
}

export interface ServiceTypesFilter extends PaginationFilter {
  freeTextSearch?: string;
  serviceTypeCategoryId?: string;
  serviceTypeCategory?: string;
  sort?: ServiceTypesSorting[];
}

interface ServiceTypeFilesSorting extends SortFilter {
  id: "sequence";
}

export interface ServiceTypeFilesFilter extends PaginationFilter {
  sort?: ServiceTypeFilesSorting[];
}
