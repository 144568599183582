import { useCallback, useEffect, useRef } from "react";
import { useAppSelector } from "../../../app/hooks";
import { REQUEST_STATE } from "../../dataTypes";
import { selectFormDefinition } from "../formDefinitionSelectors";
import useFetchFormDefinition from "./useFetchFormDefinition";

const useLazyGetFormDefinition = ({
  id,
  loadIfNotInitialized = false,
}: {
  id?: string;
  loadIfNotInitialized?: boolean;
}) => {
  const fetchFormDefinition = useFetchFormDefinition();
  const formDefinition = useAppSelector(selectFormDefinition(id));
  const hasInitalized = useRef(false);

  const requestState = formDefinition?.requestState;
  const errorMessage = formDefinition?.errorMessage;

  const isUninitialized = requestState === undefined;
  const isLoading = requestState === REQUEST_STATE.PENDING;
  const isError = requestState === REQUEST_STATE.REJECTED;
  const isSuccess = requestState === REQUEST_STATE.FULFILLED;

  const loadFormDefinition = useCallback(async () => {
    await fetchFormDefinition(id);
  }, [fetchFormDefinition, id]);

  useEffect(() => {
    hasInitalized.current = false;
  }, [id]);

  useEffect(() => {
    if (loadIfNotInitialized && isUninitialized && !hasInitalized.current) {
      hasInitalized.current = true;
      loadFormDefinition();
    }
  }, [isUninitialized, loadFormDefinition, loadIfNotInitialized]);

  return {
    formDefinition,
    isUninitialized,
    isLoading,
    isError,
    isSuccess,
    errorMessage,
    fetchFormDefinition: loadFormDefinition,
  };
};

export default useLazyGetFormDefinition;
