import { useLocation, useParams } from "react-router-dom";
import { useTranslate } from "../../../language/i18n";
import pages from "../../../utils/pages";
import { withPage } from "@myloc/myloc-utils";
import useGetServiceType from "../../../features/serviceType/hooks/useGetServiceType";
import ServiceTypeInformation from "./ServiceTypeInformation/ServicetypeInformation";
import LoadingSpinner from "../../shared/Spinner/LoadingSpinner";

type ServiceTypeParams = { id?: string };

type Location = { referrer?: string };

const ServiceDetail = () => {
  const translate = useTranslate();
  const location = useLocation<Location | undefined>();
  const params = useParams<ServiceTypeParams>();
  const { serviceType, isLoading } = useGetServiceType({ id: params.id });

  const referrer = location.state?.referrer;
  const fromErrorReport = Boolean(location.pathname.match(/^\/report-error/));

  if (isLoading) return <LoadingSpinner />;

  const breadcrumbs = () => {
    return [
      {
        text: fromErrorReport ? translate(pages.REPORT_ERROR.NAME) : translate(pages.SERVICES.NAME),
        link: referrer ?? (fromErrorReport ? pages.REPORT_ERROR.PATH : pages.SERVICES.PATH),
      },
      {
        text: serviceType?.name ?? "",
      },
    ];
  };

  return (
    <div>
      {serviceType ? (
        <ServiceTypeInformation serviceType={serviceType} breadcrumbs={breadcrumbs} />
      ) : (
        <p>{translate("SERVICE_TYPE_NOT_FOUND")}</p>
      )}
    </div>
  );
};

export default withPage(ServiceDetail);
