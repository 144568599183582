import { useCallback, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { FETCH_MASTER_DATA_TIME_OUT, REQUEST_STATE, UseFetchRefs } from "../../dataTypes";
import { setError } from "../../dialog/dialogSlice";
import mapUseFetchRefs from "../../utils/mapUseFetchRefs";
import mergeFetchedTimestampRefAndStateEntities from "../../utils/mergeFetchedTimestampRefAndStateEntities";
import { useValidateUserIsLoggedIn } from "../../utils/useValidateUserIsLoggedIn";
import { selectUserAccessMessageEntities } from "../userAccessMessageSelectors";

let refs: UseFetchRefs;

const useFetchUserAccessMessage = () => {
  const dispatch = useAppDispatch();
  const userAccessMessages = useAppSelector(selectUserAccessMessageEntities);
  const result = useValidateUserIsLoggedIn();

  if ((refs as UseFetchRefs | undefined) === undefined) refs = mapUseFetchRefs(userAccessMessages);

  useEffect(() => {
    refs = mergeFetchedTimestampRefAndStateEntities(refs, mapUseFetchRefs(userAccessMessages));
  }, [userAccessMessages]);

  const loadUserAccessMessage = useCallback(
    async (id?: string) => {
      if (result.isError) {
        dispatch(setError({ value: result.errorMessage }));
      } else if (id) {
        const ref = refs[id];

        if (
          !ref ||
          (ref.requestState !== REQUEST_STATE.PENDING && ref.fetchedTimestamp + FETCH_MASTER_DATA_TIME_OUT < Date.now())
        ) {
          refs[id] = { fetchedTimestamp: Date.now(), requestState: REQUEST_STATE.PENDING };
          //Nothing to do for now
        }
      }
    },
    [dispatch, result],
  );

  return loadUserAccessMessage;
};

export default useFetchUserAccessMessage;
