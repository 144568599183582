export const FILTER_OPERATOR = {
  EQ: "=",
  LE: "<=",
  GE: ">=",
  LT: "<",
  GT: ">",
  NE: "<>",
  LIKE: "LIKE",
  NOT_LIKE: "NOT LIKE",
  IN: "IN",
  IS_NULL: "IS NULL",
  IS_NOT_NULL: "IS NOT NULL",
} as const;

export const ORDER_BY_OPERATOR = {
  ASC: "asc",
  DESC: "desc",
} as const;

export const SQL_OPERATOR = { ...FILTER_OPERATOR, ...ORDER_BY_OPERATOR } as const;
