import { MenuItem } from "./dataTypes";
import pages from "../pages";
import cloneDeep from "lodash.clonedeep";

const asMenuItem = <T>(menuItems: { [K in keyof T]: { items: MenuItem[] } }) => menuItems;

const CONFIGS = asMenuItem({
  MENU: {
    items: [
      {
        label: "MENU_SERVICES",
        to: pages.SERVICES.PATH,
        id: "MENU_SERVICES",
      },
      {
        label: "MENU_REPORT_ERROR",
        to: pages.REPORT_ERROR.PATH,
        id: "MENU_REPORT_ERROR",
      },
      {
        label: "MENU_ACCESS_CARD",
        to: pages.ACCESS_CARD.PATH,
        id: "MENU_ACCESS_CARD",
      },
      {
        label: "MENU_INFORMATION",
        to: pages.INFORMATION.PATH,
        id: "MENU_INFORMATION",
      },
    ],
  },
  MENU_DRAWER: {
    items: [
      {
        label: "MENU_SERVICES",
        to: pages.SERVICES.PATH,
        id: "MENU_SERVICES",
      },
      {
        label: "MENU_REPORT_ERROR",
        to: pages.REPORT_ERROR.PATH,
        id: "MENU_REPORT_ERROR",
      },
      {
        label: "MENU_ACCESS_CARD",
        to: pages.ACCESS_CARD.PATH,
        id: "MENU_ACCESS_CARD",
      },
      {
        label: "MENU_INFORMATION",
        to: pages.INFORMATION.PATH,
        id: "MENU_INFORMATION",
      },
    ],
  },
});

export const createMenu = (conf: keyof typeof CONFIGS) => {
  const menuConfig = CONFIGS[conf];

  return cloneDeep(menuConfig.items);
};
