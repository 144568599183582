import { useCallback, useEffect, useRef } from "react";
import { useAppSelector } from "../../../app/hooks";
import { REQUEST_STATE } from "../../dataTypes";
import { selectServiceType } from "../serviceTypeSelectors";
import useFetchServiceType from "./useFetchServiceType";

const useLazyGetServiceType = ({
  id,
  loadIfNotInitialized = false,
}: {
  id?: string;
  loadIfNotInitialized?: boolean;
}) => {
  const fetchServiceType = useFetchServiceType();
  const serviceType = useAppSelector(selectServiceType(id));
  const hasInitalized = useRef(false);

  const requestState = serviceType?.requestState;
  const errorMessage = serviceType?.errorMessage;

  const isUninitialized = requestState === undefined;
  const isLoading = requestState === REQUEST_STATE.PENDING;
  const isError = requestState === REQUEST_STATE.REJECTED;
  const isSuccess = requestState === REQUEST_STATE.FULFILLED;

  const loadServiceType = useCallback(async () => {
    await fetchServiceType(id);
  }, [fetchServiceType, id]);

  useEffect(() => {
    hasInitalized.current = false;
  }, [id]);

  useEffect(() => {
    if (loadIfNotInitialized && (isUninitialized || !serviceType?.hasFetchedDetail) && !hasInitalized.current) {
      hasInitalized.current = true;
      loadServiceType();
    }
  }, [isUninitialized, loadServiceType, loadIfNotInitialized, serviceType?.hasFetchedDetail]);

  return {
    serviceType,
    isUninitialized,
    isLoading,
    isError,
    isSuccess,
    errorMessage,
    fetchServiceType: loadServiceType,
  };
};

export default useLazyGetServiceType;
