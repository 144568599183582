import { FormData } from "@myloc/myg-forms";
import { useEffect, useState } from "react";
import { MylocFile } from "../../file/dataTypes";

import useGetForm from "./useGetForm";
import useLazyGetFiles from "./useLazyGetFiles";

const getAggregatedSequences = (
  formData: Readonly<Parameters<typeof FormData>["0"]["form"]["sequences"]>,
  files: { [fileId: string]: MylocFile | undefined },
) => {
  let hasNewData = false;
  const newData = [...formData];

  for (let index = 0; index < newData.length; index++) {
    const sequence = newData[index];

    if ("type" in sequence) {
      if (sequence.fileId && files[sequence.fileId]) {
        if (sequence.file !== files[sequence.fileId]) {
          newData[index] = { ...sequence, file: files[sequence.fileId] };
          hasNewData = true;
        }
      }
    } else if (sequence.sequences) {
      const newSequences = getAggregatedSequences(sequence.sequences, files);

      if (newSequences !== sequence.sequences) {
        newData[index] = { ...sequence, sequences: newSequences };
        hasNewData = true;
      }
    }
  }

  return hasNewData ? newData : (formData as Parameters<typeof FormData>["0"]["form"]["sequences"]);
};

const useGetAggregatedForm = ({ id, loadFiles }: { id?: string; loadFiles: boolean }) => {
  const { form, isLoading, refetchForm } = useGetForm({ id });
  const { files, isLoading: isFilesLoading } = useLazyGetFiles({ id, loadIfNotInitialized: loadFiles });
  const [modifiedForm, setModifiedForm] = useState<Parameters<typeof FormData>["0"]["form"] | undefined>(() =>
    form && !isLoading
      ? loadFiles
        ? { ...form, sequences: getAggregatedSequences(form.sequences, files) }
        : form
      : undefined,
  );

  useEffect(() => {
    setModifiedForm(modifiedForm => {
      const currForm = modifiedForm ?? (!isLoading ? form : undefined);

      if (currForm) {
        if (loadFiles) {
          const newSequences = getAggregatedSequences(currForm.sequences, files);

          if (newSequences !== currForm.sequences) return { ...currForm, sequences: newSequences };
        }

        return currForm;
      }
    });
  }, [form, files, isLoading, loadFiles]);

  return { form: modifiedForm, isLoading, isFilesLoading: loadFiles && isFilesLoading, refetchForm };
};

export default useGetAggregatedForm;
