import { useEffect, useRef } from "react";
import { WebOrderContentFilesFilter } from "../dataTypes";
import { FulfilledUpdateMethod } from "../../dataTypes";
import useLazyGetFiles from "./useLazyGetFiles";
import useForceUpdate from "../../../hooks/useForceUpdate";

const useGetFiles = ({
  webOrderContentId,
  filter,
  fulfilledUpdateMethod,
}: {
  webOrderContentId?: string;
  filter?: WebOrderContentFilesFilter;
  fulfilledUpdateMethod?: FulfilledUpdateMethod;
}) => {
  const { isLoading: isLazyLoading, fetchFiles, ...rest } = useLazyGetFiles({
    webOrderContentId,
    filter,
    fulfilledUpdateMethod,
  });

  const forceUpdate = useForceUpdate();

  const initiationStatus = useRef<"initiating" | "initiated">();

  useEffect(() => {
    const initiateFiles = async () => {
      if (!initiationStatus.current) {
        initiationStatus.current = "initiating";
        forceUpdate();
        await fetchFiles();
        initiationStatus.current = "initiated";
      }
    };

    initiateFiles();
  }, [fetchFiles, forceUpdate]);

  const isLoading = !initiationStatus.current || isLazyLoading;

  return { ...rest, isLoading, refetchFiles: fetchFiles };
};

export default useGetFiles;
