import { useEffect, useRef } from "react";
import useForceUpdate from "../../../hooks/useForceUpdate";
import useLazyGetWebOrderContent from "./useLazyGetWebOrderContent";

const useGetWebOrderContent = ({ id }: { id?: string }) => {
  const { isLoading: isLazyLoading, fetchWebOrderContent, ...rest } = useLazyGetWebOrderContent({
    id,
  });

  const forceUpdate = useForceUpdate();

  const initiationStatus = useRef<"initiating" | "initiated">();

  useEffect(() => {
    const initiateWebOrderContent = async () => {
      if (!initiationStatus.current) {
        initiationStatus.current = "initiating";
        forceUpdate;
        await fetchWebOrderContent();
        initiationStatus.current = "initiated";
      }
    };

    initiateWebOrderContent();
  }, [fetchWebOrderContent, forceUpdate]);

  const isLoading = !initiationStatus.current || isLazyLoading;

  return { ...rest, isLoading, refetchWebOrderContent: fetchWebOrderContent };
};

export default useGetWebOrderContent;
