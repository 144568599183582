import PropType from "prop-types";
import React, { useState, useEffect, useCallback, useMemo, useRef } from "react";
import { useTranslate } from "../../../language/i18n";
import Page from "../../shared/Page/Page";
import Paginate from "../../shared/Pagination/Paginate";
import TopFilter from "../../shared/TopFilter/TopFilter";
import RadioButtonGroup, { RadioButton } from "../../shared/RadioButton/RadioButtonGroup";
import { InputField } from "@myloc/myloc-gui";
import { useHistory } from "@myloc/myloc-utils";
import CaseRow from "./CaseRow/CaseRow";
import Breadcrumbs from "../../shared/Breadcrumbs/Breadcrumbs";
import pages from "../../../utils/pages";
import styles from "./Cases.module.scss";
import { STATUS, WebOrderContentsFilter } from "../../../features/webOrderContent/dataTypes";
import { FILTER_OPERATOR, ORDER_BY_OPERATOR } from "../../../features/utils/SqlOperators";
import useFetchWebOrderContents from "../../../features/webOrderContent/hooks/useFetchWebOrderContents";
import { selectCount } from "../../../features/webOrderContent/webOrderContentSelectors";
import { getFiltrationFilter, getSortFilter } from "../../../features/utils/pagination";
import { useLocation } from "react-router";
import useLazyGetCurrentUser from "../../../features/session/hooks/useLazyGetCurrentUser";

const FILTERS: readonly (keyof WebOrderContentsFilter)[] = [
  "fromOrderDate",
  "toOrderDate",
  "responsibleId",
  "status",
  "freeTextSearch",
  "sort",
] as const;

type TopFilterType = Pick<WebOrderContentsFilter, "fromOrderDate" | "toOrderDate">;

const TOP_FILTERS: readonly (keyof TopFilterType)[] = ["fromOrderDate", "toOrderDate"] as const;

const DEFAULT_FILTER = "OPEN";

const Cases = ({
  setBreadcrumbs,
}: {
  setBreadcrumbs: (breadcrumbs: Parameters<typeof Breadcrumbs>[0]["breadcrumbs"]) => void;
}) => {
  const history = useHistory();
  const translate = useTranslate();
  const location = useLocation();
  const params = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const [paramsExist, setParamsExist] = useState(false);
  const [status, setStatus] = useState(params.get("status") ?? DEFAULT_FILTER);
  const isInitialized = useRef(false);
  const { user } = useLazyGetCurrentUser({ loadIfNotInitialized: true });

  const getStatus = useCallback(
    (currentStatus?: string): WebOrderContentsFilter["status"] => {
      switch (currentStatus ?? status) {
        case "OPEN":
          return [getFiltrationFilter({ id: STATUS.COMPLETED, value: FILTER_OPERATOR.LT })];
        case "CLOSED":
          return [getFiltrationFilter({ id: STATUS.COMPLETED, value: FILTER_OPERATOR.GE })];
        default:
          return undefined;
      }
    },
    [status],
  );

  const getChoicesFromParams = useCallback(() => {
    const filters = FILTERS.reduce(
      (choice, key) => ({
        ...choice,
        [key]: params.get(key) ?? "",
      }),
      {} as WebOrderContentsFilter,
    );

    filters.responsibleId = user?.id;
    filters.status = getStatus();

    filters.sort = [getSortFilter({ id: "orderDate", order: ORDER_BY_OPERATOR.DESC })];

    return filters;
  }, [params, user?.id, getStatus]);

  const [selectedFilters, setSelectedFilters] = useState(getChoicesFromParams);
  const [filters, setFilters] = useState(() => {
    const paramsFilter = getChoicesFromParams();

    return TOP_FILTERS.reduce(
      (filter, key) => ({
        ...filter,
        [key]: paramsFilter[key],
      }),
      {} as TopFilterType,
    );
  });

  const [ids, setIds] = useState<string[]>();
  const [doSearch, setDoSearch] = useState(false);
  const loadWebOrderContents = useFetchWebOrderContents();

  const filterStates = [
    { id: "OPEN", value: translate("OPEN") },
    { id: "CLOSED", value: translate("CLOSED") },
    { id: "ALL", value: translate("ALL") },
  ];

  const filterSearch = useCallback(
    (status?: string) => {
      setSelectedFilters({
        fromOrderDate: filters.fromOrderDate ?? "",
        toOrderDate: filters.toOrderDate ?? "",
        freeTextSearch: params.get("q") ?? "",
        responsibleId: user?.id,
        status: getStatus(status),
        sort: [getSortFilter({ id: "orderDate", order: ORDER_BY_OPERATOR.DESC })],
      });
    },
    [filters.fromOrderDate, filters.toOrderDate, user?.id, getStatus, params],
  );

  useEffect(() => {
    if (user && !selectedFilters.responsibleId) {
      setDoSearch(true);
      filterSearch();
    } else if (selectedFilters.responsibleId) {
      setDoSearch(true);
    }
  }, [user, selectedFilters.responsibleId, filterSearch]);

  const updateFilters = useCallback((filters: TopFilterType = {}) => {
    setFilters(filters);
  }, []);

  const handleStatusChange = async (selected: RadioButton) => {
    setHistory(selected);

    setStatus(selected.id);

    filterSearch(selected.id);
  };

  const setHistory = (selected: RadioButton) => {
    params.set("status", selected.id);

    history.replace({ PATH: `${location.pathname}`, NAME: pages.MY_CASES.NAME, STATE: undefined }, params);
  };

  const fetchWebOrderContents = useCallback(
    (filter: WebOrderContentsFilter) => {
      return loadWebOrderContents({ filter });
    },
    [loadWebOrderContents],
  );

  const onFilterChange = (key: keyof TopFilterType, value: string) => {
    setFilters(currFilters => ({ ...currFilters, [key]: value }));
  };

  useEffect(() => {
    if (paramsExist) {
      filterSearch();

      setParamsExist(false);
    }
  }, [filterSearch, paramsExist]);

  useEffect(() => {
    if (!isInitialized.current) {
      isInitialized.current = true;

      for (const filter of FILTERS) {
        if (params.get(filter)) {
          setParamsExist(true);

          break;
        }
      }

      if (params.get("status") === null) {
        params.set("status", status);

        history.replace({ PATH: `${location.pathname}`, NAME: pages.MY_CASES.NAME, STATE: undefined }, params);
      }

      if (params.get("q") != null) {
        filterSearch();
      }
    }
  }, [filterSearch, history, location.pathname, params, status]);

  useEffect(() => {
    setBreadcrumbs([{ text: `${translate(pages.MY_PAGE.NAME)} - ${translate(pages.MY_CASES.NAME)}` }]);
  }, [setBreadcrumbs, translate]);

  return (
    <Page customCssClass={styles.page} title={translate(pages.MY_CASES.NAME)}>
      <Paginate
        filter={selectedFilters}
        data={ids}
        setData={setIds}
        request={doSearch ? fetchWebOrderContents : undefined}
        countSelect={selectCount}
        triggerData={selectedFilters}
      >
        <section>
          <TopFilter
            path={pages.MY_CASES.PATH}
            onSubmit={() => filterSearch()}
            filters={filters}
            setFilters={updateFilters}
            fields={TOP_FILTERS}
            label={translate("SEARCH_FOR_CASE")}
          >
            <div className={styles.searchGroup}>
              <InputField
                type="date"
                label={translate("FROM_DATE")}
                labelAlwaysTop
                value={filters.fromOrderDate}
                onChange={(option: React.ChangeEvent<HTMLInputElement>) =>
                  onFilterChange("fromOrderDate", option.target.value)
                }
              />
              <InputField
                type="date"
                label={translate("TO_DATE")}
                labelAlwaysTop
                value={filters.toOrderDate}
                onChange={(option: React.ChangeEvent<HTMLInputElement>) =>
                  onFilterChange("toOrderDate", option.target.value)
                }
              />
            </div>
          </TopFilter>

          <section>
            <RadioButtonGroup
              values={filterStates}
              onChange={handleStatusChange}
              selectedId={status}
              customCssClass={styles.filter}
            />
          </section>

          <ul className={styles.wrapper}>{!!ids?.length && ids.map(id => <CaseRow key={id} id={id} />)}</ul>
        </section>
      </Paginate>
    </Page>
  );
};

Cases.propTypes = {
  setBreadcrumbs: PropType.func.isRequired,
};

export default Cases;
