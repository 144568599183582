import { signOut } from "../../../app/actions";
import { AppDispatch } from "../../../app/store";
import { saveSessionId } from "./saveSessionId";
import { saveSingleSignOnResponse } from "./saveSingleSignOnResponse";

export const handleClientSideSignOut = (dispatch: AppDispatch) => {
  dispatch(signOut());
  saveSessionId(undefined, false);
  saveSessionId(undefined, true);
  saveSingleSignOnResponse(undefined, false);
};
