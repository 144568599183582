import { AdditionalRequestState, FetchedIdObject, IdObject, IdType } from "../dataTypes";

export const sessionSliceName = "session";

export interface SingleSignOnResponse {
  iss: string;
  upn: string;
  given_name: string;
  family_name: string;
  aud: string;
  sub: string;
  tid: string;
  unique_name: string;
}

export interface ResponseSessionData extends IdObject {
  token?: string;
  authorizations: IdType[];
  hasToAcceptAccessMessage: boolean;
}

export interface SessionData extends ResponseSessionData, AdditionalRequestState, FetchedIdObject {}

export interface CurrentUser extends AdditionalRequestState, FetchedIdObject {}

export interface CurrentPageContent extends AdditionalRequestState, FetchedIdObject {}

export interface NextUserAccessMessage extends AdditionalRequestState, Omit<FetchedIdObject, "id"> {
  id?: string;
}

export interface SessionState extends AdditionalRequestState {
  sessionData?: SessionData;
  isSingleSignOn?: boolean;
  singleSignOut?: boolean;
  isInitialized: boolean;
  rememberMe?: boolean;
  loginToken?: string;
  currentUser?: CurrentUser;
  currentPageContent?: CurrentPageContent;
  nextUserAccessMessage?: NextUserAccessMessage;
}

export interface LogInRequest {
  username?: string;
  password?: string;
  token?: string;
  mylocLoginToken?: string;
  persistent?: boolean;
}

export interface UpdateUserCurrentDataRequest {
  firstName: string;
  surname: string;
  cellPhoneNumber: string;
  emailAddress: string;
}

export interface RequestResetPasswordRequest {
  username: string;
}

export interface UpdateUserAccessMessageRequest {
  accept: boolean;
}
