import PropType from "prop-types";
import { useTranslate } from "../../../../../language/i18n";
import { useEffect } from "react";
import pages from "../../../../../utils/pages";
import Breadcrumbs from "../../../../shared/Breadcrumbs/Breadcrumbs";
import useGetFiles from "../../../../../features/webOrderContent/hooks/useGetFiles";
import Attachment from "./Attachment/Attachment";

import styles from "./CaseAttachments.module.scss";

const CaseAttachments = ({
  caseId,
  setBreadcrumbs,
  referrer,
}: {
  caseId: string;
  setBreadcrumbs: (breadcrumbs: Parameters<typeof Breadcrumbs>["0"]["breadcrumbs"]) => void;
  referrer?: string;
}) => {
  const translate = useTranslate();
  const { files } = useGetFiles({ webOrderContentId: caseId, filter: { all: true } });

  useEffect(() => {
    setBreadcrumbs([
      {
        text: `${translate(pages.MY_PAGE.NAME)} - ${translate(pages.MY_CASES.NAME)}`,
        link: referrer ?? pages.MY_CASES.PATH,
      },
      {
        text: `${translate("CASE")} - ${translate(pages.MY_CASES_ATTACHMENTS.NAME)}`,
      },
    ]);
  }, [referrer, setBreadcrumbs, translate]);

  return (
    <div>
      <div className={styles.header}>
        <div className={styles.left}>
          <h4 className={styles.title}>{`${translate("ATTACHMENTS")}`}</h4>
        </div>
      </div>
      {files.length ? (
        <ul>
          {files.map(file => (
            <Attachment key={file.id} file={file} />
          ))}
        </ul>
      ) : (
        <p>{translate("NO_ATTACHMENTS")}</p>
      )}
    </div>
  );
};

CaseAttachments.propTypes = {
  referrer: PropType.string,
  caseId: PropType.string.isRequired,
  setBreadcrumbs: PropType.func.isRequired,
};

export default CaseAttachments;
