import PropTypes from "prop-types";
import classNames from "classnames";
import { myloc } from "../../../config/settings";
import { formatPostalNo } from "../../../utils/postalNo";
import { useTranslate } from "../../../language/i18n";
import { ExternalLink, TARGET, TYPE } from "../../shared/Link";
import { useAppSelector } from "../../../app/hooks";
import { selectSessionId } from "../../../features/session/sessionSelectors";
import styles from "./Footer.module.scss";
import { ReactNode } from "react";

function Footer() {
  const userLoggedIn = !!useAppSelector(selectSessionId);
  const translate = useTranslate();

  return userLoggedIn ? (
    <footer className={styles.versionFooter}>
      <div className={styles.versionContent}>
        <div className={styles.version}>
          {translate("VERSION")}: {process.env.REACT_APP_VERSION}
        </div>
      </div>
    </footer>
  ) : (
    <footer className={styles.footer}>
      <div className={classNames(styles.content, styles.containerPadding)}>
        <Contact />
        <Links />
        <Address />
      </div>
    </footer>
  );
}

const Contact = () => {
  const translate = useTranslate();

  return (
    <div>
      <Title>{translate("CONTACT")}</Title>
      <ExternalLink url={myloc.contactEmail} type={TYPE.EMAIL} customCssClass={styles.text}>
        {myloc.contactEmail.toString}
      </ExternalLink>
      <ExternalLink url={myloc.contactPhone} type={TYPE.PHONE} customCssClass={styles.text}>
        {translate("PHONE_SHORT")}: {myloc.contactPhone.toString}
      </ExternalLink>
      <ExternalLink url={myloc.webpageUrl} target={TARGET.NEW_TAB}>
        myloc.se
      </ExternalLink>
    </div>
  );
};

const Links = () => {
  const translate = useTranslate();

  return (
    <div className={classNames(styles.section)}>
      <Title>{translate("SUPPORT")}</Title>
      <ExternalLink url={myloc.supportEmail} type={TYPE.EMAIL} customCssClass={classNames(styles.text)}>
        {myloc.supportEmail.toString}
      </ExternalLink>
      <ExternalLink url={myloc.cookieIntegrityUrl} target={TARGET.NEW_TAB} customCssClass={styles.text}>
        {translate("COOKIE_POLICY")}
      </ExternalLink>
      <Version />
    </div>
  );
};

const Version = () => {
  const translate = useTranslate();

  return (
    <div>
      <Text>
        {translate("VERSION")}: {process.env.REACT_APP_VERSION}
      </Text>
    </div>
  );
};

const Address = () => {
  const translate = useTranslate();

  return (
    <div className={styles.address}>
      <Title>{translate("ADDRESS")}</Title>
      <Text>{myloc.address.street}</Text>
      <Text>{`${formatPostalNo(myloc.address.postalNo)} ${myloc.address.city}`}</Text>
      <Text>{myloc.address.county}</Text>
    </div>
  );
};

const Title = ({ children }: { children: ReactNode }) => <p className={styles.title}>{children}</p>;
const Text = ({ children }: { children: ReactNode }) => <p className={styles.text}>{children}</p>;

Text.propTypes = {
  children: PropTypes.node.isRequired,
};

Title.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Footer;
