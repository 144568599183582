import { useCallback, useEffect, useRef, useState } from "react";
import { useAppSelector } from "../../../app/hooks";
import { ServiceTypesFilter } from "../dataTypes";
import { FulfilledUpdateMethod, REQUEST_STATE } from "../../dataTypes";
import { selectCount, selectErrorMessage, selectRequestState, selectServiceTypes } from "../serviceTypeSelectors";
import useFetchServiceTypes from "./useFetchServiceTypes";

const useLazyGetServiceTypes = ({
  loadIfNotInitialized = false,
  filter,
  fulfilledUpdateMethod,
}: {
  loadIfNotInitialized?: boolean;
  filter?: ServiceTypesFilter;
  fulfilledUpdateMethod?: FulfilledUpdateMethod;
}) => {
  const fetchServiceTypes = useFetchServiceTypes();
  const hasInitalized = useRef(false);
  const [fetchedIds, setFetchedIds] = useState<string[]>();

  const requestState = useAppSelector(selectRequestState);
  const errorMessage = useAppSelector(selectErrorMessage);
  const count = useAppSelector(selectCount);

  const serviceTypes = useAppSelector(selectServiceTypes);

  const isUninitialized = requestState === undefined;
  const isLoading = requestState === REQUEST_STATE.PENDING;
  const isError = requestState === REQUEST_STATE.REJECTED;
  const isSuccess = requestState === REQUEST_STATE.FULFILLED;

  const loadServiceTypes = useCallback(async () => {
    setFetchedIds(await fetchServiceTypes({ filter, fulfilledUpdateMethod }));
  }, [fetchServiceTypes, filter, fulfilledUpdateMethod]);

  useEffect(() => {
    hasInitalized.current = false;
  }, [filter]);

  useEffect(() => {
    if (loadIfNotInitialized && isUninitialized && !hasInitalized.current) {
      hasInitalized.current = true;
      loadServiceTypes();
    }
  }, [isUninitialized, loadServiceTypes, loadIfNotInitialized]);

  return {
    serviceTypes,
    fetchedIds,
    count,
    isUninitialized,
    isLoading,
    isError,
    isSuccess,
    errorMessage,
    fetchServiceTypes: loadServiceTypes,
  };
};

export default useLazyGetServiceTypes;
