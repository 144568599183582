import { createSlice } from "@reduxjs/toolkit";
import { AdditionalRequestState } from "../dataTypes";
import { hyperlinkSliceName } from "./dataTypes";
import { hyperlinkAdapter } from "./hyperlinkAdapter";
import { addFetchHyperlinksForPageContentReducers } from "./reducers/fetchHyperlinksForPageContent";

const slice = createSlice({
  name: hyperlinkSliceName,
  initialState: hyperlinkAdapter.getInitialState<AdditionalRequestState>({}),
  reducers: {},
  extraReducers: builder => {
    addFetchHyperlinksForPageContentReducers(builder);
  },
});

const { reducer } = slice;

export default reducer;
