import PropTypes from "prop-types";
import classNames from "classnames";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";
import styles from "./Page.module.scss";
import LoadingSpinner from "../Spinner/LoadingSpinner";
import { Button, THEME } from "@myloc/myloc-gui";
import Refresh from "../../../assets/icons/Refresh";
import { useTranslate } from "../../../language/i18n";
import { ReactNode } from "react";
import { ValueOf } from "../../../utils/dataTypes";
import { useLocation } from "react-router";

const Page = ({
  title,
  breadcrumbs,
  description,
  children,
  customCssClass,
  customHeaderCssClass,
  layout,
  spaceBetween,
  isLoading,
  loadingTitle,
  showRefreshButton = false,
}: {
  title?: string;
  breadcrumbs?: Parameters<typeof Breadcrumbs>[0]["breadcrumbs"];
  description?: string;
  children?: ReactNode;
  customCssClass?: string;
  customHeaderCssClass?: string;
  layout?: ValueOf<typeof LAYOUT>;
  spaceBetween?: boolean;
  isLoading?: boolean;
  loadingTitle?: string;
  showRefreshButton?: boolean;
}) => {
  const translate = useTranslate();
  const location = useLocation();

  const isEmbedded = Boolean(location.pathname.match(/^\/embedded\//));
  const showBreadcrumbs = !!breadcrumbs?.length && (breadcrumbs.length !== 1 || breadcrumbs[0].text !== title);

  return (
    <section
      className={classNames(
        styles.page,
        layout,
        spaceBetween ? styles.spaceBetween : undefined,
        isEmbedded ? styles.embedded : undefined,
        customCssClass,
      )}
    >
      <div className={classNames(styles.headerSection, !title && styles.noTitle, customHeaderCssClass)}>
        {showBreadcrumbs && <Breadcrumbs breadcrumbs={breadcrumbs} showRefreshButton={showRefreshButton} />}
        {title && (
          <div className={showRefreshButton ? styles.displayRefreshButton : undefined}>
            <h2 className={styles.title}>
              {title} {description && <span className={styles.description}> - {description}</span>}
            </h2>
            {showRefreshButton && !showBreadcrumbs && (
              <div className={styles.button}>
                <Button theme={THEME.SECONDARY} onClick={window.location.reload} customCssClass={styles.refreshButton}>
                  <Refresh size={25} customCssClass={styles.refreshIcon} />
                  <div className={styles.buttonText}>{translate("RELOAD")}</div>
                </Button>
              </div>
            )}
          </div>
        )}
      </div>

      {isLoading ? <LoadingSpinner title={loadingTitle} /> : children}
    </section>
  );
};

export const LAYOUT = {
  ONE_TO_ONE: styles.oneToOne,
} as const;

Page.propTypes = {
  title: PropTypes.string,
  breadcrumbs: PropTypes.array,
  description: PropTypes.string,
  layout: PropTypes.oneOf(Object.values(LAYOUT)),
  spaceBetween: PropTypes.bool,
  customCssClass: PropTypes.string,
  customHeaderCssClass: PropTypes.string,
  isLoading: PropTypes.bool,
  loadingTitle: PropTypes.string,
  showRefreshButton: PropTypes.bool,
  children: PropTypes.node,
};

export default Page;
