import { AdditionalRequestState, FetchedIdObject, IdObject } from "../dataTypes";

export const userSliceName = "user";

export interface ResponseUser extends IdObject {
  firstName: string;
  surname: string;
  cellPhoneNumber: string;
  emailAddress: string;
}

export interface User extends ResponseUser, AdditionalRequestState, FetchedIdObject {}
