export const TYPE = {
  URL: "",
  EMAIL: "mailto:",
  PHONE: "tel:",
} as const;

export const TARGET = {
  SELF: "_self",
  NEW_TAB: "_blank",
  PARENT: "_parent: ",
  FULL: "_top: ",
} as const;
