import { RootState } from "../../app/rootReducer";
import { sessionSliceName } from "./dataTypes";

const selectSliceState = (state: RootState) => state[sessionSliceName];

export const selectErrorMessage = (state: RootState) => selectSliceState(state).errorMessage;

export const selectRequestState = (state: RootState) => selectSliceState(state).requestState;

export const selectSessionData = (state: RootState) => selectSliceState(state).sessionData;

export const selectSessionId = (state: RootState) => selectSliceState(state).sessionData?.id;

export const selectIsSingleSignOn = (state: RootState) => selectSliceState(state).isSingleSignOn;

export const selectSingleSignOut = (state: RootState) => selectSliceState(state).singleSignOut;

export const selectIsInitialized = (state: RootState) => selectSliceState(state).isInitialized;

export const selectRememberMe = (state: RootState) => selectSliceState(state).rememberMe;

export const selectCurrentUser = (state: RootState) => selectSliceState(state).currentUser;
export const selectCurrentUserRequestState = (state: RootState) => selectCurrentUser(state)?.requestState;
export const selectCurrentUserErrorMessage = (state: RootState) => selectCurrentUser(state)?.errorMessage;
export const selectCurrentUserId = (state: RootState) => selectCurrentUser(state)?.id;

export const selectCurrentPageContent = (state: RootState) => selectSliceState(state).currentPageContent;
export const selectCurrentPageContentRequestState = (state: RootState) => selectCurrentPageContent(state)?.requestState;
export const selectCurrentPageContentErrorMessage = (state: RootState) => selectCurrentPageContent(state)?.errorMessage;
export const selectCurrentPageContentId = (state: RootState) => selectCurrentPageContent(state)?.id;

export const selectNextUserAccessMessage = (state: RootState) => selectSliceState(state).nextUserAccessMessage;
export const selectNextUserAccessMessageRequestState = (state: RootState) =>
  selectNextUserAccessMessage(state)?.requestState;
export const selectNextUserAccessMessageErrorMessage = (state: RootState) =>
  selectNextUserAccessMessage(state)?.errorMessage;
export const selectNextUserAccessMessageId = (state: RootState) => selectNextUserAccessMessage(state)?.id;
