import { OptionalSelected, ValueOf } from "../../utils/dataTypes";
import { FILTER_OPERATOR, ORDER_BY_OPERATOR } from "./SqlOperators";

export const QUERY_PARAM = {
  FROM: "from",
  AMOUNT: "amount",
  ALL: "all",
  SORT: "sort",
} as const;

const FILTRATION_FILTER_DISCRIMINATOR = "FiltrationFilter";

export interface FiltrationFilter {
  id: any;
  value: ValueOf<typeof FILTER_OPERATOR>;
  discriminator: typeof FILTRATION_FILTER_DISCRIMINATOR;
}

const SORT_FILTER_DISCRIMINATOR = "SortFilter";

export interface SortFilter {
  id: string;
  order: ValueOf<typeof ORDER_BY_OPERATOR>;
  discriminator: typeof SORT_FILTER_DISCRIMINATOR;
}

export interface PaginationFilter {
  [QUERY_PARAM.ALL]?: boolean;
  [QUERY_PARAM.AMOUNT]?: number;
  [QUERY_PARAM.FROM]?: number;
  [QUERY_PARAM.SORT]?: SortFilter[];
}

export const DEFAULT_QUERY_PARAM = {
  FROM: 0,
  AMOUNT: 40,
  ALL: false,
} as const;

export const getSortFilter = <T extends SortFilter>(filter: OptionalSelected<T, "discriminator">): T =>
  ({
    discriminator: SORT_FILTER_DISCRIMINATOR,
    ...filter,
  } as T);

export const getFiltrationFilter = <T extends FiltrationFilter>(filter: OptionalSelected<T, "discriminator">) =>
  ({
    discriminator: FILTRATION_FILTER_DISCRIMINATOR,
    ...filter,
  } as T);
