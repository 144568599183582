import { useEffect, useRef } from "react";
import useForceUpdate from "../../../hooks/useForceUpdate";
import useLazyGetServiceType from "./useLazyGetServiceType";

const useGetServiceType = ({ id }: { id?: string }) => {
  const { isLoading: isLazyLoading, fetchServiceType, ...rest } = useLazyGetServiceType({ id });
  const forceUpdate = useForceUpdate();

  const initiationStatus = useRef<"initiating" | "initiated">();

  useEffect(() => {
    const initiateServiceType = async () => {
      if (!initiationStatus.current) {
        initiationStatus.current = "initiating";
        forceUpdate();
        await fetchServiceType();
        initiationStatus.current = "initiated";
      }
    };

    initiateServiceType();
  }, [fetchServiceType, forceUpdate]);

  const isLoading = !initiationStatus.current || isLazyLoading;

  return { ...rest, isLoading, refetchServiceType: fetchServiceType };
};

export default useGetServiceType;
