import { ValueOf } from "../../utils/dataTypes";
import HttpStatusCodes from "../../utils/HttpStatusCodes";

export const getValidationResult: {
  (isError: false): { isError: false };
  (isError: true, errorMessage: string, statusCode: ValueOf<typeof HttpStatusCodes>): {
    isError: true;
    errorMessage: string;
    statusCode: ValueOf<typeof HttpStatusCodes>;
  };
} = (isError: boolean, errorMessage?: string, statusCode?: ValueOf<typeof HttpStatusCodes>) => {
  if (!isError) {
    return { isError } as any;
  }

  return { isError, errorMessage, statusCode };
};
