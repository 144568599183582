import { useCallback, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { RequestState, REQUEST_STATE } from "../../dataTypes";
import { fetchSessionData } from "../reducers/fetchSessionData";
import { selectRequestState } from "../sessionSelectors";

let requestStateRef: RequestState | undefined;

const useFetchSessionData = () => {
  const dispatch = useAppDispatch();

  const requestState = useAppSelector(selectRequestState);

  if (requestStateRef === undefined) requestStateRef = requestState;

  useEffect(() => {
    requestStateRef = requestState;
  }, [requestState]);

  const loadSessionData = useCallback(async () => {
    if (requestStateRef !== REQUEST_STATE.PENDING) {
      requestStateRef = REQUEST_STATE.PENDING;

      const response = (await dispatch(fetchSessionData())).payload;
      let result: { isError: false; sessionId: string } | { isError: true };

      if (response) {
        if ("id" in response) result = { isError: false, sessionId: response.id };
        else result = { isError: true };
        return result;
      }
    }
  }, [dispatch]);

  return loadSessionData;
};

export default useFetchSessionData;
