import { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { InputField, Form, Button, THEME } from "@myloc/myloc-gui";
import RequestResetPassword from "../ResetPassword/RequestResetPassword";
import styles from "./Login.module.scss";
import { pages } from "../../../config/settings";
import useLogIn from "../../../features/session/hooks/useLogIn";
import { useTranslate } from "../../../language/i18n";

const Login = ({ desiredPage }: { desiredPage?: string }) => {
  const translate = useTranslate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [resetPasswordVisible, setResetPasswordVisible] = useState(false);
  const { logIn, isSuccess, isLoading, isError } = useLogIn();
  const loggingIn = useRef(false);

  useEffect(() => {
    if (loggingIn.current) {
      if (isSuccess) {
        if (desiredPage && pages.allowRedirectionToAfterLogin(desiredPage)) {
          //@ts-ignore:next-line
          window.location = desiredPage;
        } else {
          window.location.reload();
        }
      }

      loggingIn.current = !(isSuccess || isError);
    }
  }, [desiredPage, isSuccess, isError]);

  async function submitLogin(event: React.SyntheticEvent) {
    event.preventDefault();

    logIn({ username, password });

    loggingIn.current = true;
  }

  return resetPasswordVisible ? (
    <RequestResetPassword onClose={() => setResetPasswordVisible(false)} />
  ) : (
    <>
      <Form className={styles.login} onSubmit={submitLogin}>
        <h2 className={styles.title}>{translate("LOGIN")}</h2>
        <InputField
          required
          label={translate("USERNAME")}
          value={username}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => setUsername(event.target.value)}
          autoFocus
          customCssClass={styles.input}
        />
        <InputField
          required
          label={translate("PASSWORD")}
          type={"password"}
          value={password}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => setPassword(event.target.value)}
          customCssClass={styles.input}
        />

        <div className={styles.actionBar}>
          <Button isLoading={isLoading} type="submit" customCssClass={styles.loginButton}>
            {translate("LOGIN")}
          </Button>

          <Button
            theme={THEME.PLAIN_PRIMARY}
            customCssClass={styles.passwordButton}
            onClick={() => setResetPasswordVisible(true)}
          >
            {translate("FORGOT_PASSWORD") + "?"}
          </Button>
        </div>
      </Form>
    </>
  );
};

Login.propTypes = {
  desiredPage: PropTypes.string,
};

export default Login;
