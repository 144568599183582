import PropTypes from "prop-types";
import classNames from "classnames";
import styles from "./Label.module.scss";

// todo better key

const Label = ({
  label,
  value,
  inline = false,
  hideEmpty = false,
  customCssClass,
  customLabelCss,
}: {
  label: string;
  value?: any;
  inline?: boolean;
  hideEmpty?: boolean;
  customCssClass?: string;
  customLabelCss?: string;
}) => {
  if (hideEmpty && !value) return <></>;

  return (
    <div className={classNames(styles.text, customCssClass)}>
      {label && <span className={classNames(styles.label, inline && styles.inline)}>{label}:</span>}
      {value && Array.isArray(value) ? (
        value.map((value, i) => (
          <span className={classNames(inline && styles.inline)} key={i}>
            {value}
          </span>
        ))
      ) : (
        <span className={classNames(inline && styles.inline, customLabelCss)}>{value}</span>
      )}
    </div>
  );
};

Label.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.any,
  inline: PropTypes.bool,
  hideEmpty: PropTypes.bool,
  customCssClass: PropTypes.string,
  customLabelCss: PropTypes.string,
};

export default Label;
