import PropType from "prop-types";
import { useEffect } from "react";
import { useTranslate } from "../../../language/i18n";
import pages from "../../../utils/pages";
import Breadcrumbs from "../../shared/Breadcrumbs/Breadcrumbs";
import UserSettingsPage from "../../UserSettings/UserSettingsPage";

const Profile = ({
  setBreadcrumbs,
}: {
  setBreadcrumbs: (brreadcrumbs: Parameters<typeof Breadcrumbs>["0"]["breadcrumbs"]) => void;
}) => {
  const translate = useTranslate();

  useEffect(() => {
    setBreadcrumbs([{ text: `${translate(pages.MY_PAGE.NAME)} - ${translate(pages.MY_PROFILE.NAME)}` }]);
  }, [setBreadcrumbs, translate]);

  return <UserSettingsPage />;
};

Profile.propTypes = {
  setBreadcrumbs: PropType.func.isRequired,
};

export default Profile;
